import React, { useRef, useState } from 'react';
import { TextField, Button, Tooltip, IconButton } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import NorthIcon from '@mui/icons-material/North';
import StopIcon from '@mui/icons-material/Stop';
import { setLoginSignupScreenOpen } from '../../store';
import { useSelector, useDispatch } from 'react-redux';

const InputForm = ({
 handleSend, 
 themeData,
 loading, 
 isStreaming, 
//  handleFileUploadClick, 
 handleFileChange, 
 handleAbort 
}) => {
  const dispatch = useDispatch();
    const [input, setInput] = useState('');
    const isLoggedIn = useSelector((state) => state.chat.isLoggedIn);
    const handleChange = (event) => {
        setInput(event.target.value);
      };

      const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
          event.preventDefault();
          handleSubmit(event);
        }
      };

      const handleSubmit = (event) => {
        event.preventDefault();
        handleSend(event, input);
        setInput('');
      };

      const fileInputRef = useRef(null);

      const handleFileUploadClick = () => {
        if (!isLoggedIn) {
            dispatch(setLoginSignupScreenOpen({loginSignupScreenOpen: true, loginSignupTab: 1}));
        } else {
            fileInputRef.current.click();
        }
    };
    

 return (
   <form onSubmit={handleSubmit} id="messageForm" style={{ width: '100%', display: 'flex' }}>
     <div style={{ display: 'flex', alignItems: 'center', width: '100%', backgroundColor: `rgba(64, 121, 140, 0.90)`, borderRadius: '15px', border: '1px solid #000' }}>
       <Tooltip title="Upload a file for this conversation with Plato">
         <IconButton
           aria-label="upload file"
           style={{
             minWidth: '40px',
             width: '40px',
             height: '40px',
             borderRadius: '50%',
             padding: 0,
             marginLeft: '4px',
             backgroundColor: "#70A9A1",
             display: 'flex',
             justifyContent: 'center',
             alignItems: 'center',
             border: '1px solid #000'
           }}
           onClick={handleFileUploadClick}
         >
           <AttachFileIcon style={{ color: "#F6F1D1" }} />
         </IconButton>
       </Tooltip>
       <input
         type="file"
         ref={fileInputRef}
         style={{ display: 'none' }}
         onChange={handleFileChange}
       />
       <TextField
         className="message-input"
         autoFocus
         value={input}
         onChange={handleChange}
         onKeyDown={handleKeyDown}
         placeholder="Type your question..."
         InputProps={{
           style: { color: themeData.color4, paddingLeft: '8px' },
           disableUnderline: true,
         }}
         variant="standard"
         fullWidth
         margin="normal"
         disabled={loading}
         multiline={true}
         maxRows={10}
         sx={{ flexGrow: 1, marginRight: '8px', border: 'none' }}
       />
       <Tooltip 
         title={isStreaming ? "Stop Message" : "Send Message"}
       >
         <Button
           variant="contained"
           color={isStreaming ? "error" : "primary"}
           type={isStreaming ? "button" : "submit"}
           onClick={isStreaming ? handleAbort : undefined}
           disabled={loading || (!isStreaming && input.length === 0)}
           style={{
             minWidth: '40px',
             width: '40px',
             height: '40px',
             borderRadius: '50%',
             padding: 0,
             marginRight: '4px',
             backgroundColor: "#70A9A1",
             display: 'flex',
             justifyContent: 'center',
             alignItems: 'center',
             border: '1px solid #000'
           }}
         >
           {loading && !isStreaming ? "sending..." : isStreaming ? <StopIcon style={{ color: "#F6F1D1" }} /> : <NorthIcon style={{ color: "#F6F1D1" }} />}
         </Button>
       </Tooltip>
     </div>
   </form>
 );
};

export default InputForm;