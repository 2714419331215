import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  IconButton,
  Tooltip,
} from '@mui/material';
import { HelpOutline as HelpOutlineIcon } from '@mui/icons-material';
import { removeResources, setIsLoggedIn, clearChatHistory, removeSelectedResource, signOut } from '../../store.js';
import CloseIcon from '@mui/icons-material/Close';


const SettingsPopup = ({ open, onClose, themeData, userEmail }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const username = useSelector((state) => state.chat.username);

    const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);

    function clearAllCookies() {
      // Split the document.cookie string by "; " to get each individual cookie
      document.cookie.split(";").forEach((cookie) => {
        // Get the cookie name (the part before the '=' sign)
        const cookieName = cookie.split("=")[0];
        // Set the cookie's expiration date to a past date to delete it
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      });
    }

    const handleSignOut = () => {
        // dispatch(setIsLoggedIn(false));
        dispatch(signOut());
        clearAllCookies();
        // dispatch(clearChatHistory());
        // dispatch(removeSelectedResource());
        // dispatch(removeResources());
        onClose();
    };

    const handlePrivacyPolicyClick = () => {
      onClose();
      navigate('/legal/privacy-policy');
  };

  const handleTermsOfUseClick = () => {
    onClose();
    navigate('/legal/terms-of-use');
};

  const DialogOption = ({ label, content, tooltip }) => (
    <div style={{ 
      display: 'flex', 
      alignItems: 'center', 
      marginBottom: '20px', 
      padding: '15px',
      border: `1px solid ${themeData.color1}`,
      borderRadius: '10px',
      backgroundColor: themeData.color3,
    }}>
      <Typography variant="h6" style={{ 
        marginRight: 'auto', 
        color: themeData.color5 
      }}>
        {label}
      </Typography>
      <Typography variant="body1" style={{ 
        color: themeData.color5,
        marginRight: '10px'
      }}>
        {content}
      </Typography>
      <Tooltip title={tooltip}>
        <IconButton size="small" style={{ color: themeData.color5 }}>
          <HelpOutlineIcon />
        </IconButton>
      </Tooltip>
    </div>
  );

  return (
    <>
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="settings-dialog-title"
      PaperProps={{
        style: {
          backgroundColor: themeData.color2,
          borderRadius: '20px',
          color: themeData.color5,
          border: '1px solid #000',
          minWidth: '400px',
        },
      }}
    >
      <DialogTitle 
        id="settings-dialog-title"
        sx={{ 
          fontWeight: 'bold',
        }}
      >
        Settings
        <IconButton
          aria-label="close"
          onClick={onClose} 
          sx={{ 
            position: 'absolute',
            right: 8,
            top: 8,
            color: themeData.color5 
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogOption
          label="Email"
          content={username}
          tooltip="This is the email associated with your account."
        />

        {/* Add more settings options here */}
      </DialogContent>
      <DialogActions
        style={{ 
          display: 'flex', 
          justifyContent: 'space-between',
          padding: '0 24px 20px', // Add padding for spacing around buttons if needed
          width: '100%', // Ensures buttons take full width for flexbox to apply evenly
        }}
      >
      <Button 
          onClick={handlePrivacyPolicyClick} // Open the dialog directly
          style={{ 
              color: themeData.color5,
              textTransform: 'none',
            
          }}
        >
          Privacy Policy
        </Button>
        <Button 
              onClick={handleTermsOfUseClick}
              style={{ 
                  color: themeData.color5,
                  textTransform: 'none',
                
              }}
          >
              Terms of Use
          </Button>
      <Button 
          onClick={handleSignOut}
          style={{ 
            color: themeData.color5,
            textTransform: 'none',
             // This pushes the Sign Out button to the left
          }}
        >
          Sign Out
        </Button>
        {/* <Button 
          onClick={onClose} 
          style={{ 
            color: themeData.color5,
            textTransform: 'none' 
          }}
        >
          Close
        </Button> */}
      </DialogActions>
    </Dialog>

    </>
  );
};

export default SettingsPopup;