// import React, { useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import {
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Button,
//   TextField,
//   Typography,
//   IconButton,
//   Tooltip,
// } from '@mui/material';
// import { HelpOutline as HelpOutlineIcon } from '@mui/icons-material';
// import { getFlashcardsAPI, setFlashcardsScreenOpen, setQuizMeScreenOpen, getQuizAPI, setFlashcardPrompt } from '../../store';
// import customLog from '../../customLogger.js';
// import CloseIcon from '@mui/icons-material/Close';
// //import { uploadFile } from './yourFileUploadSlice'; // You'll need to create this

// const FlashcardsQuizMeDialog = ({ open, onClose, type, onFlashcardFileUpload, onQuizFileUpload }) => {
//   const dispatch = useDispatch();
//   const themeData = useSelector((state) => state.chat.themeData);
//   const [topic, setTopic] = useState('');
//   const [selectedFile, setSelectedFile] = useState(null);

// //   const handleTopicSubmit = () => {
// //     // Call the appropriate backend endpoint based on the type
// //     const endpoint = type === 'flashcards' ? '/api/flashcards' : '/api/quizme';
// //     // Implement the API call here
// //     console.log(`Submitting ${type} request for topic: ${topic}`);
// //     // Reset the topic after submission
// //     setTopic('');
// //   };

// const handleTopicSubmit = (event) => {
//     customLog('in handleTopicSubmit')
//     if (event.key === 'Enter' && !event.shiftKey) {
//         event.preventDefault();
//         customLog('in event.key === in handleTopicSubmit')
      
//       if (type === 'flashcards') {
//         // dispatch(setFlashcardsScreenOpen(false));
//         // dispatch(getFlashcardsAPI(event.target.value));
//         customLog('in flashcards in handleTopicSubmit with submission of ' + event.target.value);
//         // dispatch(setFlashcardPrompt(event.target.value));
//         dispatch(getFlashcardsAPI(event.target.value));
//       } else if (type === 'quizme') {
//         // dispatch(setQuizMeScreenOpen(false));
//         customLog('in quiz in handleTopicSubmit with submission of ' + event.target.value);
//         dispatch(getQuizAPI(event.target.value));
//       }
//       onClose(); // Close the dialog after submitting
//     }
//   };

//   const handleFileUpload = (event) => {
//     const file = event.target.files[0];
//     // setSelectedFile(file);
//     if (file) {
      
//       if (type === 'flashcards') {
//         // dispatch(setFlashcardsScreenOpen(false));
//         // dispatch(getFlashcardsAPI(event.target.value));
//         onFlashcardFileUpload(file);
//         customLog('in flashcards in handleTopicSubmit with submission of ' + event.target.value);
//         // dispatch(setFlashcardPrompt(event.target.value));
//         dispatch(getFlashcardsAPI(''));
//       } else if (type === 'quizme') {
//         // dispatch(setQuizMeScreenOpen(false));
//         onQuizFileUpload(file);
//         customLog('in quiz in handleTopicSubmit with submission of ' + event.target.value);
//         dispatch(getQuizAPI(''));
//       }
//     }
    
//   };

// //   const handleTopicChange = React.useCallback((e) => {
// //     setTopic(e.target.value);
// //   }, []);

//   const handleTopicChange = (event) => {
//     setTopic(event.target.value);
//   };

//   const DialogOption = ({ label, action, tooltip, icon }) => (
//     <div style={{ 
//       display: 'flex', 
//       alignItems: 'center', 
//       marginBottom: '20px', 
//       padding: '15px',
//       border: `1px solid ${themeData.color1}`,
//       borderRadius: '10px',
//       backgroundColor: themeData.color3,
//     }}>
//       <Typography variant="h6" style={{ 
//         marginRight: 'auto', 
//         color: themeData.color5 }}>
//         {label}
//       </Typography>
//       {action}
//       <Tooltip title={tooltip}>
//         <IconButton size="small" style={{ color: themeData.color5, marginLeft: '10px' }}>
//           <HelpOutlineIcon />
//         </IconButton>
//       </Tooltip>
//     </div>
//   );

//   return (
//     <Dialog
//       open={open}
//       onClose={onClose}
//       aria-labelledby={`${type}-dialog-title`}
//       PaperProps={{
//         style: {
//           backgroundColor: themeData.color2,
//           borderRadius: '20px',
//           color: themeData.color5,
//           border: '1px solid #000',
//           minWidth: '400px',
//         },
//       }}
//     >
//       <DialogTitle 
//         id={`${type}-dialog-title`}
//         sx={{ 
//             fontWeight: 'bold',
//             // fontSize: '1.25rem',
//           }}>
//         {type === 'flashcards' ? 'Create Flashcards' : 'Quiz Me'}
//         <IconButton
//           aria-label="close"
//           onClick={onClose} 
//           sx={{ 
//             position: 'absolute',
//             right: 8,
//             top: 8,
//             color: themeData.color5 
//           }}
//         >
//           <CloseIcon />
//         </IconButton>
//       </DialogTitle>
//       <DialogContent>
//         <DialogOption
//           label="Enter a Topic"
//           style={{
//             backgroundColor: themeData.color3,
//         }}
//           action={
//             <TextField
//               defaultValue={topic}
//               onChange={handleTopicChange}
//               onKeyDown={handleTopicSubmit}
//             // onKeyDown={(event) => {
//             //     if (event.key === 'Enter') {
//             //       dispatch(getFlashcardsAPI(topic));
//             //     }
//             //   }}
//               variant="outlined"
//               size="small"
//               style={{ marginLeft: '10px' }}
//               autoFocus
//               sx={{
//                 '& .MuiOutlinedInput-root': {
//                   '&.Mui-focused fieldset': {
//                     borderColor: themeData.color2,
//                   },
//                 },
//               }}
//             />
//           }
//           tooltip="Plato will create content based on this topic. The more specific, the better!"
//         />
//         <DialogOption
//           label="Upload a File"
//           action={
//             <Button
//               variant="contained"
//               component="label"
//               style={{
//                 backgroundColor: themeData.color5,
//                 color: themeData.color2,
//                 textTransform: 'none',
//               }}
//             >
//               Choose File
//               <input
//                 type="file"
//                 hidden
//                 onChange={handleFileUpload}
//               />
//             </Button>
//           }
//           tooltip="Upload your notes, class powerpoints, assignments, and more for Plato to create personalized content!"
//         />
//         {/* You can easily add more options here following the same pattern */}
//       </DialogContent>
//       {/* <DialogActions>
//         <Button 
//             onClick={onClose} 
//             style={{ 
//                 color: themeData.color5,
//                 textTransform: 'none' }}>
//           Close
//         </Button>
//       </DialogActions> */}
//     </Dialog>
//   );
// };

// export default FlashcardsQuizMeDialog;


// -------------------------------------------------------------------------------------------------

// import React, { useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import {
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   TextField,
//   Typography,
//   IconButton,
//   Tooltip,
//   Button,
//   Tabs,
//   Tab,
//   Box,
// } from '@mui/material';
// import { HelpOutline as HelpOutlineIcon } from '@mui/icons-material';
// import { getFlashcardsAPI, getQuizAPI } from '../../store';
// import customLog from '../../customLogger.js';
// import CloseIcon from '@mui/icons-material/Close';

// const FlashcardsQuizMeDialog = ({ open, onClose, type, onFlashcardFileUpload, onQuizFileUpload }) => {
//   const dispatch = useDispatch();
//   const themeData = useSelector((state) => state.chat.themeData);
//   const [topic, setTopic] = useState('');
//   const [tabValue, setTabValue] = useState(0);

//   const handleTopicSubmit = (event) => {
//     customLog('in handleTopicSubmit')
//     if (event.key === 'Enter' && !event.shiftKey) {
//       event.preventDefault();
//       customLog('in event.key === in handleTopicSubmit')
      
//       if (type === 'flashcards') {
//         customLog('in flashcards in handleTopicSubmit with submission of ' + event.target.value);
//         dispatch(getFlashcardsAPI(event.target.value));
//       } else if (type === 'quizme') {
//         customLog('in quiz in handleTopicSubmit with submission of ' + event.target.value);
//         dispatch(getQuizAPI(event.target.value));
//       }
//       onClose();
//     }
//   };

//   const handleFileUpload = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       if (type === 'flashcards') {
//         onFlashcardFileUpload(file);
//         customLog('in flashcards in handleTopicSubmit with file upload');
//         dispatch(getFlashcardsAPI(''));
//       } else if (type === 'quizme') {
//         onQuizFileUpload(file);
//         customLog('in quiz in handleTopicSubmit with file upload');
//         dispatch(getQuizAPI(''));
//       }
//       onClose();
//     }
//   };

//   const handleTopicChange = (event) => {
//     setTopic(event.target.value);
//   };

//   const handleTabChange = (event, newValue) => {
//     setTabValue(newValue);
//   };

//   const TabPanel = ({ children, value, index }) => (
//     <div role="tabpanel" hidden={value !== index}>
//       {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
//     </div>
//   );

//   return (
//     <Dialog
//       open={open}
//       onClose={onClose}
//       aria-labelledby={`${type}-dialog-title`}
//       PaperProps={{
//         style: {
//           backgroundColor: themeData.color2,
//           borderRadius: '20px',
//           color: themeData.color5,
//           border: '1px solid #000',
//           minWidth: '400px',
//         },
//       }}
//     >
//       <DialogTitle 
//         id={`${type}-dialog-title`}
//         sx={{ 
//           fontWeight: 'bold',
//         }}
//       >
//         {type === 'flashcards' ? 'Create Flashcards' : 'Quiz Me'}
//         <IconButton
//           aria-label="close"
//           onClick={onClose} 
//           sx={{ 
//             position: 'absolute',
//             right: 8,
//             top: 8,
//             color: themeData.color5 
//           }}
//         >
//           <CloseIcon />
//         </IconButton>
//       </DialogTitle>
//       <DialogContent>
//         <Tabs 
//           value={tabValue} 
//           onChange={handleTabChange}
//           sx={{
//             '& .MuiTab-root': {
//               color: themeData.color4,
//               '&.Mui-selected': {
//                 color: themeData.color5,
//               },
//             },
//             '& .MuiTabs-indicator': {
//               backgroundColor: themeData.color5,
//             },
//           }}
//         >
//           <Tab label="Prompt" />
//           <Tab label="Upload" />
//         </Tabs>
        
//         <TabPanel value={tabValue} index={0}>
//           <Typography variant="h6" style={{ color: themeData.color4, marginBottom: '10px' }}>
//             Enter a Topic
//           </Typography>
//           <TextField
//             value={topic}
//             onChange={handleTopicChange}
//             onKeyDown={handleTopicSubmit}
//             variant="outlined"
//             fullWidth
//             autoFocus
//             sx={{
//               '& .MuiOutlinedInput-root': {
//                 '& fieldset': {
//                   borderColor: themeData.color5,
//                 },
//                 '&:hover fieldset': {
//                   borderColor: themeData.color1,
//                 },
//                 '&.Mui-focused fieldset': {
//                   borderColor: themeData.color1,
//                 },
//               },
//               '& .MuiInputBase-input': {
//                 color: themeData.color5,
//               },
//             }}
//           />
//           <Tooltip title="Plato will create content based on this topic. The more specific, the better!">
//             <IconButton size="small" style={{ color: themeData.color5, marginTop: '10px' }}>
//               <HelpOutlineIcon />
//             </IconButton>
//           </Tooltip>
//         </TabPanel>
        
//         <TabPanel value={tabValue} index={1}>
//           <Typography variant="h6" style={{ color: themeData.color5, marginBottom: '10px' }}>
//             Upload a File
//           </Typography>
//           <Button
//             variant="contained"
//             component="label"
//             style={{
//               backgroundColor: themeData.color5,
//               color: themeData.color2,
//               textTransform: 'none',
//             }}
//           >
//             Choose File
//             <input
//               type="file"
//               hidden
//               onChange={handleFileUpload}
//             />
//           </Button>
//           <Tooltip title="Upload your notes, class powerpoints, assignments, and more for Plato to create personalized content!">
//             <IconButton size="small" style={{ color: themeData.color5, marginLeft: '10px' }}>
//               <HelpOutlineIcon />
//             </IconButton>
//           </Tooltip>
//         </TabPanel>
//       </DialogContent>
//     </Dialog>
//   );
// };

// export default FlashcardsQuizMeDialog;


// -------------------------------------------------------------------------------------------------

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  Typography,
  Tabs,
  Tab,
  Box,
  IconButton,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { HelpOutline as HelpOutlineIcon, Close as CloseIcon } from '@mui/icons-material';
import { getFlashcardsAPI, getQuizAPI } from '../../store';
import NorthIcon from '@mui/icons-material/North';
import customLog from '../../customLogger';


const StyledTextField = styled(TextField)(({ theme, themeData }) => ({
  backgroundColor: themeData.color3,
  // border: `1px solid ${themeData.color1}`,
  marginTop: '0px',
  borderRadius: '10px',
  '& .MuiFilledInput-root': {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
    },
    padding: '0 0px'
  },

  '& .MuiFormLabel-root': {
    color: themeData.color5,
    '&.Mui-focused': {
      color: themeData.color5
    }
  }
}));

const FlashcardsQuizMeDialog = ({ open, onClose, type, onFlashcardFileUpload, onQuizFileUpload, createFlashcards, createQuiz }) => {
  const dispatch = useDispatch();
  const themeData = useSelector((state) => state.chat.themeData);
  const [activeTab, setActiveTab] = useState(0);
  const [prompt, setPrompt] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handlePromptSubmit = (event) => {
    event.preventDefault();
    if (type === 'flashcards') {
      // dispatch(getFlashcardsAPI(prompt));
      createFlashcards({message: prompt});
    } else if (type === 'quizme') {
      // dispatch(getQuizAPI(prompt));
      createQuiz({message: prompt});
    }
    customLog('HANDLEPROMPTSUBMIT right before onClose()');
    onClose();
  };

  const handleSendClick = () => {
    handlePromptSubmit({ preventDefault: () => {} });  // Call the handlePromptSubmit function directly
};

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      if (type === 'flashcards') {
        // onFlashcardFileUpload(file);
        // dispatch(getFlashcardsAPI(''));
        createFlashcards({file: file});
      } else if (type === 'quizme') {
        // onQuizFileUpload(file);
        // dispatch(getQuizAPI(''));
        createQuiz({file: file});
      }
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby={`${type}-dialog-title`}
      PaperProps={{
        style: {
          backgroundColor: themeData.color2,
          borderRadius: '20px',
          color: themeData.color5,
          border: '1px solid #000',
          width: '400px',
        },
      }}
    >
      <DialogTitle id={`${type}-dialog-title`} sx={{ fontWeight: 'bold', textAlign: 'center' }}>
        {type === 'flashcards' ? 'Create Flashcards' : 'Create Quiz'}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: themeData.color5
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          centered
          textColor={themeData.color4}
          indicatorColor={themeData.color4}
          TabIndicatorProps={{
            style: { backgroundColor: themeData.color4, color: themeData.color3 },
          }}
          sx={{
            '& .MuiTab-root': {
              color: themeData.color4,
              textTransform: 'none',
            },
            '& .Mui-selected': {
              color: themeData.color4,
            },
          }}
        >
          <Tab label="Prompt" />
          <Tab label="Upload" />
        </Tabs>
        <Box sx={{ p: 2 }}>
          {activeTab === 0 && (
            <form onSubmit={handlePromptSubmit}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: themeData.color3,
                    borderRadius: '10px',
                    border: `1px solid ${themeData.color1}`,
                    marginBottom: '12px'
                    // padding: '4px', // Adjust padding to align the TextField and IconButton
                  }}
              >
                <StyledTextField
                  fullWidth
                  margin="normal"
                  placeholder="Enter a topic or prompt"
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                  themeData={themeData}
                  InputProps={{
                    disableUnderline: true,
                    sx: {
                      '& .MuiInputBase-input': { // Target the input text
                          color: themeData.color4,  // Change this to your desired text color
                      },
                      
                    },
                  }}
                  variant="filled"
                  sx={{ flexGrow: 1, color: themeData.color5 }} // Ensure the TextField takes the remaining width
                />
                <IconButton
                  onClick={handleSendClick}
                  sx={{
                    color: themeData.color5,
                    marginRight: '12px',
                    //marginTop: '10px' // Optional spacing between TextField and IconButton
                  }}
                >
                  <NorthIcon />
                </IconButton>
              </Box>
            </form>
          )}
          {activeTab === 1 && (
            <Box>
              <Button
                variant="contained"
                component="label"
                fullWidth
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: themeData.color3,
                  color: themeData.color4,
                  border: `1px solid ${themeData.color1}`,
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: themeData.color3,
                    color: themeData.color5,
                  },
                }}
              >
                Choose File
                <input
                  type="file"
                  hidden
                  onChange={handleFileUpload}
                />
              </Button>
              {selectedFile && (
                <Typography variant="body2" sx={{ mt: 2, color: themeData.color5 }}>
                  Selected file: {selectedFile.name}
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default FlashcardsQuizMeDialog;