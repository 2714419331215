// store.js
import { configureStore, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { thunk } from 'redux-thunk';
import axios from 'axios';
// import { DjangoHost } from './hosts.js';
// import abstractClassesPDF from './resources/comp215_abstract_classes.pdf';
import abstractClassPreview from './images/abstractClassPreview.png'; //TODO: not for prod. line uses it for all files returned from rag
import ragPreview from './images/RAG-Preview.png';
// import abstractClassesVideo from './resources/abstractVid.mp4';
import abstractVideoPreview from './images/videoPreview.png';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import customLog from './customLogger.js';
import { flatMap, lte } from 'lodash';
import {themes} from './colors.js';
// import { Alert } from 'react-native';
const { Configuration, OpenAIApi } = require("openai");
const { GoogleGenerativeAI } = require("@google/generative-ai");


const DjangoHost = process.env.REACT_APP_DJANGO_HOST;

const initialState = {
  iframeDragged: false,
  selectedTheme: 'default',
  themeData: themes['default'],
  quizMeScreenOpen: false,
  quiz: [],
  flashcardsScreenOpen: false,
  currentFlashcardPDF: null,
  // flashcards: [],
  currentFlashcardQuizIndex: 0,
  allCoursesID: false,
  drawerOpen: true,
  IDToConversation: {},
  IDToChatTitle: {},
  courseIDsToRecentChatIDs: {},
  currentOrderedChatIDs: [],
  conversationIDCounter: 0,
  selectedChatTitle: "New Chat",
  messages: [],
  input: "",
  loading: false,
  coursePopover: null,
  userPopover: null,
  selectedCourse: null, //TODO: Figure out how to automatically set a selectedCourse
  selectedCourseTitle: "CS 101", //TODO: Figure out how to set automatically
  courses: {},
  courseIdCounter : 5,
  editingChatTitle: null,
  editedChatTitle: "",
  currentConversationID: null,
  resources: [],
  selectedResource: null,
  selectedResourceID: null,
  selectedResourceType: null,
  systemPrompt: "normal",
  settingsOpen: false,
  snackbarShareChatOpen: false,
  snackbarRecordingOpen: false,
  snackbarRecordingMessage: "",
  generalSnackbarOpen: false,
  generalSnackbarMessage: "",
  streamedMessages: "",
  APIToken: null,
  refreshToken: null,
  selectedCourseId: "",
  mostRecentUserPrompt: "",
  creatingCourse: false,
  isShortTermMemory: false,
  isLongTermMemory: false,
  contextResourceSelection: false,
  isLoggedIn: false,
  firstName: "Guest",
  username: "Guest",
  messageToSend: "",
  storedCourseName: "Error",
  storedChatName: "Error",
  previousRemoveChatData: {},
  abortion: false,
  loginSignupScreenOpen: false,
  flashcardPrompt: null,
  flashcardArray: [],
  currentFlashcardQuiz: null,
  quizPrompt: null,
  loginSignupTab: 1,
  presetPrompt: null,
  allFlashcardGroups: [],
  allQuizGroups: [],
  currentFlashcardGroupID: null,
  currentQuizID: null,
  currentFlashcardGroup: null,
  currentQuizGroup: null,
  currentFlashcardIndex: 0,
  currentQuizIndex: 0,
  streamingCards: false,
}

// Define the initial state and create slices for each piece of state
const chatSlice = createSlice({
  name: 'chat',
  initialState,
  // initialState: {
  //   iframeDragged: false,
  //   selectedTheme: 'default',
  //   themeData: themes['default'],
  //   quizMeScreenOpen: false,
  //   quiz: [],
  //   flashcardsScreenOpen: false,
  //   currentFlashcardPDF: null,
  //   flashcards: [],
  //   currentFlashcardQuizIndex: 0,
  //   allCoursesID: false,
  //   drawerOpen: true,
  //   IDToConversation: {},
  //   IDToChatTitle: {},
  //   courseIDsToRecentChatIDs: {},
  //   currentOrderedChatIDs: [],
  //   conversationIDCounter: 0,
  //   selectedChatTitle: "New Chat",
  //   messages: [],
  //   input: "",
  //   loading: false,
  //   coursePopover: null,
  //   userPopover: null,
  //   selectedCourse: null, //TODO: Figure out how to automatically set a selectedCourse
  //   selectedCourseTitle: "CS 101", //TODO: Figure out how to set automatically
  //   courses: {},
  //   courseIdCounter : 5,
  //   editingChatTitle: null,
  //   editedChatTitle: "",
  //   currentConversationID: null,
  //   resources: [],
  //   selectedResource: null,
  //   selectedResourceID: null,
  //   selectedResourceType: null,
  //   systemPrompt: "normal",
  //   settingsOpen: false,
  //   snackbarShareChatOpen: false,
  //   snackbarRecordingOpen: false,
  //   snackbarRecordingMessage: "",
  //   generalSnackbarOpen: false,
  //   generalSnackbarMessage: "",
  //   streamedMessages: "",
  //   APIToken: null,
  //   refreshToken: null,
  //   selectedCourseId: "",
  //   mostRecentUserPrompt: "",
  //   creatingCourse: false,
  //   isShortTermMemory: false,
  //   isLongTermMemory: false,
  //   contextResourceSelection: false,
  //   isLoggedIn: false,
  //   firstName: "Guest",
  //   username: "Guest",
  //   messageToSend: "",
  //   storedCourseName: "Error",
  //   storedChatName: "Error",
  //   previousRemoveChatData: {},
  //   abortion: false,
  //   loginSignupScreenOpen: false,
  //   flashcardPrompt: null,
  //   flashcardArray: [],
  //   currentFlashcardQuiz: null,
  //   quizPrompt: null,
  //   streamingCards: false,
  //   loginSignupTab: 1,
  //   presetPrompt: null,
  // },
  reducers: {
    setDrawerOpen: (state, action) => {
      state.drawerOpen = action.payload;
    },
    setIDToConversation: (state, action) => {
      state.IDToConversation = action.payload;
    },
    setChatTitle: (state, action) => {
      state.selectedChatTitle = action.payload;
    },
    setMessages: (state, action) => {
      state.messages = action.payload;
    },
    setInput: (state, action) => {
      state.input = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCoursePopover: (state, action) => {
      state.coursePopover = action.payload;
    },
    setUserPopover: (state, action) => {
      state.userPopover = action.payload;
    },
    handleLoadChatRedux: (state, action) => {
        const {selectedCourse } = state;
        const conversationId = action.payload;
        state.currentConversationID = conversationId;
        if (state.IDToConversation[selectedCourse] && state.IDToConversation[selectedCourse][conversationId]) {
          state.messages = state.IDToConversation[selectedCourse][conversationId];
          state.selectedChatTitle = state.IDToChatTitle[conversationId] || "New Chat";
        }
        // if (state.savedChats[selectedCourse] && state.savedChats[selectedCourse][action.payload]) {
        //   state.messages = state.savedChats[selectedCourse][action.payload];
        //   state.chatTitle = action.payload;
        // };
    },
    setSelectedCourse: (state, action) => {
        state.selectedCourse = action.payload;
        // Update currentOrderedChatIDs to reflect the new selectedCourse
        if (state.courseIDsToRecentChatIDs[action.payload]) {
          state.currentOrderedChatIDs = state.courseIDsToRecentChatIDs[action.payload];
        } else {
          state.currentOrderedChatIDs = [];
        }
        customLog('currentOrderedChatIDs in setSelectedCourse is ' + state.currentOrderedChatIDs);
    },
    setCourses: (state, action) => {
        state.courses = action.payload;
    },
    editCourseName: (state, action) => {
      const { courseId, courseName } = action.payload;
      if (state.courses.hasOwnProperty(courseId)) {
        state.courses[courseId] = courseName;
      }
    },
    removeCourse: (state, action) => {
      const { courseId } = action.payload;
      delete state.courses[courseId];
    },
    // setEditingChatTitle: (state, action) => {
    //   state.editingChatTitle = action.payload;
    // },
    setEditedChatTitleRedux: (state, action) => { //TODO: Get better name
      const { conversationID, newTitle } = action.payload;
      if (state.IDToChatTitle[state.selectedCourse].hasOwnProperty(conversationID)) {
        state.IDToChatTitle[state.selectedCourse][conversationID] = newTitle;
      }
    },
    setConversationID: (state, action) => {
      state.currentConversationID = action.payload;
    },
    removeChat: (state, action) => {
      const { conversationID } = action.payload;
      delete state.IDToConversation[state.selectedCourse][conversationID];
      delete state.IDToChatTitle[state.selectedCourse][conversationID];
      
      if (state.currentConversationID === conversationID) { //TODO: can combine this with handleLoadChat 
        // Automatically select a new chatTitle
        // console.log("Current chat being deleted");
        const remainingChatTitles = Object.keys(state.IDToChatTitle[state.selectedCourse]);
        if (remainingChatTitles.length > 0) {
          // Assuming the first item is the newest after sorting
          const newSelectedChatTitle = remainingChatTitles[0];
          state.selectedChatTitle = state.IDToChatTitle[state.selectedCourse][newSelectedChatTitle];
          state.messages = state.IDToConversation[state.selectedCourse][newSelectedChatTitle];
          state.currentConversationID = newSelectedChatTitle;
        } else {
          state.selectedChatTitle = "New Chat";
          state.currentConversationID = null;
          state.messages = [];
        }
      }

    // Other reducers...
  },
  setResources: (state, action) => {
    const mockResources = [
      // { id: 1, type: 'file', title: 'AbstractClasses.pdf', url: abstractClassesPDF, previewImageUrl: abstractClassPreview, locations: [5, 12, 50] },
      // { id: 2, type: 'video', title: 'Abstract Classes Video', url: abstractClassesVideo, previewImageUrl: abstractVideoPreview, locations: [35, 105, 160] },
      // { id: 3, type: 'video', title: 'Abstract Classes Video', url: abstractClassesVideo, previewImageUrl: abstractVideoPreview, locations: [30, 60, 90] },
      //{ id: 2, type: 'video', title: 'Lecture.mp4', url: '/path/to/lecture.mp4' },
      // ... up to 3 resources
    ];
    state.resources = mockResources; //TODO: Replace with actual resources
    // state.resources = action.payload;
  },
  removeResources: (state, action) => {
    state.resources = [];
    
    // state.selectedResource = null; //TODO: Think if this is best user design - probs not if the select resource is short term memory. Why not leave on for long-term memory anyway
  },
  removeSelectedResource: (state, action) => {
    state.selectedResource = null;
    state.selectedResourceID = null;
    state.selectedResourceType = null;
  },
  setSelectedResource: (state, action) => {
    customLog('SETSELECTEDRESOURCE CALLED WITH action.payload of ' + JSON.stringify(action.payload));
    state.selectedResource = action.payload;
    state.selectedResourceID = 'action.payload.id';
    customLog('SETSELECTEDRESOURCE state.selectedResourceID is now ' + state.selectedResourceID);
  },
  handleNewChat: (state, action) => {
  //   if (!selectedCourse) {
  //     return; //TODO: Figure out what to do if new chat made but no course selected
  // }
    state.selectedChatTitle = "New Chat";
    state.messages = [];
    state.currentConversationID = null;
    state.resources = [];
    state.selectedResource = null;  
  },
  setSelectedCourseTitle: (state, action) => {
    state.selectedCourseTitle = action.payload;
  },
  setSystemPrompt: (state, action) => {
    state.systemPrompt = action.payload;
  },
  setSettingsOpen: (state, action) => {
    state.settingsOpen = action.payload;
  },
  setSnackbarShareChatOpen: (state, action) => {
    state.snackbarShareChatOpen = action.payload;
  },
  setSnackbarRecordingOpen: (state, action) => {
    state.snackbarRecordingOpen = action.payload;
  },
  setSnackbarRecordingMessage: (state, action) => {
    state.snackbarRecordingMessage = action.payload;
  },
  addStreamingMessage: (state, action) => {
    const { message, conversationID } = action.payload;
    state.messages.push(message);
    state.IDToConversation[state.selectedCourse][conversationID] = state.messages;
  },
  addStreamedMessage: (state, action) => {
    // append action.payload to state.streamedMessages
    // Update the IDToConversation mapping
    // get streamedResponse from action.payload
    const { streamedResponse } = action.payload;
    // console.log('in addStreamedMessage with streamedResponse of ' + streamedResponse);
    const { selectedCourse, selectedChatTitle: chatTitle, messages, currentConversationID } = state;
    // if (!state.IDToConversation[selectedCourse]) {
    //     state.IDToConversation[selectedCourse] = {};
    // }dispatch(addStreamedMessage(streamedResponse));

    // Add streamed response to messages as assistant message
    const assistantMessage = {
      user: "Assistant",
      text: streamedResponse,
    };
    state.messages.push(assistantMessage);

    state.IDToConversation[selectedCourse][currentConversationID] = messages;
    state.presetPrompt = null;
    state.messageToSend = "";
  },
  addPresetPrompt: (state, action) => {
    const { message } = action.payload;
    const assistantMessage = {
      user: "Assistant",
      text: message,
    }
    state.messages.push(assistantMessage);
    state.presetPrompt = null;
  },
  addMessage: (state, action) => {
    state.messages.push(action.payload);
  },
  setMostRecentUserPrompt: (state, action) => {
    state.mostRecentUserPrompt = action.payload;
  },
  setGeneralSnackbarOpen: (state, action) => {
    customLog('setGeneralSnackbarOpen called with ' + action.payload);
    state.generalSnackbarOpen = action.payload;
  },
  setGeneralSnackbarMessage: (state, action) => {
    customLog('setGeneralSnackbarMessage called with ' + action.payload);
    state.generalSnackbarMessage = action.payload;
  },
  setIsShortTermMemory: (state, action) => {
    state.isShortTermMemory = action.payload;
  },
  setIsLongTermMemory: (state, action) => {
    state.isLongTermMemory = action.payload;
  },
  setFlashcardsScreenOpen: (state, action) => {
    state.flashcardsScreenOpen = action.payload;
    state.selectedResource = null;
    state.selectedResourceID = null;
  },
  setFlashcards: (state, action) => {
    customLog('SETFLASHCARDS before ' + state.flashcards);
    customLog('SETFLASHCARDS ACTION.PAYLOAD ' + JSON.stringify(action.payload));
    state.flashcards = action.payload;
    customLog('SETFLASHCARDS after ' + JSON.stringify(state.flashcards));
    // state.flashcardsScreenOpen = false;
    state.currentFlashcardQuizIndex = 0;
    // state.selectedResource = {id: 'temp', type: 'flashcard', title: 'flashcardTest', content: action.payload};
    // state.selectedResourceID = 'test';
  },
  setQuiz(state, action) {
    state.quiz = action.payload;
    // state.quizMeScreenOpen = false;
    state.currentFlashcardQuizIndex = 0;
    // state.selectedResource = { type: 'quiz' };
  },
  // addFlashcard(state, action) {
  //   state.flashcards.splice(state.currentFlashcardQuizIndex + 1, 0, action.payload);
  // },
  addFlashcard: (state, action) => {
    const flashcard = action.payload;
    if (state.currentFlashcardGroup) {
      state.currentFlashcardGroup.flashcards.push(flashcard);
    }
  },
  // appendFlashcard(state, action) {
  //   state.flashcards.push(action.payload);
  // },

  addQuizQuestion(state, action) {
    state.quiz.splice(state.currentFlashcardQuizIndex + 1, 0, action.payload);
  },
  appendQuizQuestion(state, action) {
    state.quiz.push(action.payload);
  },
  setCurrentFlashcardPDF: (state, action) => {
    state.currentFlashcardPDF = action.payload;
  },
  sendMessageToAPI: (state, action) => {
    const { message, presetPrompt } = action.payload;
    customLog('sendMessageToAPI called with message: ' + message + ' and presetPrompt: ' + presetPrompt);
    customLog('messageToSend before setting: ' + state.messageToSend);
    state.messageToSend = message;
    state.presetPrompt = presetPrompt;
    customLog('messageToSend after setting: ' + state.messageToSend);
  },
  setQuizMeScreenOpen: (state, action) => {
    state.quizMeScreenOpen = action.payload;
    state.selectedResource = null;
    state.selectedResourceID = null;
  },
  setTheme: (state, action) => {
    state.selectedTheme = action.payload;
    state.themeData = themes[action.payload];
  },
  setIframeDragged: (state, action) => {
    state.iframeDragged = action.payload;
  },
  setAbortion: (state, action) => {
    state.abortion = action.payload;
  },
  setIsLoggedIn: (state, action) => {
    state.isLoggedIn = action.payload;
    if (!action.payload) {
      state.username = "Guest";
      state.firstName = "Guest";
    }
  },
  setLoginSignupScreenOpen: (state, action) => {
    const {loginSignupScreenOpen, loginSignupTab } = action.payload;
    state.loginSignupScreenOpen = loginSignupScreenOpen;
    state.loginSignupTab = loginSignupTab;
    state.drawerOpen = !loginSignupScreenOpen;
  },
  setQuizPrompt: (state, action) => {
    state.quizPrompt = action.payload;
  },
  setFlashcardPrompt: (state, action) => {
    customLog('store.js setFlashcardPrompt called with ' + action.payload);
    state.flashcardPrompt = action.payload;
  },
  setFlashcardQuizObject : (state, action) => {
    state.currentFlashcardQuiz = action.payload;
  },
  changeFlashcardQuizIndex: (state, action) => {
    state.currentFlashcardQuizIndex += action.payload;
  },
  setFlashcardQuizIndex: (state, action) => {
    state.currentFlashcardQuizIndex = action.payload;
  },
  clearChatHistory: (state, action) => {
    state.currentOrderedChatIDs = [];
  },
  setCurrentFlashcardGroupID: (state, action) => {
    customLog('SETCURRENTFLASHCARDGROUPID STORE.JS with action.payload of ' + action.payload);
    state.currentFlashcardGroupID = action.payload;
  },
  setCurrentQuizID: (state, action) => {
    state.currentQuizID = action.payload;
  },
  prependFlashcardGroup: (state, action) => {
    state.allFlashcardGroups.unshift(state.currentFlashcardGroup);
  },
  prependQuizGroup: (state, action) => {
    state.allQuizGroups.unshift(state.currentQuizGroup);
  },
  appendFlashcardToGroup: (state, action) => {
    if (state.currentFlashcardGroup) {
      state.currentFlashcardGroup.flashcards.push(action.payload);
    }
  },
  appendQuizQuestionToGroup: (state, action) => {
    customLog()
    const {quizQuestion, incrementCard} = action.payload;
    if (state.currentQuizGroup) {
      state.currentQuizGroup.quiz_questions.push(quizQuestion);
    }

    if (incrementCard) {
      state.currentQuizIndex += 1;
    }
  },
  setAllFlashcardGroups: (state, action) => {
    state.allFlashcardGroups = action.payload;
  },
  setAllQuizGroups: (state, action) => {
    state.allQuizGroups = action.payload;
  },
  setCurrentFlashcardGroup: (state, action) => {
    state.currentFlashcardGroup = action.payload;
  },
  setCurrentQuizGroup: (state, action) => {
    state.currentQuizGroup = action.payload;
  },
  setCurrentFlashcardIndex: (state, action) => {
    state.currentFlashcardIndex = action.payload;
  },
  setCurrentQuizIndex: (state, action) => {
    state.currentQuizIndex = action.payload;
  },
  changeCurrentFlashcardIndex: (state, action) => {
    state.currentFlashcardIndex += action.payload;
  },
  changeCurrentQuizIndex: (state, action) => {
    state.currentQuizIndex += action.payload;
  },
  setSelectedResourceType: (state, action) => {
    state.selectedResourceType = action.payload;
  },
  setStreamingCards: (state, action) => {
    state.streamingCards = action.payload;
  },
  insertFlashcard: (state, action) => {
    const {flashcard } = action.payload
    if (state.currentFlashcardGroup) {
      const nextIndex = state.currentFlashcardIndex + 1;
      state.currentFlashcardGroup.flashcards.splice(nextIndex, 0, flashcard);
      state.currentFlashcardIndex = nextIndex;
    }
  },
  insertQuizQuestion: (state, action) => {
    const {quizQuestion} = action.payload;
    if (state.currentQuizGroup) {
      const nextIndex = state.currentQuizIndex + 1;
      state.currentQuizGroup.quiz_questions.splice(nextIndex, 0, quizQuestion);
      state.currentQuizIndex = nextIndex;
    }
  },

  signOut: () => initialState,
  
},
  // Extra reducers for handling async thunks...
  extraReducers: (builder) => {
    builder
        .addCase(createCourseAPI.pending, (state, action) => {
          customLog('createCourseAPI.pending action.meta.arg.courseName: ', action.meta.arg);
          state.courses["temp id"] = action.meta.arg;
          state.selectedCourse = "temp id";
          // Update the currentOrderedChatIDs
          state.currentOrderedChatIDs = [];
      
          // Update the messages array
          state.messages = [];
          state.loading = true;
          state.creatingCourse = true;

      })
      .addCase(createCourseAPI.fulfilled, (state, action) => {
          // Use temp counter for uniqueness (TODO: Get from backend)
          //const courseId = state.courseIdCounter;

          // Extract the new course from the action payload
          const {courseName, courseID} = action.payload;

          // Check if saved courses is empty
          if (!state.allCoursesID) {
            state.allCoursesID = courseID;
          }
          
          // Add the new course to the saved courses
          state.courses[courseID] = courseName;

          //Update the selected course
          state.selectedCourse = courseID;

          // Update the currentOrderedChatIDs
          state.currentOrderedChatIDs = [];

          // Update the messages array
          state.messages = [];

          // if temp id exists in courses, update it
          if (state.courses["temp id"]) {
            delete state.courses["temp id"];
          }

          // Go ahead and creating empty ma  in IDToChatTitle for new course
          state.IDToChatTitle[courseID] = {};

          // Go ahead and create an empty array in courseIDsToRecentChatIDs for new course
          state.courseIDsToRecentChatIDs[courseID] = [];

          // Go ahead and create empty map in IDToConversation
          state.IDToConversation[courseID] = {};
          // Increment the course ID counter
          //state.courseIdCounter++;
          // Reset the loading state
          state.loading = false;
          state.creatingCourse = false;

          // Set snackbar for creatingCourse to false
          state.generalSnackbarOpen = false;
      })
      .addCase(createCourseAPI.rejected, (state, action) => {
          // Handle the rejected state
          // `action.payload` contains the error message if `rejectWithValue` was used
          customLog('createCourseAPI.rejected:', action.payload);
          // state.generalSnackbarMessage = 'Error creating course';
          // state.generalSnackbarOpen = true;
          state.loading = false;
          state.creatingCourse = false;

      })
        .addCase(loadMostRecentChat.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(loadMostRecentChat.fulfilled, (state, action) => {
          // console.log("loadMostRecentChat.fulfilled");
          const courseId = action.payload;
          const savedChats = state.IDToConversation[courseId];
          if (savedChats) {
            // const savedChats = state.savedChats[courseId];
            const mostRecentChatTitle = Object.keys(savedChats).slice(-1)[0];
            state.selectedChatTitle = mostRecentChatTitle;
            state.messages = savedChats[mostRecentChatTitle];
          } else {
            state.selectedChatTitle = "New Chat";
            state.messages = [];
            state.currentConversationID = null;
          }

        })
        .addCase(loadMostRecentChat.rejected, (state, action) => {
            console.error('Error loading most recent chat:', action.payload);
            state.loading = false;
        })
      .addCase(uploadContextFileAPI.pending, (state, action) => {
        state.loading = true;
        // state.selectedResouceType = 'file';
      })
      .addCase(uploadContextFileAPI.fulfilled, (state, action) => {
        // const {url} = action.payload;
        // state.loading = false;
        // state.selectedResource = url;
        // state.contextResourceSelection = true;
        customLog('uploadContextFileAPI.fulfilled');
        const {fileID, filePreview, fileURL, initialPage} = action.payload;
        customLog('values for selectedResource and ID now are ' + state.selectedResource + ' ' + state.selectedResourceID);
        state.selectedResource = {id: fileID, type: 'file', title: 'Rag File', url: fileURL, previewImageUrl: filePreview, locations: [initialPage]};
        // state.resources = [{id: fileID, type: 'file', title: 'Rag File', url: fileURL, previewImageUrl: filePreview, locations: [initialPage]}]
        state.selectedResourceID = fileID;
        state.selectedResourceType = 'file';
        state.generalSnackbarMessage = "Talk to Plato about your file!";
        state.generalSnackbarOpen = true;
        customLog('values for selectedResource and ID after update are ' + state.selectedResource + ' ' + state.selectedResourceID);
      })
      .addCase(uploadContextFileAPI.rejected, (state, action) => {
        customLog('uploadContextFileAPI rejected :', action.payload);
        state.loading = false;
      })
      .addCase(uploadLongtermFileAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(uploadLongtermFileAPI.fulfilled, (state, action) => {
        // const {file} = action.payload;
        customLog('uploadLongtermFileAPI.fulfilled');
        state.generalSnackbarMessage = "Plato's adding it to memory!";
        state.generalSnackbarOpen = true;
        state.loading = false;
      })
      .addCase(uploadLongtermFileAPI.rejected, (state, action) => {
        customLog('uploadLongtermFileAPI.rejected: ', action.payload);
        state.loading = false;
      })
      .addCase(updateCourseAPI.pending, (state, action) => {//TODO: success assumed 
        // state.loading = true;
        const {courseName, courseId} = action.meta.arg;
        state.storedCourseName = state.courses[courseId];
        if (courseId !== state.allCoursesID) {
          state.courses[courseId] = courseName;
        }
      })
      .addCase(updateCourseAPI.fulfilled, (state, action) => {
        // const {courseName, courseId} = action.payload;

        // Update the courses object
        //state.courses[courseId] = courseName; TOOD: this used to be fulfilled

        // .addCase(editCourseName.pending, (state, action) => {
        //     state.loading = true;
        // });//TODO: add more cases, need to figure out how course to chatTitles/content mapping will look like on FE
      })
      .addCase(updateCourseAPI.rejected, (state, action) => {
          customLog('updateCourseAPI.rejected: ', action.payload);
          const {courseName, courseId} = action.meta.arg;
          state.courses[courseId] = state.storedCourseName;
          state.storedCourseName = "Error";
          state.loading = false;
      })
      .addCase(deleteCourseAPI.pending, (state, action) => {
        // state.loading = true;
        const {courseId} = action.meta.arg; //TODO: used to be fulfilled, success now assumed
        if (courseId !== state.allCoursesID) {
          delete state.courses[courseId];
        }
      })
      .addCase(deleteCourseAPI.fulfilled, (state, action) => {
        // const {courseId} = action.payload;
        // Remove the course from the courses object
          //         // Update the currentOrderedChatIDs
          // state.currentOrderedChatIDs = [];

          // // Update the messages array
          // state.messages = [];
        //delete state.courses[courseId];
      })
      .addCase(deleteCourseAPI.rejected, (state, action) => {
          customLog('deleteCourseAPI.rejected: ', action.payload);
          const {courseId} = action.meta.arg;
          state.courses[courseId] = action.meta.arg.courseName;
          state.loading = false;
      })
      .addCase(searchLongtermFilesAPI.fulfilled, (state, action) => {
        
        
        if (action.payload.found) {
          const {fileID, ragFilePreview, ragFileURL, initialPage} = action.payload;
          const newResources = [//TODO: location may be off by one
          {id: fileID, type: 'file', title: 'Rag File', url: ragFileURL, previewImageUrl: ragFilePreview, locations: [initialPage, 2, 1]}
          ]
          if (state.currentConversationID) {
            state.resources = newResources;
          }
          
        }


      })
      .addCase(searchLongtermFilesAPI.rejected, (state, action) => {
        console.error('Error searching files:', action.payload);
      })
      .addCase(searchLongtermFilesAPI.pending, (state, action) => {
        // state.loading = true;
      })
      .addCase(editChatTitleAPI.pending, (state, action) => {
        const {conversationID, newTitle} = action.meta.arg;
        state.storedChatName = state.IDToChatTitle[state.selectedCourse][conversationID];
        state.IDToChatTitle[state.selectedCourse][conversationID] = newTitle;
        state.loading = true;
      })
      .addCase(editChatTitleAPI.fulfilled, (state, action) => {
        // const {conversationID, newTitle} = action.payload;
        // state.IDToChatTitle[state.selectedCourse][conversationID] = newTitle;
        state.loading = false;
      })
      .addCase(editChatTitleAPI.rejected, (state, action) => {
        customLog('editChatTitleAPI.rejected:', action.payload);
        const {conversationID, newTitle} = action.meta.arg;
        state.IDToChatTitle[state.selectedCourse][conversationID] = state.storedChatName;
        state.storedChatName = "Error";
        state.loading = false;
      })
      .addCase(removeChatAPI.pending, (state, action) => {
          // Assume the chat is successfully removed
        const { conversationID } = action.meta.arg;
        
        // Temporarily store data for possible rollback
        const previousData = {
          conversation: state.IDToConversation[state.selectedCourse][conversationID],
          chatTitle: state.IDToChatTitle[state.selectedCourse][conversationID],
          currentOrderedChatIDs: [...state.currentOrderedChatIDs],
          courseIDsToRecentChatIDs: [...state.courseIDsToRecentChatIDs[state.selectedCourse]]
        };
        state.previousRemoveChatData = previousData; // Store previous data in state for rollback

        // Optimistically delete the chat
        delete state.IDToConversation[state.selectedCourse][conversationID];
        delete state.IDToChatTitle[state.selectedCourse][conversationID];
        state.currentOrderedChatIDs = state.currentOrderedChatIDs.filter(id => id !== conversationID);
        state.courseIDsToRecentChatIDs[state.selectedCourse] = state.courseIDsToRecentChatIDs[state.selectedCourse].filter(id => id !== conversationID);

        // Update selected chat if current conversation is deleted
        if (state.currentConversationID === conversationID) {
          const remainingChatTitles = Object.keys(state.IDToChatTitle[state.selectedCourse]);
          // if (remainingChatTitles.length > 0) {
          //   const newSelectedChatTitle = remainingChatTitles[0];
          //   state.selectedChatTitle = state.IDToChatTitle[state.selectedCourse][newSelectedChatTitle];
          //   state.messages = state.IDToConversation[state.selectedCourse][newSelectedChatTitle];
          //   state.currentConversationID = newSelectedChatTitle;
          // } else {
            state.selectedChatTitle = "New Chat";
            state.currentConversationID = null;
            state.messages = [];
          // }
        }
        state.loading = true;
      })
      .addCase(removeChatAPI.fulfilled, (state, action) => {
        // const { conversationID } = action.payload;
        // delete state.IDToConversation[state.selectedCourse][conversationID];
        // delete state.IDToChatTitle[state.selectedCourse][conversationID];
        // // Remove conversationID from currentOrderedChatIDs
        // state.currentOrderedChatIDs = state.currentOrderedChatIDs.filter(id => id !== conversationID);

        // // Remove conversationID from courseIDsToRecentChatIDs
        // state.courseIDsToRecentChatIDs[state.selectedCourse] = state.courseIDsToRecentChatIDs[state.selectedCourse].filter(id => id !== conversationID);
        
        // if (state.currentConversationID === conversationID) { //TODO: can combine this with handleLoadChat 
        //   // Automatically select a new chatTitle
        //   // console.log("Current chat being deleted");
        //   const remainingChatTitles = Object.keys(state.IDToChatTitle[state.selectedCourse]);
        //   // if remaining chat titles exist & currentConversationID is not null
        //   customLog('remainingChatTitles in removeChatAPI.f is ' + remainingChatTitles);
        //   customLog('state.currentConversationID is ' + state.currentConversationID);
        //   if (remainingChatTitles.length > 0) {
        //     // Assuming the first item is the newest after sorting
        //     const newSelectedChatTitle = remainingChatTitles[0];
        //     state.selectedChatTitle = state.IDToChatTitle[state.selectedCourse][newSelectedChatTitle];
        //     state.messages = state.IDToConversation[state.selectedCourse][newSelectedChatTitle];
        //     state.currentConversationID = newSelectedChatTitle;
        //   } else {
        //     state.selectedChatTitle = "New Chat";
        //     state.currentConversationID = null;
        //     state.messages = [];
        //   }
        // }
        state.previousRemoveChatData = {};
        state.loading = false;
      })
      .addCase(removeChatAPI.rejected, (state, action) => {
          // Roll back to previous state
          const { previousRemoveChatData } = state;
          const { conversationID } = action.meta.arg;
          state.IDToConversation[state.selectedCourse][conversationID] = previousRemoveChatData.conversation;
          state.IDToChatTitle[state.selectedCourse][conversationID] = previousRemoveChatData.chatTitle;
          state.currentOrderedChatIDs = previousRemoveChatData.currentOrderedChatIDs;
          state.courseIDsToRecentChatIDs[state.selectedCourse] = previousRemoveChatData.courseIDsToRecentChatIDs;

          if (state.currentConversationID === conversationID) {
            state.selectedChatTitle = previousRemoveChatData.chatTitle;
            state.messages = previousRemoveChatData.conversation;
            state.currentConversationID = conversationID;
          }

          customLog('removeChatAPI.rejected: ', action.payload);
          state.loading = false;
      })
      .addCase(loginUserAPI.pending, (state, action) => {
        state.loading = true;
        if(!action.meta.arg.signup) {
          state.generalSnackbarMessage = "Logging In"
          state.generalSnackbarOpen = true;
        }
      })
      .addCase(loginUserAPI.fulfilled, (state, action) => {
        const {userName, APIToken, refreshToken} = action.payload;
        state.generalSnackbarOpen = false;
        state.refreshToken = refreshToken;
        state.APIToken = APIToken;
        state.username = userName;
        state.isLoggedIn = true;
        if (!action.payload.signup) {
          state.generalSnackbarMessage = "Successfully Logged In!"
          state.generalSnackbarOpen = true;
        }
        state.loading = false;
      })
      .addCase(loginUserAPI.rejected, (state, action) => { //TODO: Actual rejection logic
        customLog('Error logging in user:', action.payload);
        state.loading = false;
        state.generalSnackbarOpen = false;
        state.generalSnackbarMessage = "Login Failed"
        state.generalSnackbarOpen = true;
      })
      .addCase(getChatTitleAPI.fulfilled, (state, action) => { //TODO: Handle success
        const { chatTitle, conversation_id } = action.payload;
        state.selectedChatTitle = chatTitle;
        // console.log('conversation id in createChatTitle is ' + conversation_id);
        // Update the IDToChatTitle
        // if (!state.IDToChatTitle[state.selectedCourse]) {
        //   state.IDToChatTitle[state.selectedCourse] = {};
        // }
        state.IDToChatTitle[state.selectedCourse][conversation_id] = chatTitle;
        // Check if length of currentOrderedChatIDs is greater than 5
        if (state.currentOrderedChatIDs.length > 4) {
          state.currentOrderedChatIDs.pop();
          state.courseIDsToRecentChatIDs[state.selectedCourse].pop();
        }
        customLog('about to add conversation_id to currentOrderedChatIDs in createChatTitle.f');
        // Add conversationID to currentOrderedChatIDs
        state.currentOrderedChatIDs.unshift(conversation_id);


        // Add conversationID to courseIDsToRecentChatIDs
        // Check if courseIDsToRecentChatIDs[selectedCourse] exists, if not, initialize it as an empty array
        if (!state.courseIDsToRecentChatIDs[state.selectedCourse]) {
          state.courseIDsToRecentChatIDs[state.selectedCourse] = [];
        }
        state.courseIDsToRecentChatIDs[state.selectedCourse].unshift(conversation_id);

        state.loading = false;
      })
      .addCase(getChatTitleAPI.rejected, (state, action) => {
        console.error('Error getting chat title:', action.payload);
      })
      .addCase(getChatTitleAPI.pending, (state, action) => {
        // state.loading = true;
      })
      .addCase(getInitialUserData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getInitialUserData.fulfilled, (state, action) => {
        const {courseIDToCourseTitle, currentConversationID, currentCourse, currentMessages, 
              courseIDsToRecentChatIDs, IDToChatTitle, IDToConversation, 
              currentOrderedChatIDs, APIToken, email, flashcardGroups, quizzes, //firstName, TODO: uncomment once backend updated firstName
              //email, TODO: uncomment once backend updated email
            } = action.payload;
        //return ({IDToConversation, IDToChatTitle, currentOrderedChatIDs, courseIDsToRecentChatIDs});
        // state.courses = courses;        currentConversationID: mostRecentConversationID,
        // currentCourse: mostRecentCourse,
        // currentMessages: mostRecentMessages
        state.courses = courseIDToCourseTitle;
        state.courseIDsToRecentChatIDs = courseIDsToRecentChatIDs;
        state.IDToConversation = IDToConversation;
        state.IDToChatTitle = IDToChatTitle;
        state.selectedCourse = currentCourse;
        state.APIToken = APIToken;
        state.isLoggedIn = true;
        state.username = email;
        state.firstName = email;
        state.allFlashcardGroups = flashcardGroups;
        state.allQuizGroups = quizzes;
        //TODO: Uncomment once backend updated firstName
        //state.firstName = firstName;
        //TODO: Uncomment once backend updated email
        //state.email = email;
        if(!currentOrderedChatIDs) {
          state.currentOrderedChatIDs = [];
        } else {
          state.currentOrderedChatIDs = currentOrderedChatIDs;
        }
        
        
        
        
        
        // const {selectedCourse } = state;
        // const conversationId = action.payload;
        // state.messages = currentMessages;
        // state.currentConversationID = currentConversationID;
        // if (state.IDToConversation[currentCourse] && state.IDToConversation[currentCourse][currentConversationID]) {
        //   state.messages = state.IDToConversation[currentCourse][currentConversationID];
        //   state.selectedChatTitle = state.IDToChatTitle[currentConversationID] || "New Chat";
        // }
        
        // state.APIToken = APIToken;
        state.loading = false;
      })
      .addCase(getInitialUserData.rejected, (state, action) => {
        console.error('Error getting initial user data:', action.payload);
        state.loading = false;
        state.isLoggedIn = false;
      })
      .addCase(registerUserAPI.rejected, (state, action) => {
        console.error('Error registering user:', action.payload);
        state.generalSnackbarOpen = false;
        state.generalSnackbarMessage = "Sign Up Failed"
        state.generalSnackbarOpen = true;
        state.loading = false;
      })
      .addCase(registerUserAPI.pending, (state, action) => {
        // setGeneralSnackbarOpen: (state, action) => {
        //   state.generalSnackbarOpen = action.payload;
        // },
        // setGeneralSnackbarMessage: (state, action) => {
        //   state.generalSnackbarMessage = action.payload;
        // },
        state.generalSnackbarOpen = true;
        state.generalSnackbarMessage = "Signing Up"
        state.loading = true;
      })
      .addCase(registerUserAPI.fulfilled, (state, action) => {
        state.generalSnackbarOpen = false;
        //TODO: Figure out how to have this on screen for a sec after sign up
        // state.generalSnackbarMessage = "Welcome to Plato!"
        state.generalSnackbarMessage = "Welcome to Plato!"
        state.generalSnackbarOpen = true;
        state.loading = false;
      })
      .addCase(getFlashcardsAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getFlashcardsAPI.fulfilled, (state, action) => {
        const {fileID} = action.payload;
        // state.flashcards = flashcards;
        // customLog('flashcards from getFlashcardsAPI is ' + flashcards);
        // state.flashcardsScreenOpen = false;
        // state.selectedResource = {id: 'temp', type: 'flashcard', title: 'flashcardTest', content: flashcards};
        // state.selectedResourceID = 'test';
        // customLog('selectedResource from getFlashcardsAPI is ' + JSON.stringify(state.selectedResource));
        // state.loading = false;


        customLog('store.js getFlashcardsAPI called with ' + action.payload);
        // state.flashcardPrompt = action.payload;
        const flashcards = [{"Question": "What is the capital of France?", "Answer": "Paris"}, {"Question": "What is the capital of Germany?", "Answer": "Berlin"}];
        // state.selectedResourceID = 'temp';
        // state.selectedResourceType = 'flashcard';
        // state.selectedResource = {id: 'temp', type: 'flashcard', title: 'flashcardTest', content: flashcards};
        state.flashcardPrompt = fileID;
        state.flashcards = flashcards;
        customLog('flashcards from getFlashcardsAPI is ' + flashcards);
        state.flashcardsScreenOpen = false;
        state.selectedResource = {id: 'temp', type: 'flashcard', title: 'flashcardTest', content: flashcards};
        state.selectedResourceID = 'test';
        customLog('selectedResource from getFlashcardsAPI is ' + JSON.stringify(state.selectedResource));
      })
      .addCase(getFlashcardsAPI.rejected, (state, action) => {
        console.error('Error getting flashcards:', action.payload);
        state.loading = false;
      })
      .addCase(getQuizAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getQuizAPI.fulfilled, (state, action) => {
        // const {quiz} = action.payload;
        // state.quiz = quiz;
        // customLog('quiz from getQuizAPI is ' + quiz);
        // state.quizMeScreenOpen = false;
        // state.selectedResource = {id: 'temp', type: 'quiz', title: 'quizTest', content: quiz};
        // state.selectedResourceID = 'temp';
        // customLog('selectedResource from getQuizAPI is ' + JSON.stringify(state.selectedResource));
        // state.loading = false;

        const {fileID} = action.payload;


        customLog('store.js getQuizAPI called with ' + action.payload);
        const quiz = [{Q: "What is OOP?", A: "Out Of Pants", B: "Object Oriented Pussy", C: "Object Oriented Programming", D: "Out Of Place", Correct: "B"}, {Q: "What is the capital of France?", A: "Paris", B: "London", C: "Berlin", D: "Madrid", Correct: "A"}];
        state.quizPrompt = fileID;
        state.quiz = quiz;
        customLog('quiz from getQuizAPI is ' + quiz);
        state.quizMeScreenOpen = false;
        state.selectedResource = {id: 'temp', type: 'quiz', title: 'quizTest', content:  quiz};
        state.selectedResourceID = 'test';
        customLog('selectedResource from getQuizAPI is ' + JSON.stringify(state.selectedResource));
      })
      .addCase(getQuizAPI.rejected, (state, action) => {
        console.error('Error getting quiz:', action.payload);
        state.loading = false;
      })
      .addCase(getFlashcardsLibrary.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getFlashcardsLibrary.fulfilled, (state, action) => {
        // customLog('SETFLASHCARDS before ' + state.flashcards);
        // customLog('SETFLASHCARDS ACTION.PAYLOAD ' + JSON.stringify(action.payload));
        // const {flashcards} = action.payload;
        // state.flashcards = flashcards;
        // customLog('SETFLASHCARDS after ' + JSON.stringify(state.flashcards));
        // state.flashcardsScreenOpen = false;
        // state.currentFlashcardQuizIndex = 0;
        // state.selectedResource = {id: 'temp', type: 'flashcard', title: 'flashcardTest', content: flashcards};
        // state.selectedResourceID = 'test';
        state.currentFlashcardGroup = action.payload;
      })
      .addCase(getFlashcardsLibrary.rejected, (state, action) => {
        console.error('Error getting flashcards:', action.payload);
        state.loading = false;
      })
      .addCase(getQuizLibrary.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getQuizLibrary.fulfilled, (state, action) => {
        // customLog('SETFLASHCARDS before ' + state.flashcards);
        // customLog('SETFLASHCARDS ACTION.PAYLOAD ' + JSON.stringify(action.payload));
        const {formattedQuiz} = action.payload;
        state.quiz = formattedQuiz;
        // customLog('SETFLASHCARDS after ' + JSON.stringify(state.flashcards));
        state.flashcardsScreenOpen = false;
        state.currentFlashcardQuizIndex = 0;
        state.selectedResource = {id: 'temp', type: 'quiz', title: 'quizTest', content: formattedQuiz};
        state.selectedResourceID = 'test';
      })
      .addCase(getQuizLibrary.rejected, (state, action) => {
        console.error('Error getting quiz:', action.payload);
        state.loading = false;
      })
      .addCase(getAppendFlashcardToGroup.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAppendFlashcardToGroup.fulfilled, (state, action) => {
        const {flashcard, incrementCard} = action.payload;
        if (state.currentFlashcardGroup) {
          state.currentFlashcardGroup.flashcards.push(flashcard);
        }
        if (incrementCard) {
          state.currentFlashcardIndex += 1;
        }
        // state.currentFlashcardIndex += 1;
      })
  },
});

const handleSnackbarError = (message, dispatch) => {
  dispatch(setGeneralSnackbarMessage(message));
  dispatch(setGeneralSnackbarOpen(true));
};


// Async thunk for getting chat title from API
export const getChatTitleAPI = createAsyncThunk(
  'chat/getChatTitleAPI',
  async ({ conversation_id }, { dispatch, getState, rejectWithValue }) => {
    try {
      let chatTitle ="";
      const maxRetries = 10; // Maximum number of attempts
      const retryDelay = 3000; // Delay between attempts in milliseconds

      // Function to delay execution
      const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));
      customLog('getChatTitleAPI called for conversation_id ' + conversation_id);
      const attemptFetch = async (attempt) => {
        try { 
          customLog('beginning of try');
          const response = await fetch(DjangoHost+"/api/conversation/"+conversation_id+"/metadata/", {
            method: "GET",
            headers: {Authorization:  `Bearer ${getState().chat.APIToken}`}                   
          });
          customLog('after response');
          // const data = await response.json();
          // if (!response.ok) throw new Error('Request failed');
          customLog('this is attempt number ' + attempt + 'with max retries ' + maxRetries);
          customLog('conversation_id header is  ' + response.headers.get('conversation_id'));
          customLog('conversation_name header is ' + response.headers.get('conversation_name'));
          // customLog all headers from response
          // for (var pair of response.headers.entries()) {
          //   customLog(pair[0]+ ': '+ pair[1]);
          // }
          
          // let responseData = {
          //   // Extract and return necessary data from the response, e.g., headers
          //   conversationName: response.headers.get('conversation_name')
          // };
          // if (responseData.conversationName) {
          //   return responseData; // Return this data to the outer scope if conversationName is not null
          // } else {
          //   throw new Error('Conversation name not found');
          // }
          const data = await response.json();

          if(data.conversation_name) {
            chatTitle = data.conversation_name;
            return true;
          } else {
            throw new Error('Conversation name not found');
          }
          return chatTitle;
        } catch (error) {
          if (attempt < maxRetries) {
            await wait(retryDelay);
            return attemptFetch(attempt + 1);
          } else {
            customLog('else of getChatTitleAPI');
            dispatch(setGeneralSnackbarMessage('Failed to get chat title'));
            dispatch(setGeneralSnackbarOpen(true));
            throw error;
          }
        }
      };

      let fetchedData = await attemptFetch(1); // Start attempts with 1
      customLog('fetched data is ' + fetchedData);

      if (fetchedData) {
        // customLog('conversation_name from response header is ' + fetchedData.conversationName);
        // chatTitle = fetchedData.conversationName; // Example usage
        customLog('Chat title is ' + chatTitle);
        return ({chatTitle, conversation_id});
      }
      //TEMP UNTIL CHAT TITLE ENDPOINT UPDATED ON BACKEND
      // let chatTitle = "Temp Store!";

      customLog('outside fetchedData send');
      return ({chatTitle, conversation_id});
    } catch (error) {
      customLog('catch (error) of getChatTitleAPI');
      dispatch(setGeneralSnackbarMessage('Failed to get chat title'));
      dispatch(setGeneralSnackbarOpen(true));
      return rejectWithValue(error.message);
    }
  });

    // Async thunk for creating a course
    export const createCourseAPI = createAsyncThunk(
      'chat/createCourseAPI',
      async (courseName, { dispatch, getState, rejectWithValue }) => {
        try {

          customLog('createCourseAPI called with courseName ' + courseName);
          // Call the API endpoint to create a new course
          const response = await fetch(DjangoHost+"/api/course/", {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization:  `Bearer ${getState().chat.APIToken}`,
            },
            body: JSON.stringify({ course_name: courseName }),
          });
            
          if (!response.ok) {
            customLog('!response.ok of createCourseAPI');
            dispatch(setGeneralSnackbarMessage('Failed to create course'));
            dispatch(setGeneralSnackbarOpen(true));
            throw new Error('Failed to create course');
          }
    
          if (response.status === 201) {
            const newCourse = await response.json();
            const courseID = newCourse.course_id;

            return ({courseName, courseID});  
          } else {
            customLog('response.status !== 200 of createCourse');
            dispatch(setGeneralSnackbarMessage('Failed to createCourse'));
            dispatch(setGeneralSnackbarOpen(true));
            throw new Error('Failed to create course with response status ' + response.status + ' and response text ' + response.text());
          }

        } catch (error) {
          customLog('catch (error) of createCourseAPI');
          dispatch(setGeneralSnackbarMessage('Failed to createCourse'));
          dispatch(setGeneralSnackbarOpen(true));
          return rejectWithValue(error.message);
        }
      }
    );


// Async thunk for logging in user
export const loginUserAPI = createAsyncThunk(
  'chat/loginUserAPI',
  async ({ userName, password, signup }, { dispatch, getState, rejectWithValue }) => {
    try {
      // console.log("entered loginUserAPI");
      // Call the API endpoint to log in the user
      customLog('loginUserAPI called with userName ' + userName + ' and password ' + password);
      const response = await fetch(DjangoHost+"/api/token/", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          
        },
        body: JSON.stringify({ username: userName, password }),
      });
        
      if (!response.ok) {
        throw new Error('Failed to log in the user');
      }

      const data = await response.json();
      // console.log("response good");
      // Print JSON response to console
      // console.log(data);
      
      // Set the created course as the selected course
      //console.log(...getState().chat.courses);
      // dispatch(setCourses([...getState().chat.courses, courseName]));
      // dispatch(setSelectedCourse(newCourse));
      const APIToken = data.access;
      const refreshToken = data.refresh;
      customLog('APIToken for this session is ' + APIToken);

      // Set the cookies for the tokens
      document.cookie = `jwt=${APIToken};path=/;secure;samesite=strict`;
      document.cookie = `refreshToken=${refreshToken};path=/;secure;samesite=strict`;

      // console.log("finna send with APIToken " + APIToken);
      return ({userName, APIToken, refreshToken, signup});
      /* NO BACKEND USE
      return courseName
      */
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Async think for getting the flashcards associated with a file
export const getFlashcardsAPI = createAsyncThunk(
  'chat/getFlashcardsAPI',
  async (fileID, { dispatch, getState, rejectWithValue }) => {
    try {
      customLog('getFlashcardsAPI called');
      // Call the API endpoint to get the flashcards associated with a file
      //TODO: Uncomment once flashcards API is done
      // const response = await fetch(DjangoHost+"/api/flashcards/"+fileID, {
      //   method: 'GET',
      //   headers: {
      //     Authorization:  `Bearer ${getState().chat.APIToken}`,
      //   },
      // });
      // customLog('after response in getFlashcardsAPI');
      // if (!response.ok) {
      //   throw new Error('Failed to fetch flashcards');
      // }
      // customLog('before data in getFlashcardsAPI');
      // const flashcards = await response.json();
      // customLog('Flashcards data: ' + JSON.stringify(flashcards));
      //TEMP: flashcards test placeholder
      const flashcards = [{"Question": "What is the capital of France?", "Answer": "Paris"}, {"Question": "What is the capital of Germany?", "Answer": "Berlin"}];
      return ({fileID});
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getAppendFlashcardToGroup = createAsyncThunk(
  '/chat/getAppendFlashcardToGroup',
  async ({flashcard, incrementCard}, { dispatch, getState, rejectWithValue}) => {
    try {
      customLog('GETAPPENDFLASHCARDTOGROUP with flashcard of ' + JSON.stringify(flashcard));

      return ({flashcard, incrementCard});
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
)

// Async think for getting the flashcards associated with a file
export const getFlashcardsLibrary = createAsyncThunk(
  'chat/getFlashcardsLibrary',
  async (item, { dispatch, getState, rejectWithValue }) => {
    try {
      customLog('getFlashcardsLibrary called');
      // Call the API endpoint to get the flashcards associated with a file
      //TODO: Uncomment once flashcards API is done
      // const response = await fetch(DjangoHost+"/api/flashcards/"+fileID, {
      //   method: 'GET',
      //   headers: {
      //     Authorization:  `Bearer ${getState().chat.APIToken}`,
      //   },
      // });
      // customLog('after response in getFlashcardsAPI');
      // if (!response.ok) {
      //   throw new Error('Failed to fetch flashcards');
      // }
      // customLog('before data in getFlashcardsAPI');
      // const flashcards = await response.json();
      // customLog('Flashcards data: ' + JSON.stringify(flashcards));
      //TEMP: flashcards test placeholder
      // const flashcards = [{"Question": "What is the capital of France?", "Answer": "Paris"}, {"Question": "What is the capital of Germany?", "Answer": "Berlin"}];
      return ({item});
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Async think for getting the flashcards associated with a file
export const getQuizLibrary = createAsyncThunk(
  'chat/getQuizLibrary',
  async (formattedQuiz, { dispatch, getState, rejectWithValue }) => {
    try {
      customLog('getQuizLibrary called');
      // Call the API endpoint to get the flashcards associated with a file
      //TODO: Uncomment once flashcards API is done
      // const response = await fetch(DjangoHost+"/api/flashcards/"+fileID, {
      //   method: 'GET',
      //   headers: {
      //     Authorization:  `Bearer ${getState().chat.APIToken}`,
      //   },
      // });
      // customLog('after response in getFlashcardsAPI');
      // if (!response.ok) {
      //   throw new Error('Failed to fetch flashcards');
      // }
      // customLog('before data in getFlashcardsAPI');
      // const flashcards = await response.json();
      // customLog('Flashcards data: ' + JSON.stringify(flashcards));
      //TEMP: flashcards test placeholder
      // const flashcards = [{"Question": "What is the capital of France?", "Answer": "Paris"}, {"Question": "What is the capital of Germany?", "Answer": "Berlin"}];
      return ({formattedQuiz});
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Async think for getting the flashcards associated with a file
export const getQuizAPI = createAsyncThunk(
  'chat/getQuizAPI',
  async (fileID, { dispatch, getState, rejectWithValue }) => {
    try {
      customLog('getQuizAPI called');
      // Call the API endpoint to get the flashcards associated with a file
      //TODO: Uncomment once flashcards API is done
      // const response = await fetch(DjangoHost+"/api/flashcards/"+fileID, {
      //   method: 'GET',
      //   headers: {
      //     Authorization:  `Bearer ${getState().chat.APIToken}`,
      //   },
      // });
      // customLog('after response in getFlashcardsAPI');
      // if (!response.ok) {
      //   throw new Error('Failed to fetch flashcards');
      // }
      // customLog('before data in getFlashcardsAPI');
      // const flashcards = await response.json();
      // customLog('Flashcards data: ' + JSON.stringify(flashcards));
      //TEMP: flashcards test placeholder
      const quiz = [{Q: "What is OOP?", A: "Out Of Pants", B: "Object Oriented Pussy", C: "Object Oriented Programming", D: "Out Of Place", Correct: "B"}, {Q: "What is the capital of France?", A: "Paris", B: "London", C: "Berlin", D: "Madrid", Correct: "A"}];
      return ({fileID});
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchSimilarFlashcard = (flashcardId) => async (dispatch, getState) => {
  try {
    const response = await fetch(`/api/flashcard/similar/${flashcardId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getState().chat.APIToken}`,
      },
    });
    
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    
    const similarFlashcard = await response.json();
    // dispatch(addSimilarFlashcard(similarFlashcard));
  } catch (error) {
    console.error('Error fetching similar flashcard:', error);
  }
};

// Define the getCookie function to parse cookies by name
const getCookie = (name) => {
  const cookies = document.cookie.split('; ').reduce((acc, cookie) => {
    const [key, value] = cookie.split('=');
    acc[key] = value;
    return acc;
  }, {});
  return cookies[name];
};

export const getSimilarFlashcard = createAsyncThunk(
  'chat/getSimilarFlashcard',
  async ({ flashcardID }, { dispatch, getState }) => {
    const { APIToken, currentFlashcardQuizIndex } = getState().chat;
    customLog('GETSIMILARFLASHCARD API call');
    dispatch(setGeneralSnackbarMessage("Plato's making another one!"));
    dispatch(setGeneralSnackbarOpen(true));
    try {
      const response = await fetch(DjangoHost + "/api/flashcards/similar/", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${APIToken}`,
        },
        body: JSON.stringify({ flashcard_id: flashcardID }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const flashcard = await response.json();
      // customLog('GETSIMILAR FLASHCARD RECEIVED ' + JSON.stringify(flashcard));
      // dispatch(addFlashcard(flashcard));
      // dispatch(changeFlashcardQuizIndex(1));
      customLog('GETSIMILARFLASHCARD inserting ' + JSON.stringify(flashcard));
      dispatch(insertFlashcard({flashcard}));
    } catch (error) {
      console.error("Error in getSimilarFlashcard:", error);
    }
  }
);

export const getSimilarQuiz = createAsyncThunk(
  'chat/getSimilarQuiz',
  async ({ quizID }, { dispatch, getState }) => {
    const { APIToken, currentFlashcardQuizIndex } = getState().chat;
    try {
      dispatch(setGeneralSnackbarMessage("Plato's making another one!"));
      dispatch(setGeneralSnackbarOpen(true));
      const response = await fetch(DjangoHost + "/api/quiz/question/similar/", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${APIToken}`,
        },
        body: JSON.stringify({ quiz_question_id: quizID }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const quizQuestion = await response.json();
      // const formattedQuestion = {
      //   Q: quizQuestion.question,
      //   A: quizQuestion.choices[0].body,
      //   B: quizQuestion.choices[1].body,
      //   C: quizQuestion.choices[2].body,
      //   D: quizQuestion.choices[3].body,
      //   Correct: String.fromCharCode(65 + quizQuestion.choices.findIndex(choice => choice.is_correct)),
      //   id: quizQuestion.id,
      // };
      // dispatch(addQuizQuestion(formattedQuestion));
      // dispatch(changeFlashcardQuizIndex(1));
      dispatch(insertQuizQuestion({quizQuestion}));
    } catch (error) {
      console.error("Error in getSimilarQuiz:", error);
    }
  }
);



// Async thunk for fetching initial user data
export const getInitialUserData = createAsyncThunk(
  'chat/getInitialUserData',
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      const APIToken = getCookie('jwt');
      dispatch(setIsLoggedIn(true));
      customLog('getInitialUserData called with current APIToken ' + getState().chat.APIToken);
      const response = await fetch(DjangoHost+"/api/initial-user-data/", {
        method: 'GET',
        headers: {
          Authorization:  `Bearer ${APIToken}`,
        },
      });
      customLog('after response in getInitialUserData');
      if (!response.ok) {
        throw new Error('Failed to fetch initial user data');
      }
      
      customLog('before data in getInitialUserData');
      const data = await response.json();
      customLog('Initial user data: ' + JSON.stringify(data));

      const courses = data.info.courses;
      const email = data.info.email;
      customLog('EMAILIS ' + email);
      //TODO: Uncomment this once the backend is updated firstName
      //let firstName = data.info.first_name;
      //TODO: Uncomment this once the backend is updated email
      //let email = data.info.email;
      const flashcardGroups = data.info.flashcard_groups || [];
      const quizzes = data.info.quizzes || [];
      const IDToConversation = {};
      const IDToChatTitle = {};
      let currentOrderedChatIDs = [];
      const courseIDsToRecentChatIDs = {};
      let courseIDToCourseTitle = {};

      let mostRecentConversationID = null;
      let mostRecentCourse = null;
      let mostRecentMessages = [];
      const startWithFilter = performance.now();
      courses.forEach(course => {
        const courseID = course.course_id;
        mostRecentCourse = courseID;
        //Initialize empty objects for courses
        IDToConversation[courseID] = {};
        IDToChatTitle[courseID] = {};
        courseIDToCourseTitle[courseID] = course.course_name;
        customLog('current course name is ' + course.course_name);
        
        //Process 5 most recent conversations per course
        course.top_5_conversations.forEach(conversation => {
          // const messages = conversation.messages.map(message => ({
          //   user: message.author ? "User" : "Assistant",
          //   text: message.message,
          // }));
          const messages = conversation.messages
          // .filter(message => !message.message.startsWith("[{'role': 'system',")) //TODO: This should be server side
          .map(message => ({
            user: message.author ? "User" : "Assistant",
            text: message.message,
          }));
          messages.reverse();
          IDToConversation[courseID][conversation.conversation_id] = messages;
          IDToChatTitle[courseID][conversation.conversation_id] = conversation.conversation_name;
          customLog('conversation name is ' + conversation.conversation_name);

          // Add to courseIDsToRecentChatIDs. Create empty array if doesn't exist
          if (!courseIDsToRecentChatIDs[courseID]) {
            courseIDsToRecentChatIDs[courseID] = [];
          }
          courseIDsToRecentChatIDs[courseID].push(conversation.conversation_id);
          // Update most recent conversation details if it's the first in the list
          if (conversation === course.top_5_conversations[0]) {
            mostRecentConversationID = conversation.conversation_id;
            mostRecentCourse = courseID;
            mostRecentMessages = messages;
          }
        });
      });
      const endWithFilter = performance.now();
      customLog('TIMETAKEN FILTER is: ' + (endWithFilter - startWithFilter) + 'ms');

      //Update currentOrderedChatIDs
      if (courses.length > 0) {
        // Assuming the first course is the currently selected one
        currentOrderedChatIDs = courseIDsToRecentChatIDs[mostRecentCourse] || [];
      }

      customLog('IDToConversation is ' + JSON.stringify(IDToConversation));
      customLog('IDToChatTitle is ' + JSON.stringify(IDToChatTitle));
      customLog('currentOrderedChatIDs is ' + JSON.stringify(currentOrderedChatIDs));
      customLog('courseIDsToRecentChatIDs is ' + JSON.stringify(courseIDsToRecentChatIDs));
      customLog('currentConversationID is ' + mostRecentConversationID);
      customLog('currentCourse is ' + mostRecentCourse);
      customLog('currentMessages is ' + JSON.stringify(mostRecentMessages));
      customLog('current courses are ' + JSON.stringify(courseIDToCourseTitle));
      return ({
        IDToConversation, 
        IDToChatTitle, 
        currentOrderedChatIDs, 
        courseIDsToRecentChatIDs,
        currentConversationID: mostRecentConversationID,
        currentCourse: mostRecentCourse,
        currentMessages: mostRecentMessages,
        courseIDToCourseTitle,
        APIToken,
        email,
        flashcardGroups,
        quizzes,
        //firstName TODO: Uncomment this once the backend is updated firstName
        //email TODO: Uncomment this once the backend is updated email
      });
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Async thunk for registering a user
export const registerUserAPI = createAsyncThunk(
  'chat/registerUserAPI',
  async ({ user_name, first_name, last_name, email, password, is_student,
            is_individual }, { dispatch, getState, rejectWithValue }) => {
    try {
      customLog('registerUserAPI called');
      // console.log("entered registerUserAPI");
      // Call the API endpoint to register the user
      const response = await fetch(DjangoHost+"/api/register/", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({email,
                              password,
                              is_student,
                              is_individual }),
      });
      customLog('before response.ok');
        
      if (!response.ok) {
        throw new Error('Failed to register the user');
      }
      customLog('after data before response.status in registerUserAPI');
      const courseName = 'All Courses';
      // If response code is 201
      if (response.status === 201) {
        //Login user
        customLog('login called from register thunk');
        await dispatch(loginUserAPI({userName: user_name, password, signup: true}));
        dispatch(createCourseAPI(courseName));
      } else {
        customLog('response status is ' + response.status);
      } 
      return ({hi: false});
      /* NO BACKEND USE
      return courseName
      */
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

  // Async thunk for creating a course
  export const updateCourseAPI = createAsyncThunk(
    'chat/updateCourseAPI',
    async ({courseName, courseId}, { dispatch, getState, rejectWithValue }) => {
      try {
        customLog("entered updateCourseAPI with courseID " + courseId + " and course name " + courseName);
        if (courseId == getState().chat.allCoursesID) {
          return;
        }
        
        // Call the API endpoint to create a new course
        const response = await fetch(`${DjangoHost}/api/course/${courseId}/`, {
          method: 'PUT',
          headers: {
             'Content-Type': 'application/json',
            Authorization:  `Bearer ${getState().chat.APIToken}`,
          },
          body: JSON.stringify({ course_name: courseName }),
        });
          
        if (!response.ok) {
          customLog('!response.ok of updateCourseAPI');
          dispatch(setGeneralSnackbarMessage('Failed to edit course name'));
          dispatch(setGeneralSnackbarOpen(true));
          throw new Error('Failed to edit course name');
        }
  
        // const newCourse = await response.json();
        // console.log("response good");
        // Print JSON response to console
        // console.log(newCourse);
        
        // Set the created course as the selected course
        //console.log(...getState().chat.courses);
        // dispatch(setCourses([...getState().chat.courses, courseName]));
        // dispatch(setSelectedCourse(newCourse));
        // const courseID = newCourse.course_id;
        // console.log("finna send with courseID " + courseId + " and course name " + courseName);
        return ({courseName, courseId});
        /* NO BACKEND USE
        return courseName
        */
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

    // Async thunk for creating a course
    export const deleteCourseAPI = createAsyncThunk(
      'chat/deleteCourseAPI',
      async ({courseName, courseId}, { dispatch, getState, rejectWithValue }) => {
        try {
          customLog("entered deleteCourseAPI with courseID " + courseId + " and course name " + courseName);
          // Call the API endpoint to create a new course
          if (courseId == getState().chat.allCoursesID) {
            return;
          }
          const response = await fetch(`${DjangoHost}/api/course/${courseId}/`, {
            method: 'DELETE',
            headers: {
               'Content-Type': 'application/json',
              Authorization:  `Bearer ${getState().chat.APIToken}`,
            },
            body: JSON.stringify({ course_name: courseName }),
          });
            
          if (!response.ok) {
            customLog('!response.ok of deleteCourseAPI');
            dispatch(setGeneralSnackbarMessage('Failed to delete course'));
            dispatch(setGeneralSnackbarOpen(true));
            throw new Error('Failed to delete the course');
          }
  
          return ({courseId});
        } catch (error) {
          return rejectWithValue(error.message);
        }
      }
    );

  // // Async thunk for creating a course
  // export const uploadContextFileAPI = createAsyncThunk(
  //   'chat/uploadContextFileAPI',
  //   async (file, { dispatch, getState, rejectWithValue }) => {
  //     try {
  //       customLog("entered uploadContextFileAPI for conversation_id " + getState().chat.currentConversationID);
        
  //       // Initialize FormData object
  //       const formData = new FormData();
  //       formData.append('file', file);
        
  //       // Call the API endpoint to upload file
  //       const response = await fetch(DjangoHost+"/api/context-file/"+ getState().chat.currentConversationID + '/', {
  //         method: 'POST',
  //         headers: {
  //           // 'Content-Type': 'multipart/form-data',
  //           Authorization:  `Bearer ${getState().chat.APIToken}`,
  //         },
  //         body: formData,
  //         redirect: 'follow'
  //       });
  //       // customLog('response for uploadContextFileAPI' +  response.text());
          
  //       if (!response.ok) {
  //         customLog('UPLOADCONTEXTFILEAPI !RESPONSE.OK');
  //         const errorResponse = await response.json();

  //         const errorMessage = errorResponse.error || 'Unkown error occurred';

  //         customLog('in !response.ok of uploadContextFileAPI:', errorMessage);
  //         dispatch(setGeneralSnackbarMessage('Failed to upload short-term memory file'));
  //         dispatch(setGeneralSnackbarOpen(true));
  //         return rejectWithValue(errorMessage);
  //       }
        
  //       const status = await response.json();
  //       customLog("response good for uploadContextFileAPI");

  //       customLog("url for shortTermContextFile is " + status.url);

  //       const url = status.url;
        
  //       return ({fileURL: url, fileID: url, filePreview: ragPreview, initialPage: 0});
  //     } catch (error) {
  //       customLog('in catch block of uploadContextFileAPI');
  //       return rejectWithValue(error.message);
  //     }
  //   }
  // );

  // Async thunk for uploading context file
export const uploadContextFileAPI = createAsyncThunk(
  'chat/uploadContextFileAPI',
  async (file, { dispatch, getState, rejectWithValue }) => {
    const maxRetries = 3; // Maximum number of attempts
    const retryDelay = 2000; // Delay between attempts in milliseconds

    // Function to delay execution
    const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const attemptUpload = async (attempt) => {
      try {
        customLog(`Attempt ${attempt} of uploadContextFileAPI for conversation_id ${getState().chat.currentConversationID}`);
        
        // Initialize FormData object
        const formData = new FormData();
        formData.append('file', file);
        
        // Call the API endpoint to upload file
        const response = await fetch(DjangoHost+"/api/context-file/"+ getState().chat.currentConversationID + '/', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${getState().chat.APIToken}`,
          },
          body: formData,
          redirect: 'follow'
        });

        if (!response.ok) {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.error || 'Unknown error occurred';
          throw new Error(errorMessage);
        }
        
        const status = await response.json();
        customLog("Response good for uploadContextFileAPI");
        customLog("URL for shortTermContextFile is " + status.url);
        customLog('UPLOADCONTEXTFILEAPI JSON STRINGIFY of status is ' + JSON.stringify(status));
        dispatch(setGeneralSnackbarMessage("Plato's reading your file!"));
        dispatch(setGeneralSnackbarOpen(true));
        return {
          fileURL: status.url, 
          fileID: status.file_id, 
          filePreview: ragPreview, 
          initialPage: 0
        };
      } catch (error) {
        if (attempt < maxRetries) {
          customLog(`Upload attempt ${attempt} failed, retrying in ${retryDelay/1000} seconds...`);
          await wait(retryDelay);
          return attemptUpload(attempt + 1);
        } else {
          throw error;
        }
      }
    };

    try {
      return await attemptUpload(1); // Start attempts with 1
    } catch (error) {
      customLog('All attempts of uploadContextFileAPI failed');
      dispatch(setGeneralSnackbarMessage('Failed to upload short-term memory file'));
      dispatch(setGeneralSnackbarOpen(true));
      return rejectWithValue(error.message);
    }
  }
);

    // Async thunk for creating a course
    export const uploadLongtermFileAPI = createAsyncThunk(
      'chat/uploadLongtermFileAPI',
      async (file, { dispatch, getState, rejectWithValue }) => {
        try {
          const selectedCourseID = getState().chat.selectedCourse;
          const APIToken = getState().chat.APIToken;
          const allCoursesID = getState().chat.allCoursesID;
          customLog("entered uploadLongtermFileAPI for course_id " + selectedCourseID);
          
          const uploadFile = async (courseID) => {
            // Initialize FormData object
            const formData = new FormData();
            formData.append('file', file);
            formData.append('course_id', courseID);
            
            // Call the API endpoint to upload file
            const response = await fetch(DjangoHost+"/api/upload-class-file/", {
              method: 'POST',
              headers: {
                // 'Content-Type': 'multipart/form-data',
                Authorization:  `Bearer ${APIToken}`,
              },
              body: formData,
            });
              
            if (!response.ok) {
              dispatch(setGeneralSnackbarMessage('Failed to upload long-term memory file'));
              dispatch(setGeneralSnackbarOpen(true));
              throw new Error('Failed to upload file');
            }
            return await response.json();

          }

    
          const response = await uploadFile(selectedCourseID);
          customLog(`File uploaded to course ${selectedCourseID}`);

          // if (selectedCourseID !== allCoursesID) {
          //   await uploadFile(allCoursesID);
          //   customLog(`File also uploaded to allCoursesID ${allCoursesID}`);
          // }
          
          return;
        } catch (error) {
          return rejectWithValue(error.message);
        }
      }
    );

  // Async thunk for creating a course
  export const searchLongtermFilesAPI = createAsyncThunk(
    'chat/',
    async (input, { dispatch, getState, rejectWithValue }) => {
      try {
        customLog("entered searchLongtermFilesAPI for course_id " + getState().chat.selectedCourse + " and input " + input);
        
        
        // Call the API endpoint to upload file
        const response = await fetch(DjangoHost+`/api/search-class-files/?course_id=${getState().chat.selectedCourse}&search_string=${input}`, {
          method: 'GET',
          headers: {
            // 'Content-Type': 'multipart/form-data',
            Authorization:  `Bearer ${getState().chat.APIToken}`,
          }
        });
          
        if (!response.ok) {
          throw new Error('Failed to search file');
        }

        // const myHeaders = new Headers();
        // myHeaders.append("Authorization", "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzA5NzgzNDk3LCJpYXQiOjE3MDk3NDAyOTcsImp0aSI6IjdjNzE0ZTFkMzA5MTQxYTg5MjgwYzk5MDliYjY1ZjAwIiwidXNlcl9pZCI6Ijg0MDVmNzZkLTc4ZTYtNDk5OC1iZTY1LTJlNTk1NTFjMTA2OCJ9.vho0nzsVcalsKR4lr1Ee_I_TvDSyBahXTKW78WFIRGc");

        // const requestOptions = {
        //   method: "GET",
        //   headers: myHeaders,
        //   redirect: "follow"
        // };

        // const response = await fetch(`http://127.0.0.1:8000/api/search-class-files/?course_id=${getState().chat.selectedCourse}&search_string=${input}`, requestOptions)
        // .then((response) => response.text())
        // .then((result) => console.log(result))
        // .catch((error) => console.error(error));
        
        // console.log('the response for search file is ' + response.json());

        const data = await response.json();
        // console.log("search files response good");

        // If your API response structure contains 'matches', print them
        // if (data.matches) {
        //   // console.log('matches does exist');
        //   // data.matches.forEach(match => {
        //   //   console.log(`filepath: ${match.filePath}, 
        //   //   parentFileID: ${match.parentFileID},
        //   //   score: ${match.score}
        //   //   pageNum: ${match.pageNum}`);
        //   // });
        // } else {
        //   console.log('no matches');
        // }
        // console.log('this is the data response' + JSON.stringify(data));

        // if the length of matches is greater than 0
        if (data.matches.length > 0) {
          customLog('matches is ' + JSON.stringify(data.matches));
          const fileID = data.matches[0].parentFileID;
          const initialPage = data.matches[0].pageNum + 1;

          const ragFileResponse = await fetch(DjangoHost+`/api/rag-file/${fileID}`, {
            method: 'GET',
            headers: {
              // 'Content-Type': 'multipart/form-data',
              Authorization:  `Bearer ${getState().chat.APIToken}`,
            }
          });

          if (!ragFileResponse.ok) {
            throw new Error('Failed to get rag file');
          }

          const ragFileData = await ragFileResponse.json();

          const ragFileURL = ragFileData.file_url;
          // const ragFilePreview = ragFileData.preview_url; //TODO: Replace once preview image available
          const ragFilePreview = ragPreview;

          customLog('file url is ' + ragFileURL);
          customLog('initialPage is ' + initialPage);
          customLog('similarity score is ' + data.matches[0].score);
          
          return ({found: true, fileID, ragFileURL, ragFilePreview, initialPage});
        }
        customLog('no matches found in longterm files');
        return ({found: false});
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

  // Async thunk for deleting a course
export const deleteCourse = createAsyncThunk(
    'chat/deleteCourse',
    async (courseId, { dispatch, rejectWithValue }) => {
      try {
        // Call the API endpoint to delete the course
        const response = await fetch('API_ENDPOINT_FOR_DELETING_COURSE', {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ courseId }),
        });
  
        if (!response.ok) {
          throw new Error('Failed to delete the course');
        }
  
        // Set the selected course to null
        dispatch(setSelectedCourse(null));
        
        return courseId;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

  export const loadMostRecentChat = createAsyncThunk(
    'chat/loadMostRecentChat',
    async (courseId, {getState, dispatch }) => {
      return courseId;
    }
  )

// Async thunk for editing chat title
export const editChatTitleAPI = createAsyncThunk(
  'chat/editChatTitleAPI',
  async ({conversationID, newTitle}, { dispatch, getState, rejectWithValue }) => {
    try {
      customLog("entered editChatTitleAPI with conversationID " + conversationID + " and newTitle " + newTitle);
      
      // Call the API endpoint to edit a chat title
      const response = await fetch(DjangoHost+`/api/conversaion/${conversationID}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization:  `Bearer ${getState().chat.APIToken}`,
        },
        body: JSON.stringify({ conversation_name: newTitle }),
      });
        
      if (!response.ok) {
        customLog('!response.ok of editChatTitleAPI');
        dispatch(setGeneralSnackbarMessage('Failed to edit chat title'));
        dispatch(setGeneralSnackbarOpen(true));
        throw new Error('Failed to edit chat title');
      }

      if (response.status === 200) {
        return ({conversationID, newTitle});
      } else {
        customLog('response.status !== 200 of editChatTitleAPI');
        dispatch(setGeneralSnackbarMessage('Failed to edit chat title'));
        dispatch(setGeneralSnackbarOpen(true));
        throw new Error('Failed to edit the chat title with response status ' + response.status + ' and response text ' + response.text());
      }
      
    } catch (error) {
      customLog('catch (error) of editChatTitleAPI');
      dispatch(setGeneralSnackbarMessage('Failed to edit chat title'));
      dispatch(setGeneralSnackbarOpen(true));
      return rejectWithValue(error.message);
    }
  }
);

    // Async thunk for removing a chat
    export const removeChatAPI = createAsyncThunk(
      'chat/removeChatAPI',
      async ({conversationID}, { dispatch, getState, rejectWithValue }) => {
        try {
          customLog("entered removeChatAPI with conversationID " + conversationID);
          // Call the API endpoint to remove a chat
          const response = await fetch(DjangoHost+`/api/conversation/${conversationID}/`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization:  `Bearer ${getState().chat.APIToken}`,
            },
          });
            
          if (!response.ok) {
            customLog('!response.ok of removeChatAPI');
            dispatch(setGeneralSnackbarMessage('Failed to remove chat'));
            dispatch(setGeneralSnackbarOpen(true));
            throw new Error('Failed to create the course');
          }
    
          if (response.status === 200) {
            return ({conversationID});
          } else {
            throw new Error('Failed to remove chat with response status ' + response.status + ' and response text ' + response.text());
          }
        } catch (error) {
          return rejectWithValue(error.message);
        }
      }
    );

// Configure the Redux store
export const store = configureStore({
  reducer: {
    chat: chatSlice.reducer,
  },
  //middleware: [thunk],
});

export const {
  setDrawerOpen,
  setIDToConversation: setSavedChats,
  setChatTitle,
  setMessages,
  setInput,
  setLoading,
  setCoursePopover,
  setUserPopover,
  handleLoadChatRedux,
  setSelectedCourse,
  setCourses,
  editCourseName,
  removeCourse,
  //setEditingChatTitle,
  setEditedChatTitleRedux,
  setConversationID,
  removeChat,
  setSelectedResource,
  handleNewChat,
  setSelectedCourseTitle,
  setSystemPrompt,
  setSettingsOpen,
  setSnackbarRecordingOpen,
  setSnackbarShareChatOpen,
  setSnackbarRecordingMessage,
  addStreamedMessage,
  addMessage,
  setMostRecentUserPrompt,
  setResources,
  setGeneralSnackbarOpen,
  setGeneralSnackbarMessage,
  removeResources,
  setIsShortTermMemory,
  setIsLongTermMemory,
  addNewChat,
  removeSelectedResource,
  setFlashcardsScreenOpen,
  setCurrentFlashcardPDF,
  sendMessageToAPI,
  setQuizMeScreenOpen,
  setTheme,
  setIframeDragged,
  setAbortion,
  setIsLoggedIn,
  setLoginSignupScreenOpen,
  setFlashcardPrompt,
  setQuizPrompt,
  setFlashcardQuizObject,
  changeFlashcardQuizIndex,
  setFlashcardQuizIndex,
  clearChatHistory,
  addPresetPrompt,
  signOut,
  setFlashcards,
  setQuiz,
  addFlashcard,
  addQuizQuestion,
  appendFlashcard,
  setCurrentFlashcardGroupID,
  appendQuizQuestion,
  setCurrentQuizID,
  setAllFlashcardGroups,
  setAllQuizGroups,
  prependFlashcardGroup,
  prependQuizGroup,
  setCurrentFlashcardGroup,
  setCurrentQuizGroup,
  setCurrentFlashcardIndex,
  setCurrentQuizIndex,
  incrementCurrentFlashcardIndex,
  incrementCurrentQuizIndex,
  setSelectedResourceType,
  setStreamingCards,
  appendFlashcardToGroup,
  appendQuizQuestionToGroup,
  changeCurrentFlashcardIndex,
  changeCurrentQuizIndex,
  insertFlashcard,
  insertQuizQuestion
} = chatSlice.actions;

export default store;