import React from 'react';
import { Checkbox, FormControlLabel, Tooltip, IconButton } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useDispatch, useSelector } from 'react-redux';

const MemoryCheckbox = React.memo(({ label, checked, onChange, tooltip }) => {

      //Initialize dispatch
      const dispatch = useDispatch();

      // Get the current theme from the store
      const themeData = useSelector((state) => state.chat.themeData);



  return (
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px'}}>
      <FormControlLabel control={<Checkbox checked={checked} onChange={onChange} style={{ color: themeData.color5}}/>} label={label} style={{marginRight: 'auto'}}/>
      <Tooltip title={tooltip}>
        <IconButton size="small" style={{ color: themeData.color5}}><HelpOutlineIcon /></IconButton>
      </Tooltip>
    </div>
  );
});

export default MemoryCheckbox;
