import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Button, IconButton, Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { Close as CloseIcon } from '@mui/icons-material';
import FlashcardsQuizMeDialog from './flashcardsQuizMeDialog';
import PreviousItemsDialog from './PreviousItemsDialog';
import { setFlashcardsScreenOpen, setQuizMeScreenOpen } from '../../store';
import customLog from '../../customLogger';


const CreateOrLibrary = ({ retrieveQuiz, retrieveFlashcards, open, onClose, type, onFlashcardFileUpload, onQuizFileUpload, createFlashcards, createQuiz }) => {
    const dispatch = useDispatch();
  const themeData = useSelector((state) => state.chat.themeData);

  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [showLibraryDialog, setShowLibraryDialog] = useState(false);

  const handleCreateClick = () => {
    setShowCreateDialog(true);
  };

  const handleLibraryClick = () => {
    setShowLibraryDialog(true);
  };

  const handleCloseDialogs = () => {
    customLog('HANDLECLOSEDIALOGS CALLED');
    if (type === 'flashcards') {
        dispatch(setFlashcardsScreenOpen(false));
    } else if (type === 'quizme') {
        dispatch(setQuizMeScreenOpen(false));
    }
    setShowCreateDialog(false);
    setShowLibraryDialog(false);
    //onClose();  // Closes the main dialog as well
  };

  return (
    <>
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby={`${type}-dialog-title`}
            PaperProps={{
                style: {
                backgroundColor: themeData.color2,
                borderRadius: '20px',
                color: themeData.color5,
                border: '1px solid #000',
                width: '400px',
                },
            }}
        >
            <DialogTitle
                id={`${type}-dialog-title`}
                sx={{
                fontWeight: 'bold',
                textAlign: 'center', // Center the title
                }}
            >
                {type === 'flashcards' ? 'Flashcards' : 'Quizzes'}
                <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: themeData.color5
                }}
                >
                <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '16px', mb: '10px', mt: '10px' }}>
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{
                            backgroundColor: themeData.color3,
                            color: themeData.color4,
                            border: `1px solid ${themeData.color1}`,
                            textTransform: 'none',
                            borderRadius: '20px',
                            '&:hover': {
                                backgroundColor: themeData.color3,
                                color: themeData.color5,
                            },
                        }}
                        onClick={handleCreateClick}
                    >
                        Create
                    </Button>
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{
                            backgroundColor: themeData.color3,
                            color: themeData.color4,
                            border: `1px solid ${themeData.color1}`,
                            textTransform: 'none',
                            borderRadius: '20px',
                            '&:hover': {
                            backgroundColor: themeData.color3,
                            color: themeData.color5,
                            },
                        }}
                        onClick={handleLibraryClick}
                        >
                        Library
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
        {showCreateDialog && (
        <FlashcardsQuizMeDialog
          open={showCreateDialog}
          onClose={handleCloseDialogs}
          type={type}
          onFlashcardFileUpload={onFlashcardFileUpload}
          onQuizFileUpload={onQuizFileUpload}
          createFlashcards={createFlashcards}
          createQuiz={createQuiz}
        />
      )}

      {showLibraryDialog && (
        <PreviousItemsDialog
            open={showLibraryDialog}
            onClose={handleCloseDialogs}
            type={type}
            retrieveFlashcards={retrieveFlashcards}
            retrieveQuiz={retrieveQuiz}
        />
      )}
    </>
  );
};

export default CreateOrLibrary;
