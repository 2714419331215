import React, { useMemo } from 'react';
import customLog from '../../customLogger.js';
import MessageComponent from './messageComponent.js';

// Component for the currently streaming message
const StreamingMessage = React.memo(({ message, username, platoImage, stickman }) => {
    customLog('STREAMINGMESSAGE IS CALLED with message is ' + message.text);
    if (!message.text) {
        return;
    }

    return (
        <MessageComponent 
            message={message}
            username={username}
            platoImage={platoImage}
            stickman={stickman}
        />
    );
});

export default StreamingMessage;

// // Main ChatMessages component
// const ChatMessages = ({ messages, userInput, streamingMessage, username, platoImage, stickman, themeData }) => {
//     const previousMessages = useMemo(() => messages.slice(0, -1), [messages]);

//     return (
//         <List className="chat-area" style={{ backgroundColor: themeData.color4, maxHeight: 'calc(100% - 50px)' }}>
//             <PreviousMessages 
//                 messages={previousMessages}
//                 userInput={userInput}
//                 username={username}
//                 platoImage={platoImage}
//                 stickman={stickman}
//             />
//             {streamingMessage && (
//                 <StreamingMessage 
//                     message={streamingMessage}
//                     username={username}
//                     platoImage={platoImage}
//                     stickman={stickman}
//                 />
//             )}
//         </List>
//     );
// };

// export default React.memo(ChatMessages);