import React, { useMemo } from 'react';
import { List, ListItem, ListItemText, Typography } from '@mui/material';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { BlockMath, InlineMath } from 'react-katex';
import ReactMarkdown from 'react-markdown';
import customLog from '../../customLogger.js';

// Component for rendering a single message
const MessageComponent = React.memo(({ message, username, platoImage, stickman }) => {
    const renderMessageContent = useMemo(() => {
        customLog('MESSAGECOMPONENT IS CALLED WITH MESSAGE ' + message.text);
        const lines = message.text.split('\n');
        const result = [];
        let isInCodeBlock = false;
        let currentLanguage = '';
        let codeBlockContent = '';

        lines.forEach((line, index) => {
            if (line.startsWith('```')) {
                if (isInCodeBlock) {
                    result.push(
                        <SyntaxHighlighter key={index} language={currentLanguage} style={dark}>
                            {codeBlockContent}
                        </SyntaxHighlighter>
                    );
                    isInCodeBlock = false;
                    currentLanguage = '';
                    codeBlockContent = '';
                } else {
                    isInCodeBlock = true;
                    currentLanguage = line.slice(3).trim() || 'text';
                }
            } else if (isInCodeBlock) {
                codeBlockContent += line + '\n';
            } else {
                const parts = line.split(/(\$\$.*?\$\$|\$.*?\$)/g);
                parts.forEach((part, partIndex) => {
                    if (part.startsWith('$$') && part.endsWith('$$')) {
                        result.push(
                            <BlockMath key={`${index}-${partIndex}`}>
                                {part.slice(2, -2)}
                            </BlockMath>
                        );
                    } else if (part.startsWith('$') && part.endsWith('$')) {
                        result.push(
                            <InlineMath key={`${index}-${partIndex}`}>
                                {part.slice(1, -1)}
                            </InlineMath>
                        );
                    } else {
                        result.push(
                            <ReactMarkdown 
                                key={`${index}-${partIndex}`}
                                components={{
                                    p: ({node, ...props}) => <Typography variant="body1" style={{marginBottom: '1em'}} {...props} />,
                                    h1: ({node, ...props}) => <Typography variant="h4" style={{marginTop: '1em', marginBottom: '0.5em'}} {...props} />,
                                    h2: ({node, ...props}) => <Typography variant="h5" style={{marginTop: '1em', marginBottom: '0.5em'}} {...props} />,
                                    h3: ({node, ...props}) => <Typography variant="h6" style={{marginTop: '1em', marginBottom: '0.5em'}} {...props} />,
                                    ul: ({ node, ...props }) => (
                                        <ul style={{ marginBottom: '1em', paddingLeft: '2em', listStyleType: 'disc' }} {...props} />
                                    ),
                                    ol: ({ node, ...props }) => (
                                        <ol type="1" style={{ marginBottom: '1em', listStyleType: 'decimal', listStylePosition: 'inside' }} {...props} />
                                    ),
                                    li: ({ node, ...props }) => (
                                        <li style={{ marginBottom: '0.5em', listStyleType: 'inherit' }} {...props} />
                                    )
                                }}>
                                {part}
                            </ReactMarkdown>
                        );
                    }
                });
            }
        });

        if (isInCodeBlock) {
            result.push(
                <SyntaxHighlighter key={lines.length} language={currentLanguage} style={dark}>
                    {codeBlockContent}
                </SyntaxHighlighter>
            );
        }

        return result;
    }, [message.text]);

    return (
        <ListItem>
            <div style={{ display: 'flex', alignItems: 'flex-start'}}>
                {message.user === 'Assistant' && <img src={platoImage} alt="Plato" className="profile-image" style={{ height: '36px', width: '36px' }} />}
                {message.user === 'User' && <img src={stickman} alt="stickman" className="profile-image" style={{ height: '36px', width: '36px' }}/>}
                <ListItemText
                    primary={
                        <Typography style={{fontWeight: 'bold', fontSize: '0.8em', color: 'black'}}>
                            {message.user === 'Assistant' ? 'Plato' : message.user === 'User' ? username : message.user}
                        </Typography>
                    }
                    secondary={renderMessageContent}
                />
            </div>
        </ListItem>
    );
});

export default React.memo(MessageComponent);