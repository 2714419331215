// ResourcePanel.js
import React, { useState, useRef, useEffect } from 'react';
import { IconButton, CircularProgress, Card, CardContent, Typography, Tooltip } from '@mui/material';
import { Document, Page } from 'react-pdf';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CloseIcon from '@mui/icons-material/Close';
import isEqual from 'lodash/isEqual';
import { changeCurrentQuizIndex, changeCurrentFlashcardIndex, removeSelectedResource, sendMessageToAPI, setFlashcardQuizObject, changeFlashcardQuizIndex } from '../../store.js';
import { useDispatch, useSelector } from 'react-redux';
import customLog from '../../customLogger.js';
//resource,onIframeLoad
const ResourcePanel = ({ resource, extendQuiz, extendFlashcards, quiz, currentFlashcardQuizIndex, currentFlashcardQuiz }) => {// flashcards, quiz
    customLog('RESOURCEPANEL RENDERED with all props of quiz ' + JSON.stringify(quiz) + ' and then currentFlashcardQuizIndex ' + currentFlashcardQuizIndex);
    const dispatch = useDispatch();
    const [numPages, setNumPages] = useState(null);
    const [currentLocation, setCurrentLocation] = useState(0);
    const [showAnswer, setShowAnswer] = useState(false);
    const pdfViewerRef = useRef();
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [isCorrect, setIsCorrect] = useState(null);
    

    // const currentFlashcardQuizIndex = useSelector((state) => state.chat.currentFlashcardQuizIndex);
    const flashcards = useSelector((state) => state.chat.flashcards);
    // const quiz = useSelector((state) => state.chat.quiz);
    const selectedResource = useSelector((state) => state.chat.selectedResource);
    // const currentFlashcardQuizIndex = useSelector((state) => state.chat.currentFlashcardQuizIndex);
    // const currentFlashcardQuiz = useSelector((state) => state.chat.currentFlashcardQuiz);
    // const currentFlashcardGroup = useSelector((state) => state)
    const currentFlashcardGroup = useSelector((state) => state.chat.currentFlashcardGroup);
    const currentFlashcardIndex = useSelector((state) => state.chat.currentFlashcardIndex);
    const selectedResourceType = useSelector((state) => state.chat.selectedResourceType);

    const currentQuizGroup = useSelector((state) => state.chat.currentQuizGroup);
    const currentQuizIndex = useSelector((state) => state.chat.currentQuizIndex);
    // let resource = {};

    const [bullshitUseState, setBullshitUseState] = useState(null);

    useEffect(() => {
        customLog('USEEFFECT CURRENTFLASHCARDGROUP forcing rerender');
        customLog(JSON.stringify('RESOURCE IS ' + resource));
        setBullshitUseState(currentFlashcardGroup);
    }, [currentFlashcardGroup]);

    useEffect(() => {
        customLog('RESOURCE PANEL CURRENTFLASHCARDGROUP IS ' + JSON.stringify(currentFlashcardGroup));
        customLog('RESOURCE PANEL CURRENTFLASHCARDGROUP BOOLEAN IS ' + (currentFlashcardGroup && currentFlashcardGroup.flashcards && currentFlashcardGroup.flashcards.length > 0));
    }, [currentFlashcardGroup]);

    const streamingCards = useSelector((state) => state.chat.streamingCards);

    useEffect(() => {
        customLog('currentFlashcardIndex is ' + currentFlashcardQuizIndex);
        setShowAnswer(false);
        setSelectedAnswer(null);
        setIsCorrect(null); 
        if (flashcards && flashcards.length > 0) {
            customLog('USEEFFECT CURRENTFLASHCARDQUIZINDEX flash length more than 0');
            dispatch(setFlashcardQuizObject(flashcards[currentFlashcardQuizIndex]));
        } else if (quiz && quiz.length > 0) {
            customLog('USEEFFECT CURRENTFLASHCARDQUIZINDEX quiz length more than 0');
            dispatch(setFlashcardQuizObject(quiz[currentFlashcardQuizIndex]));
        }
    }, [currentFlashcardQuizIndex, flashcards, quiz, dispatch ]);


    // if (currentFlashcardGroup && currentFlashcardGroup.flashcards && currentFlashcardGroup.flashcards.length == 1) {
    //     customLog('RESOURCEPANEL rendered with flashcards question ' + flashcards[0].question);
    //     dispatch(setFlashcardQuizObject(flashcards[0]));
    //     // setCurrentFlashcardIndex(0);
    // } else {
    //     customLog('RESOURCEPANEL rendered without flashcards');
    // }

    // if (quiz.length == 1) {
    //     customLog('RESOURCEPANEL rendered with quiz question ' + quiz[0].Q);
    //     dispatch(setFlashcardQuizObject(quiz[0]));
    // } else {
    //     customLog('RESOURCEPANEL rendered without quiz');
    // }

    //Obtain theme data from the store
    const themeData = useSelector((state) => state.chat.themeData);

    // Obtain if SplitPane being dragged from the store
    const iframeDragged = useSelector((state) => state.chat.iframeDragged);

    // reference for iframe
    const iframeRef = useRef(null);

    // const handleAnswer = (answer) => {
    //     customLog(`Answer selected: ${answer}`);
    //     setSelectedAnswer(answer);
    //     setIsCorrect(answer === currentQuizGroup.quiz_questions[currentQuizIndex].Correct);
    // };
    const handleAnswer = (answerIndex) => {
        console.log(`Answer selected: ${answerIndex}`); // Replaced customLog with console.log for simplicity
        setSelectedAnswer(answerIndex);
        
        const currentQuestion = currentQuizGroup.quiz_questions[currentQuizIndex];
        if (currentQuestion && currentQuestion.choices && currentQuestion.choices[answerIndex] !== undefined) {
            const selectedChoice = currentQuestion.choices[answerIndex];
            setIsCorrect(selectedChoice.is_correct); // Assuming each choice has an 'is_correct' property
        } else {
            console.error('Invalid question or choice index');
            setIsCorrect(false);
        }
    };

    const iconButtonStyle = {
        border: '1px solid #000',
        margin: '0 5px',
        color: themeData.color5,
        backgroundColor: themeData.color3,
        '&:hover': {
            backgroundColor: themeData.color4,
        },
        padding: '6px',
        minWidth: '36px', // Set a small fixed width
        width: '36px', // Set a small fixed width
        height: '36px', // Set a small fixed height
    };

    // Style for buttons below flashcard
    // Styles for the buttons
    const flashcardButtonStyle = {
        backgroundColor: themeData.color3,  // Example color
        color: themeData.color5,
        margin: '1px',
        padding: '5px 10px',
        borderRadius: '10px'
    };

    // Updated style for answer buttons container
    const answerButtonsContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        marginTop: '10px' // Add some margin at the top
    };

    const answerButtonStyle = (answer) => ({
        width: '90%',
        height: '75px',
        // backgroundColor: isCorrect && selectedAnswer === answer ? '#00FF00' : !isCorrect && selectedAnswer === answer ? '#FF0000' : themeData.color3,
        backgroundColor: themeData.color3,
        color: themeData.color5,
        margin: '5px',
        borderRadius: '10px',
        padding: '5px',
        textAlign: 'left',
        cursor: 'pointer',
        border: '1px solid',
        borderColor: selectedAnswer === answer 
        ? (isCorrect ? '#00FF00' : '#FF0000') 
        : '#000', // Use theme color if not selected
        overflowY: "auto"
    });

    // Updated style for the flashcard container
    const flashcardContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        backgroundColor: themeData.color2,
        borderRadius: '12px',
        border: '1px solid #000',
        overflow: 'hidden',
        paddingTop: '0px'
    };

    // Updated style for the flashcard
    const flashcardStyle = {
        cursor: 'pointer',
        width: '90%',
        height: '200px',
        backgroundColor: themeData.color3,
        borderRadius: '15px',
        // display: 'flex',
        // justifyContent: 'center',
        // alignItems: 'center',
        marginBottom: '20px',
        border: '1px solid #000',
        marginTop: '20px',
        overflowY: 'auto',
        overflow: 'hidden'
    };

    const cardContentStyle = {
        // height: '100%',
        overflowY: 'auto',
        // display: 'flex',
        // alignItems: 'center',
        // justifyContent: 'center',
    };
    const DjangoHost = process.env.REACT_APP_DJANGO_HOST;

    const navigateFlashcard = (direction) => {
        customLog('NAVIGATEFLASHCARD CALLED WITH DIRECTION ' + direction);
        customLog('NAVIGATEFLASHCARD CALLED WITH CURRENTINDEX ' + currentFlashcardQuizIndex);
        // customLog('NAVIGATEFLASHCARD CALLED WITH CURRENTINDEX ' + currentFlashcardIndex);
        const newIndex = currentFlashcardIndex + direction;
        // customLog('NAVIGATEFLASHCARD CALLED WITH NEWINDEX ' + newIndex);
        // if (newIndex >= 0 && newIndex < flashcards.length || newIndex >= 0 && newIndex < quiz.length) {
        //     dispatch(changeFlashcardQuizIndex(direction));
        //     // change(newIndex);
        // }
        if (
            (currentFlashcardGroup && newIndex >= 0 && newIndex < currentFlashcardGroup.flashcards.length)
          ) {
            dispatch(changeCurrentFlashcardIndex(direction));
            setShowAnswer(false);
          }
    };

    const navigateQuiz = (direction) => {
        const newIndex = currentQuizIndex + direction;
        if (newIndex >= 0 && newIndex < currentQuizGroup.quiz_questions.length) {
            // setCurrentLocation(newIndex);
            // setShowAnswer(false);
            dispatch(changeCurrentQuizIndex(direction));
            setSelectedAnswer(null);
            setIsCorrect(null); 
        }
    };

    // Based on arrow click, updates index of location in resource object
    const navigateLocation = (direction) => {
        customLog(`navigateLocation called with direction ${direction}`);
        customLog(`currentLocation is ${currentLocation}`);
        customLog(`resource locations is ${resource.locations}`);
        
        const newIndex = currentLocation + direction;
        const isValidIndex = newIndex >= 0 && (
            (resource.type === 'flashcard' || resource.type === 'quiz') ? newIndex < resource.content.length : newIndex < resource.locations.length
        );
        setSelectedAnswer(null);

        if (isValidIndex) {
            setCurrentLocation(newIndex);
            setShowAnswer(false);
            if (resource.type === 'file') {
                scrollToPage(resource.locations[newIndex]);
            }
        }
    };

    const scrollToPage = (pageNumber) => {
        if (pdfViewerRef.current) {
            const pageToScrollTo = pdfViewerRef.current.querySelector(`.react-pdf__Page[data-page-number='${pageNumber}']`);
            if (pageToScrollTo) {
                pageToScrollTo.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    };

    const toggleShowAnswer = () => {
        setShowAnswer(!showAnswer);
    };

    return (
        <div className={iframeDragged ? 'disable-pointer-events' : ''} style={{ 
            height: '100%',
            marginRight: '8px' 
             }}>
            {/* <div style={{ textAlign: 'center' }}>
                {resource.type !== 'file' && (
                    <>
                        <Tooltip title="Prev">
                            <IconButton onClick={() => navigateLocation(-1)}>
                                <ArrowLeftIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Next">
                            <IconButton onClick={() => navigateLocation(1)}>
                                <ArrowRightIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                )}
                <Tooltip title="Close">
                    <IconButton onClick={() => dispatch(removeSelectedResource())}>
                        <CloseIcon />
                    </IconButton>
                </Tooltip>
            </div> */}
            <div ref={pdfViewerRef} style={{ height: 'calc(100% - 88px)', overflowY: 'auto', marginTop: '8px', marginBottom: '25px' }}>
                {/* {resource.type === 'file' && (
                    <Document file={resource.url} onLoadSuccess={onDocumentLoadSuccess} loading={<CircularProgress />}>
                        {Array.from(new Array(numPages), (el, index) => (
                            <Page key={`page_${index + 1}`} pageNumber={index + 1} width={375} renderTextLayer={false} renderAnnotationLayer={false} />
                        ))}
                    </Document>
                )} */}
                <div style={flashcardContainerStyle}> {/*style={{flashcardContainerStyle}}style={{ height: '100%', width: '100%', backgroundColor: themeData.color2, borderRadius: '12px', border: '1px solid #000', overflow: 'hidden', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}*/}
                    {resource && resource.type === 'file' && (
                        <iframe
                            src={`${resource.url}#page=${resource.locations[currentLocation]}&view=Fit&cacheBuster=${new Date().getTime()}`}
                            // ref={iframeRef}
                            //style={{ pointerEvents: 'none' }}
                            width="100%"
                            height="100%"
                            frameBorder="0"
                            allowFullScreen
                            borderRadius='12px'
                            // onLoad={() => {
                            //     customLog('onLoad in iFrame called');
                            //     const iframe = document.querySelector('iframe');
                            //     if (iframe) {
                            //         customLog('within if iframe and posting message from resource panel');
                            //         window.parent.postMessage({ type: 'iframeLoaded' }, '*');//iframe.contentWindow
                            //     }
                            // }}
                            >
                        </iframe>
                    )}
                    {resource && resource.type === 'video' && (
                        <div style={flashcardStyle}>
                            <video 
                                src={`${resource.url}#t=${resource.locations[currentLocation]}`}  
                                controls
                                style={{
                                    width: '200px',
                                    height: '300px',
                                    objectFit: 'contain',
                                    borderRadius: 'inherit'
                                }}
                            ></video>
                        </div>
                    )} {/*width="100%" height="50%" style={flashcardStyle} */}
                
                    {selectedResourceType === "flashcards" && currentFlashcardGroup && currentFlashcardGroup.flashcards && currentFlashcardGroup.flashcards.length > 0 && (
                        <>
                        <Card onClick={toggleShowAnswer} style={flashcardStyle} > {/*style={{flashcardStyle}}style={{ cursor: 'pointer', margin: '10px', width: '90%', height: '200px', backgroundColor: themeData.color3, borderRadius: '15px', justifyContent: 'center', alignContent: 'center', display: 'flex' }}*/}
                            <CardContent style={cardContentStyle}>
                                <Typography  variant="h5" component="div" style={{ color: themeData.color5, height: '150px' }}> {/* style={cardContentStyle}*/}
                                    {showAnswer ? currentFlashcardGroup.flashcards[currentFlashcardIndex].answer : currentFlashcardGroup.flashcards[currentFlashcardIndex].question}
                                </Typography>
                            </CardContent>
                        </Card>
                         <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '-10px' }}>
                            <Tooltip title="Previous">
                                <span>
                                    <IconButton 
                                        onClick={() => navigateFlashcard(-1)} 
                                        disabled={currentFlashcardIndex === 0}
                                        style={iconButtonStyle}
                                        size="small"
                                    >
                                        <ArrowLeftIcon fontSize="small" />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            {currentFlashcardIndex === currentFlashcardGroup.flashcards.length - 1 && !streamingCards ? (
                                <Tooltip title="Generate 10 more cards">
                                    <span>
                                        <IconButton 
                                            onClick={() => extendFlashcards({flashcardGroupID: currentFlashcardGroup.id})}  // Use the extendFlashcards prop to generate more cards
                                            style={iconButtonStyle}
                                            size="small"
                                        >
                                            <span style={{ fontSize: '1.5em' }}>+</span> 
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            ) : (
                                <Tooltip title="Next">
                                    <span>
                                        <IconButton 
                                            onClick={() => navigateFlashcard(1)} 
                                            disabled={currentFlashcardIndex === currentFlashcardGroup.flashcards.length - 1}
                                            style={iconButtonStyle}
                                            size="small"
                                        >
                                            <ArrowRightIcon fontSize="small" />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            )}
                        </div> 
                    </>
                    )} 
                {selectedResourceType === "quiz" && currentQuizGroup && currentQuizGroup.quiz_questions && currentQuizGroup.quiz_questions.length > 0 && (
                    <>
                        <Card style={flashcardStyle}> {/*style={{ border: '1px solid #000', cursor: 'pointer', margin: '10px', width: '90%', height: '200px', backgroundColor: themeData.color3, borderRadius: '15px' }} */}
                            <CardContent style={cardContentStyle}>
                                <Typography variant="h5" component="div" style={{ color: themeData.color5, height: '150px' }}>
                                    {currentQuizGroup.quiz_questions[currentQuizIndex].question}
                                </Typography>
                            </CardContent>
                        </Card>
                        <div style={answerButtonsContainerStyle}>
                            {[0, 1, 2, 3].map(option => (
                                <button
                                    key={option}
                                    style={answerButtonStyle(option)}
                                    onClick={() => handleAnswer(option)}
                                >
                                    {String.fromCharCode(65 + option)}: {currentQuizGroup.quiz_questions[currentQuizIndex].choices[option].body}
                                </button>
                            ))}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '0px' }}>
                        <Tooltip title="Previous">
                            <span>
                                <IconButton 
                                    onClick={() => navigateQuiz(-1)} 
                                    disabled={currentQuizIndex === 0}
                                    style={iconButtonStyle}
                                    size="small"
                                >
                                    <ArrowLeftIcon fontSize="small" />
                                </IconButton>
                            </span>
                        </Tooltip>
                        {currentQuizIndex === currentQuizGroup.quiz_questions.length - 1 && !streamingCards ? (
                            <Tooltip title="Generate 10 more questions">
                                <span>
                                    <IconButton 
                                        onClick={() => extendQuiz({quizGroupID: currentQuizGroup.id})}  // Use the extendQuiz prop to generate more questions
                                        style={iconButtonStyle}
                                        size="small"
                                    >
                                        <span style={{ fontSize: '1.5em' }}>+</span>  {/* Display a plus sign */}
                                    </IconButton>
                                </span>
                            </Tooltip>
                        ) : (
                            <Tooltip title="Next">
                                <span>
                                    <IconButton 
                                        onClick={() => navigateQuiz(1)} 
                                        disabled={currentQuizIndex === currentQuizGroup.quiz_questions.length - 1}
                                        style={iconButtonStyle}
                                        size="small"
                                    >
                                        <ArrowRightIcon fontSize="small" />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        )}
                    </div>
                    </>
                )}
                {resource && resource.type === 'link' && (
                    <iframe
                        src={resource.url}
                        style={{
                            width: '100%',       // Adjust width to fill the container 
                            height: '100%',      // Adjust height as neededstate.selectedResource = {id: 'temp', type: 'link', title: 'urlTest', url: "https://react.dev/blog/2023/03/16/introducing-react-dev"};
                            border: 'none'       // Optional: Remove border for cleaner integration
                        }}
                        frameBorder="0"
                        allowFullScreen
                    ></iframe>
                )}
                </div>
            </div>
        </div>
    );
};
// export default React.memo(ResourcePanel, (prevProps, nextProps) => isEqual(prevProps.resource, nextProps.resource) && isEqual(prevProps.flashcards, nextProps.flashcards) && isEqual(prevProps.quiz, nextProps.quiz) && prevProps.currentFlashcardIndex === nextProps.currentFlashcardIndex && isEqual(prevProps.currentFlashcardQuiz, nextProps.currentFlashcardQuiz));
export default ResourcePanel;