import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Table, TableBody, TableCell, TableHead, TableRow, IconButton, TableSortLabel, Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { Close as CloseIcon } from '@mui/icons-material';
import { setCurrentQuizGroup, setSelectedResourceType, setCurrentFlashcardIndex, setCurrentFlashcardGroup, setCurrentFlashcardGroupID, setCurrentQuizID, getQuizLibrary, setFlashcards, setQuiz, setSelectedResource, getFlashcardsLibrary } from '../../store';
import customLog from '../../customLogger';

const PreviousItemsDialog = ({ open, onClose, type, retrieveFlashcards, retrieveQuiz }) => {
  const themeData = useSelector((state) => state.chat.themeData);
  const dispatch = useDispatch();

    // Get flashcardGroups and quizzes from Redux store
    const flashcardGroups = useSelector((state) => state.chat.allFlashcardGroups);
    const quizzes = useSelector((state) => state.chat.allQuizGroups);

//   // Dummy data, replace with actual data fetched from your API
//   const previousItems = type === 'flashcards' 
//     ? [
//         { name: 'Mitochondria', createdFrom: 'File: lecNotes_09_08', lastUsed: '2023-09-01' },
//         { name: 'JSON', createdFrom: 'Prompt: JSON', lastUsed: '2023-09-05' }
//       ]
//     : [
//         { name: 'React', createdFrom: 'Prompt', lastUsed: '2023-08-25' },
//         { name: 'Vue', createdFrom: 'File', lastUsed: '2023-08-30' }
//       ];
    let previousItems = []; 

    if (type === 'flashcards') {
        previousItems = flashcardGroups.map((group) => ({
        name: group.name,
        createdFrom: 'Unknown', // Replace with actual data if available
        lastUsed: 'N/A',        // Replace with actual data if available
        id: group.id,
        flashcards: group.flashcards,
        }));
    } else {
        previousItems = quizzes.map((quiz) => ({
        name: quiz.name,
        createdFrom: 'Unknown', // Replace with actual data if available
        lastUsed: 'N/A',        // Replace with actual data if available
        id: quiz.id,
        quiz_questions: quiz.quiz_questions,
        }));
    }



  // Sorting logic
  const [orderDirection, setOrderDirection] = useState('asc');
  const [orderBy, setOrderBy] = useState('name'); // Default sorting by name

  const handleSort = (column) => {
    const isAsc = orderBy === column && orderDirection === 'asc';
    setOrderDirection(isAsc ? 'desc' : 'asc');
    setOrderBy(column);
  };

  const sortedItems = [...previousItems].sort((a, b) => {
    if (orderBy === 'name' || orderBy === 'createdFrom') {
      return orderDirection === 'asc'
        ? a[orderBy].localeCompare(b[orderBy])
        : b[orderBy].localeCompare(a[orderBy]);
    } else if (orderBy === 'lastUsed') {
      return orderDirection === 'asc'
        ? new Date(a.lastUsed) - new Date(b.lastUsed)
        : new Date(b.lastUsed) - new Date(a.lastUsed);
    }
    return 0;
  });

  const handleRowClick = (item) => {
    // dispatch(setFlashcards([]));
    // dispatch(setQuiz([]));
    dispatch(setCurrentQuizGroup(null));

    dispatch(setCurrentFlashcardGroup(null));
    dispatch(setCurrentFlashcardIndex(0));
    customLog('HANDLEROWCLICK called');
    if (type === 'flashcards') {
        customLog('HANDLEROWCLICK flashcards with item of ' + JSON.stringify(item));
        // dispatch(setFlashcards(item));
        // dispatch(getFlashcardsLibrary(item));
        // dispatch(setSelectedResourceType('flashcards'));
        // dispatch(setCurrentFlashcardGroupID(item.id))
        // dispatch(setCurrentFlashcardGroup(item));
        // dispatch(setSelectedResource({id: 'flashcard', type: 'flashcards', title: 'flashy', url: 'na'}));
        retrieveFlashcards({flashcardGroup: item});
      } else {
      // Format quiz questions
      // const formattedQuiz = item.quiz_questions.map((question) => ({
      //   Q: question.question,
      //   A: question.choices[0].body,
      //   B: question.choices[1].body,
      //   C: question.choices[2].body,
      //   D: question.choices[3].body,
      //   Correct: String.fromCharCode(65 + question.choices.findIndex(choice => choice.is_correct)),
      //   id: question.id,
      // }));
      // dispatch(getQuizLibrary(formattedQuiz));
      // dispatch(setSelectedResource({ type: 'quiz', id: item.id, title: item.name, content: formattedQuiz }));
      customLog('HANDLEROWCLICK PREVITEMS with quiz ' + JSON.stringify(item));
      retrieveQuiz({quizGroup: item});
    }
    customLog('HANDLEROWCLICK right before onClose()');
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          backgroundColor: themeData.color2,
          borderRadius: '20px',
          color: themeData.color5,
          border: '1px solid #000',
          width: '500px',
          maxHeight: '70vh', // Adjust the max height to make it scrollable
        },
      }}
    >
      <DialogTitle
        sx={{
          fontWeight: 'bold',
          textAlign: 'center',
        }}
      >
        {type === 'flashcards' ? 'Previous Flashcards' : 'Previous Quizzes'}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: themeData.color5,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Box sx={{ maxHeight: '50vh', overflowY: 'auto' }}> {/* Scrollable table box */}
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell 
                    sx={{
                        backgroundColor: themeData.color3, // Use a contrasting background color
                        color: themeData.color5, // Text color
                        fontWeight: 'bold', // Bold text
                        fontSize: '1rem', // Increase font size
                        padding: '12px', // Padding for better spacing
                        borderTopLeftRadius: '10px', // Rounded corners for the top left
                        //borderTopRightRadius: '10px', // Rounded corners for the top right
                    }}>
                  <TableSortLabel
                    active={orderBy === 'name'}
                    direction={orderBy === 'name' ? orderDirection : 'asc'}
                    onClick={() => handleSort('name')}
                    sx={{
                        '& .MuiTableSortLabel-icon': {
                          color: themeData.color5 + ' !important', // Color for the sort icon
                        },
                      }}
                  >
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell 
                    sx={{
                    backgroundColor: themeData.color3,
                    color: themeData.color5,
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    padding: '12px',
                    }}>
                  <TableSortLabel
                    active={orderBy === 'createdFrom'}
                    direction={orderBy === 'createdFrom' ? orderDirection : 'asc'}
                    onClick={() => handleSort('createdFrom')}
                    sx={{
                        '& .MuiTableSortLabel-icon': {
                          color: themeData.color5 + ' !important',
                        },
                      }}
                  >
                    Created From
                  </TableSortLabel>
                </TableCell>
                <TableCell 
                    sx={{
                    backgroundColor: themeData.color3,
                    color: themeData.color5,
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    padding: '12px',
                    borderTopRightRadius: '10px',
                    }}
                >
                  <TableSortLabel
                    active={orderBy === 'lastUsed'}
                    direction={orderBy === 'lastUsed' ? orderDirection : 'asc'}
                    onClick={() => handleSort('lastUsed')}
                    sx={{
                        '& .MuiTableSortLabel-icon': {
                          color: themeData.color5 + ' !important',
                        },
                      }}
                  >
                    Last Used
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {sortedItems.map((item, index) => (
                <TableRow 
                    key={index}
                    onClick={() => handleRowClick(item)}
                    style={{ cursor: 'pointer'
                    }}>
                  <TableCell style={{ color: themeData.color5 }}>{item.name}</TableCell>
                  <TableCell style={{ color: themeData.color5 }}>{item.createdFrom}</TableCell>
                  <TableCell style={{ color: themeData.color5 }}>{item.lastUsed}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PreviousItemsDialog;
