import { Drawer, Typography, IconButton, Tooltip } from "@mui/material";
// import platoImage from '../../../images/platoRealLogo.png';
import platoImage from '../../../images/plato.png';
import platoCartoon from '../../../images/newPlatoNoBG.png'
import './TopLeftDrawer.css'
import { useSelector, useDispatch } from "react-redux";
import MenuIcon from '@mui/icons-material/Menu'; // Example icon for the button
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import customLog from '../../../customLogger.js';
import { setQuiz, setFlashcards, setDrawerOpen, handleNewChat, uploadLongtermFileAPI, setAbortion, setLoginSignupScreenOpen, setGeneralSnackbarMessage, setGeneralSnackbarOpen } from '../../../store.js';
import {useEffect, useRef} from 'react';
import AddIcon from '@mui/icons-material/Add';

const TopLeftDrawer = () => {
    // Initialize dispatch
    const dispatch = useDispatch();

    const LONGTERM_MAX_FILE_SIZE = 50 * 1024 * 1024;

    // State for left drawer
    const drawerOpen = useSelector((state) => state.chat.drawerOpen);

    // Selector for getting theme data
    const themeData = useSelector((state) => state.chat.themeData);

    // File input ref
    const fileInputRef = useRef(null);

    // State for login Signup dialog
    const loginSignupScreenOpen = useSelector((state) => state.chat.loginSignupScreenOpen);
    const isLoggedIn = useSelector((state) => state.chat.isLoggedIn);

    // Function to update CSS variable
    const updateDrawerWidth = (isOpen) => {
        document.documentElement.style.setProperty('--drawer-width', isOpen ? '240px' : '0px');
    };

    // Effect to update drawer width when drawerOpen state changes
    useEffect(() => {
        updateDrawerWidth(drawerOpen);
    }, [drawerOpen]);

    // Function to toggle the drawer
    const toggleDrawer = () => {
        customLog('toggleDrawer called with drawerOpen of ' + drawerOpen);
        customLog('!drawerOpen is ' + !drawerOpen);
        dispatch(setDrawerOpen(!drawerOpen));
    };

    // Function to handle file selection
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            // Dispatch the API call with the selected file
            if (file.size > LONGTERM_MAX_FILE_SIZE) {
                dispatch(setGeneralSnackbarMessage("File size exceeds 50MB limit"));
                dispatch(setGeneralSnackbarOpen(true));
                return;
            }
            dispatch(uploadLongtermFileAPI(file));
        }
    };

    // Function to trigger file input click
    const handlePlatoClick = () => {
        if (!isLoggedIn) {
            dispatch(setLoginSignupScreenOpen({loginSignupScreenOpen:true, loginSignupTab: 1}));
        } else {
            if (fileInputRef.current) {
                fileInputRef.current.click();
            }
        }

    };

    return (
        <Drawer
        open={true}
        variant="persistent"
            PaperProps = {{
                className: 'topDrawer',
                style: { backgroundColor: themeData.color2 }
            }}
        >
        {/* <div> */}
            <div className="drawer-content" style={{color: themeData.color5, display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0 10px'}}>
                {/* <Tooltip title={drawerOpen ? "Close Drawer" : "Open Drawer"}>
                    <IconButton
                        onClick={toggleDrawer}
                        style={{ color: themeData.color5}}
                        className={drawerOpen ? 'reset-arrow' : 'rotate-arrow'}
                    >
                        <KeyboardBackspaceIcon /> 
                    </IconButton>
                </Tooltip> */}
                <Tooltip title={drawerOpen ? "Close Drawer" : "Open Drawer"}>
                    <IconButton
                        onClick={toggleDrawer}
                        style={{
                            backgroundColor: themeData.color3,
                            color: themeData.color5,
                            borderRadius: '50%',
                            padding: '10px',
                            border: '1px solid #000'
                        }}
                        className={drawerOpen ? 'reset-arrow' : 'rotate-arrow'}
                    >
                        <KeyboardBackspaceIcon />
                    </IconButton>
                </Tooltip>

                {/* <img src={platoImage} alt="logo" className="drawer-icon"/> */}
                {/* <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1, justifyContent: 'center' }}>
                <img src={platoImage} alt="logo" className="drawer-icon"/> */}
                <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1, justifyContent: 'center' }}>
                    <Tooltip title="Upload to Plato's memory. He will reference uploaded content in future responses">
                        <IconButton
                            style={{
                                backgroundColor: themeData.color3,
                                borderRadius: '50%',
                                padding: '5px', // Keep a small padding to maintain button size
                                border: '1px solid #000',
                                display: 'flex', // Add flexbox
                                justifyContent: 'center', // Center content horizontally
                                alignItems: 'center', // Center content vertically
                            }}
                            onClick={handlePlatoClick}
                        >
                            <img src={platoImage} alt="logo" className="drawer-icon" style={{
                                          height: '100%', // Use a percentage height
                                          width: '100%',  // Use a percentage width
                                          maxHeight: '35px', // Limit max height
                                          maxWidth: '35px',  // Limit max width
                                          objectFit: 'contain', // Ensure the image scales correctly 
                             }} />
                        </IconButton>
                    </Tooltip>
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleFileChange} // Handles the file selection
                    />
                </div>
                    {/* <Typography variant="h6" noWrap >
                        Plato
                    </Typography> */}
                {/* </div> */}
                 {/* <div> style={{ position: 'absolute', top: '6px', left: '5px', right: '5px', width: '48px', display: 'flex', alignItems: 'center', backgroundColor: themeData.color3, justifyContent: 'space-between', padding: '5px', borderRadius: '30px' }} */}
                                {/* <Button className="button" style={{ color:themeData.color5, height: '30px'}} onClick={handleCourseClick}>
                                    {selectedCourseTitle || 'Courses'} <ArrayDropDownIcon />
                                </Button> */}
                {/* <Tooltip title="Create New Chat">
                    <IconButton onClick={() => dispatch(handleNewChat())} style={{ color:themeData.color5, height: '30px' }}>
                            <AddIcon />
                    </IconButton>
                </Tooltip> */}
                <Tooltip title="Create New Chat">
                    <IconButton
                        onClick={() => {
                            dispatch(handleNewChat());
                            dispatch(setAbortion(true));
                            dispatch(setQuiz([]));
                            dispatch(setFlashcards([]));
                        }}
                        style={{
                            backgroundColor: themeData.color3,
                            color: themeData.color5,
                            borderRadius: '50%',
                            padding: '10px',
                            border: '1px solid #000'
                        }}
                    >
                        <AddIcon />
                    </IconButton>
                </Tooltip>
                        {/* </div> */}
                {/* <Typography variant="h6" noWrap className="drawer-title">
                    Plato
                </Typography> */}
            </div>
        </Drawer>
    );
    }
export default TopLeftDrawer;