// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Chat from './chat.js';
import Home from "./Home.js";
import Signup from "./components/signup/Signup";
import Login from "./components/login/Login.js";
// import Teacher from './teacher.js';
import { Provider, useSelector, useDispatch } from 'react-redux';
import {store, getInitialUserData} from './store.js';
import customLog from './customLogger.js';
import ProtectedRoute from './ProtectedRoute.js';
import { CookiesProvider } from 'react-cookie';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy.js';
import TermsOfUse from './components/TermsOfUse/TermsOfUse.js';
import useIsMobile from './useIsMobile.js';
// import { Elements } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';
//           <img src={logo} className="App-logo" alt="logo" />

// const stripePromise = loadStripe('your_stripe_public_key_here');
// function App() {

//   const dispatch = useDispatch();

//   // selected theme
//   const themeData = useSelector((state) => state.chat.themeData);
//   // const [user, setUser] = useState(null);

//   // const handleLoginSuccess = (userProfile) => {
//   //   console.log('User logged in:', userProfile);

//   //   setUser(userProfile);
//   // };

//   // const handleLoginFailure = (error) => {
//   //   console.error('Login failed:', error);

//   // };
//   // customLog('REACT_APP_DJANGOHOST:', process.env.REACT_APP_DJANGOHOST);

//   return (
//     <Provider store={store}>
//       <Router>
//         {/* <Elements stripe={stripePromise}> */}
//           <div className="App" style={{ backgroundColor: themeData.color4}}>
//             <header className="App-header">
//               <Routes>
//               <Route path="/" element={<Home />} />
//               <Route path="/signup" element={<Signup />} />
//               <Route path="/login" element={<Login />} />
//                 <Route 
//                   path="/chat" 
//                   element={
//                     <ProtectedRoute>
//                       <Chat />
//                     </ProtectedRoute>
//                   } 
//                 />
//                 {/* <Route path="/teacher" element={<Teacher />} /> */}
//               </Routes>
//             </header>
//           </div>
//         {/* </Elements> */}
//       </Router>
//     </Provider>
//   );
// }

function App() {
  return (
    <Provider store={store}>
      <CookiesProvider>
        <Router>
            <InnerApp /> {/* New Component that uses useDispatch and useSelector */}
        </Router>
      </CookiesProvider>
    </Provider>
  );
}

function InnerApp() {
  customLog('InnerApp rendered');
  const dispatch = useDispatch();  // Now correctly within the Provider context
  const themeData = useSelector((state) => state.chat.themeData);
  const isLoggedIn = useSelector((state) => state.chat.isLoggedIn);
  const isMobile = useIsMobile();

  const getCookie = (name) => {
    const cookies = document.cookie.split('; ').reduce((acc, cookie) => {
      const [key, value] = cookie.split('=');
      acc[key] = value;
      return acc;
    }, {});
    return cookies[name];
  };


  useEffect(() => {
    customLog('APP.JS USEEFFECT for cookies');
    const jwtToken = getCookie('jwt');
    
    if (jwtToken) {
      // If JWT token is present in cookies, validate it and fetch initial data
      dispatch(getInitialUserData())
        .unwrap()
        .catch(() => {
          // If token validation fails, open login/signup dialog
          // dispatch(setLoginSignupScreenOpen(true));
        });
    } else {
      // No token found, require login
      // dispatch(setLoginSignupScreenOpen(true));
    }
  }, [dispatch]);

  if (isMobile) {
    // Render the mobile-specific message if the screen size is mobile
    return (
      <div className="App" style={{ backgroundColor: '#CFD7C7', textAlign: 'center', padding: '20px' }}>
        <h2>Mobile support coming soon! Check us out on a larger device!</h2>
      </div>
    );
  }
  

  return (
    <div className="App" style={{backgroundColor: '#CFD7C7'}}>
      <header className="App-header">
        <Routes>
          {/* <Route path="/" element={<Home />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} /> */}
          <Route path="*" element={<Navigate to="/chat" replace/>} />
          <Route 
            path="/chat" 
            element={
                <Chat />
            } 
          />
          <Route path="/legal/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/legal/terms-of-use" element={<TermsOfUse />} />
        </Routes>
      </header>
    </div>
  );
}

export default App;
