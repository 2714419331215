import React, { useMemo } from 'react';
import customLog from '../../customLogger.js';
import MessageComponent from './messageComponent.js';

// Component for previous messages
const PreviousMessages = ({ messages, username, platoImage, stickman }) => {//React.memo(
    customLog('PREVIOUSMESSAGES IS CALLED WITH messages of ' + JSON.stringify(messages));

    return (
        <>
            {messages.map((message, index) => (
                <MessageComponent 
                    key={index}
                    message={message}
                    username={username}
                    platoImage={platoImage}
                    stickman={stickman}
                />
            ))}
        </>
    );//, [messages, username, platoImage, stickman]);
};

export default React.memo(PreviousMessages);