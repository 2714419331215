export const themes = {
    default: {
        color1: '#0B2027', //screen text, black
        color2: '#40798C', //drawer
        color3: '#70A9A1', //buttons
        color4: '#CFD7C7', //background
        color5: '#F6F1D1', //text on buttons
    },
    stevo: {
        color1: '#0B2027',
        color2: '#40798C',
        color3: '#70A9A1',
        color4: '#F2AE9F',
        color5: '#F6F1D1',
    }
}