import React, { useRef, useState, useEffect } from 'react';
import { Drawer, Divider, TextField, Button, List, ListItem, ListItemText,
        AppBar, Toolbar, IconButton, Menu, MenuItem, Typography, Box, Tooltip, FormControlLabel, Checkbox, Snackbar } from '@mui/material';
import ArrayDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useNavigate } from 'react-router-dom';
import Popover from '@mui/material/Popover';
import platoImage from '../../../images/newPlatoNoBG.png';
import stickman from '../../../images/stickman-nobg.png';
import CircularProgress from '@mui/material/CircularProgress';
import io from 'socket.io-client';
import { Provider, useSelector, useDispatch } from 'react-redux';
import {createStore, applyMiddleware} from 'redux';
import configureStore from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { setLoginSignupScreenOpen, setDrawerOpen, deleteCourseAPI, updateCourseAPI, setGeneralSnackbarMessage, setGeneralSnackbarOpen, 
    createCourseAPI, setSelectedResource, removeChat, editChatTitle, setConversationID, setAbortion,
    setEditedChatTitleRedux, sendChatMessage, createConversation, setChatTitle, setMessages, handleLoadChatRedux, 
    createCourse, editCourseName, removeCourse, setSelectedCourse, loadMostRecentChat, setFlashcardsScreenOpen } from '../../../store.js';
import RemoveIcon from '@mui/icons-material/RemoveCircle';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Prism from 'prismjs';
import hljs from 'highlight.js';
import 'highlight.js/styles/default.css';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';
import MicIcon from '@mui/icons-material/Mic';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import ShareIcon from '@mui/icons-material/IosShare';
import SettingsIcon from '@mui/icons-material/Settings';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {removeChatAPI, editChatTitleAPI, handleNewChat, setCoursePopover, setSystemPrompt, setSettingsOpen, setQuizMeScreenOpen} from '../../../store.js';
import './BottomLeftDrawer.css';
import { createSelector } from 'reselect';
import customLog from '../../../customLogger.js';
import LoginSignupDialogComponent from '../../LoginSignupDialog/LoginSignupDialogComponent.js';
// import { Elements } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';




const BottomLeftDrawer = () => {
    // Initialize stripe promise
    // const stripePromise = loadStripe('TODO');

    // Initialize dispatch
    const dispatch = useDispatch();

    // State for left drawer
    const drawerOpen = useSelector((state) => state.chat.drawerOpen);

    // User info for firstName
    const firstName = useSelector((state) => state.chat.firstName); 

    // User info for username
    const username = useSelector((state) => state.chat.username);

    /* COURSES */
    // State for course popover
    const coursePopover = useSelector((state) => state.chat.coursePopover);

    // State for the courses of the student
    const studentCourses = useSelector((state) => state.chat.courses);

    // State for the courseID of the selected course
    const selectedCourseId = useSelector((state) => state.chat.selectedCourse);

    const isLoggedIn = useSelector((state) => state.chat.isLoggedIn);

    // State for the title of the selected course TODO: Bug here for displaying course name
    const selectedCourseTitle = studentCourses[selectedCourseId];

    // State for name of new course being created
    const [newCourseName, setNewCourseName] = useState("");

    // State for index of course name being edited
    const [editingCourseIndex, setEditingCourseIndex] = useState(null);

    // State for new name of course being edited
    const [editedCourseName, setEditedCourseName] = useState("");

    // Variable for if course is being created
    const creatingCourse = useSelector((state) => state.chat.creatingCourse);

    // Ref for the course button
    const courseButtonRef = useRef(null);

    // Variable for most recent 5 chat titles of current course
    const mostRecentChats = useSelector((state) => state.chat.currentOrderedChatIDs);

    // Selector for getting theme data
    const themeData = useSelector((state) => state.chat.themeData);

    // Function for handling course popover close
    const handleCourseClose = () => {
        // console.log("handleCourseClose called");
        dispatch(setCoursePopover(null));
        setNewCourseName("");
        setEditingCourseIndex(null);
        // document.body.focus();
    };

    // Function for handling course click to enable popover
    const handleCourseClick = (event) => {
        dispatch(setCoursePopover(event.currentTarget)); //specifies parent that was clicked so that popover is properly anchored to it
    };

    // Function to handle course name change
    const handleCourseNameChange = (event) => {
        setEditedCourseName(event.target.value);
    };

    // Function to handle course name change submission
    const handleCourseNameUpdate = (event, index) => {
        if(event.key === 'Enter') {
            // dispatch(editCourseName({courseName: editedCourseName, courseId: index}));
            // console.log('enter pressed on course name update');
            dispatch(updateCourseAPI({courseId: index, courseName: editedCourseName}));
            setEditingCourseIndex(null);
        }
    };

    // Function to handle edit button click
    const handleEditCourseClick = (index) => {
        setEditingCourseIndex(index);
        setEditedCourseName(studentCourses[index]);
    };

    // Function to handle removing a course
    const handleRemoveCourse = (courseId) => {
        dispatch(deleteCourseAPI({courseId, courseName: studentCourses[courseId]}));
        //if currently selected course is being removed
        if (selectedCourseId === courseId) {
            dispatch(setSelectedCourse(null));
            dispatch(handleNewChat());
        }
        // dispatch(removeCourse({courseId: index}));
    };

    // Function to handle new course submission
    const handleAddCourse = (event) => {
        event.preventDefault();
        handleCourseClose();
        if (creatingCourse) {
            dispatch(setGeneralSnackbarMessage("Please wait, course is being created"));
            dispatch(setGeneralSnackbarOpen(true));
            
            
        } else {
            dispatch(createCourseAPI(newCourseName));
            dispatch(handleNewChat());
            setNewCourseName("");
        }
        

    };

    // Function to handle selecting a new course
    const handleCourseSelect = (courseId) => {//TODO: Weird bug where sometimes it doesn't change course on first click
        // console.log("handleCourseSelect called");
        dispatch(setSelectedCourse(courseId));
        // dispatch(loadMostRecentChat(courseId));
        dispatch(handleNewChat());
    };

    /* CHATS */
    // State for ID of chat title being edited
    const [editingChatID, setEditingChatID] = useState(null);

    // State for new chat title being edited
    const [editedChatTitle, setEditedChatTitle] = useState("");

    // Obtain specific course's chats
    // Using reselect to memoize the courseConvoIDsToChatTitle to avoid unnecessary rerenders
    const selectChatsBase = state => state.chat.IDToChatTitle[state.chat.selectedCourse] || {};
    const selectChats = createSelector(
        [selectChatsBase],
        chats => chats
    );
    let courseConvoIDsToChatTitle = useSelector(selectChats);

    // Handle loading a chat
    const handleLoadChat = (chatTitle) => {
        // const currentCourse = studentCourses.find(course => course.title === chatTitle);
        dispatch(handleLoadChatRedux(chatTitle)); //TODO: Send currentCourse? probs not, selectedCourse on redux side
        dispatch(setAbortion(true));
    };

    // Function to handle edit chat title click
    const handleEditChatTitleClick = (conversationID, chatTitle) => {
        // console.log('handleEditChatTitleClick called with conversationID: ', conversationID, ' and chatTitle: ', chatTitle);
        setEditingChatID(conversationID); //TODO: Change this to index
        setEditedChatTitle(chatTitle);
    };

    // Function to submit new chat title
    const handleChatTitleUpdate = (event, conversationID) => {
        if (event.key === 'Enter') {
            // console.log('about to dispatch editChatTitleAPI with conversationID: ', conversationID, ' and newTitle: ', event.target.value);
            dispatch(editChatTitleAPI({conversationID, newTitle: event.target.value}));
            setEditingChatID(null);
        };
    };

    // Function to handle upgrade now click
    const handleUpgradeNowClick = async () => {
        try {
            const response = await fetch('/create-checkout-session', {method: 'POST'});
            const session = await response.json();

            // Redirect to Stripe Checkout using session URL
            window.location.href = session.url;
        } catch (error) {
            // console.log('Error redirecting to Stripe Checkout: ', error);
        }
    };

    // Function to handle create flashcards click
    const handleCreateFlashcards = async () => {
        // clearTimeout(window.snackbarTimer);
        // dispatch(setGeneralSnackbarMessage("Flashcards coming soon!"));
        // dispatch(setGeneralSnackbarOpen(true));

        // window.snackbarTimer = setTimeout(() => {
        //     dispatch(setGeneralSnackbarOpen(false));
        // }, 3000);
        // TODO: Make snackbar close after 3 seconds
        // dispatch(setSelectedResource({id: 'temp', type: 'link', title: 'urlTest', url: "https://www.nytimes.com/games/wordle/index.html"}));
        if (!isLoggedIn) {
            dispatch(setLoginSignupScreenOpen({loginSignupScreenOpen: true, loginSignupTab: 1}));
        } else {
            dispatch(setFlashcardsScreenOpen(true));
        }
    };



    // Function to handle quiz me click
    const handleQuizMe = async () => {
        // clearTimeout(window.snackbarTimer);
        // dispatch(setGeneralSnackbarMessage("Quizzes coming soon!"));
        // dispatch(setGeneralSnackbarOpen(true));

        // window.snackbarTimer = setTimeout(() => {
        //     dispatch(setGeneralSnackbarOpen(false));
        // }, 3000);
        // TODO: Make snackbar close after 3 seconds
        if (!isLoggedIn) {
            dispatch(setLoginSignupScreenOpen({loginSignupScreenOpen: true, loginSignupTab: 1}));
        } else {
            dispatch(setQuizMeScreenOpen(true));
        } 
    }

    // Function to handle removing chats
    const handleRemoveChat = (conversationID) => {
        dispatch(removeChatAPI({conversationID}));
    };

    /* SETTINGS */
    // State for settings popup
    const settingsOpen = useSelector((state) => state.chat.settingsOpen);

    // Function to handle settings popup open
    const handleSetSettingsOpen = () => {
        if (!isLoggedIn) {
            dispatch(setLoginSignupScreenOpen({loginSignupScreenOpen: true, loginSignupTab: 1}));
        } else {
            dispatch(setSettingsOpen(true));
        }
    };

    /* EXTRA OPTIONS */
    // State for socratic method
    const socraticMethod = useSelector((state) => state.chat.socraticMethod);

    const handleSocraticMethodChange = (event) => {
        if (event.target.checked) {
            customLog('HINTS ONLY MODE ON');
            dispatch(setSystemPrompt(("socratic")));
        } else {
            customLog('HINTS ONLY MODE OFF');
            dispatch(setSystemPrompt(("normal")));
        }
        
    };


    return (
        <Drawer // For chat panel on left side
                variant="persistent" //does not close automatically
                anchor="left"
                open={drawerOpen}
                // classes="drawer"
                PaperProps = {{
                    className: 'drawer',
                    style: { backgroundColor: themeData.color2 } 
                  }} //#333style: { width: drawerWidth, backgroundColor: themeData.color2}
                //onClose={() => setDrawerOpen(false)} NOTE: Used to have code below to make create new chat button not appear when drawer is on UI
            >
            {/* <div className="topBar" style={{ backgroundColor: themeData.color3, display: 'flex', alignItems: 'center'}}>
                <Button className="button" style={{ color: themeData.color5}} onClick={handleCourseClick} ref={courseButtonRef}>
                {selectedCourseTitle || 'Select/Create Course'} <ArrayDropDownIcon />
                </Button>
                <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                    <Tooltip title="Create New Chat">
                        <IconButton onClick={() => dispatch(handleNewChat())} className="button" style={{ color: themeData.color5}}>
                                <AddIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            </div> */}
            {/* <div style={{ position: 'absolute', bottom: 0, width: '95%', padding: '5px', display: 'flex', alignItems: 'center' }}>
                    <Button style={{ color:themeData.color5, backgroundColor:themeData.color3, display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', borderRadius: '30px'}} onClick={() => setIsSettingsOpen(true)} fullWidth>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <img src={moDaddy} alt="MoDaddy" style={{ height: '30px', width: '30px', borderRadius: '50%', marginRight: '10px' }} />
                                    <span> Steven Cloud </span> 
                            </div>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <SettingsIcon />
                            </div>
                    </Button>
                </div> */}
                <Popover
                        slotProps={{
                            paper: {
                                className: 'popoverPaper',
                                sx: {
                                    backgroundColor: themeData.color3,
                                    color: themeData.color5
                                }
                            },
                            // className: 'popoverPaper',
                        }}
                        open={Boolean(coursePopover)}
                        anchorEl={coursePopover} //Specifies which element to attach popover to
                        onClose={handleCourseClose}
                        anchorOrigin={{ //Controls where popover is relative to anchorEl
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        // className="popoverPaper"
                    >
                        {Object.entries(studentCourses).map(([courseId, courseName]) => (
                            <MenuItem 
                                key={courseId}
                                onClick={() => {
                                    if (editingCourseIndex !== courseId) {
                                        handleCourseClose(); 
                                        handleCourseSelect(courseId);
                                    }
                                }}
                            > {/*onClick={handleCourseClose}>*/}
                                {editingCourseIndex === courseId ? (
                                    <TextField
                                        autoFocus
                                        value={editedCourseName}
                                        onChange={handleCourseNameChange}
                                        onKeyDown={(event) => handleCourseNameUpdate(event, courseId)}
                                        fullWidth
                                    />
                                ) : (
                                    <span>
                                        {courseName}
                                    </span>
                                )}
                                <Box sx={{ flexGrow: 1}} />
                                <Tooltip title="Edit Course Title">
                                    <IconButton size="small" sx= {{
                                        color:themeData.color5,
                                        marginLeft: '37px'
                                    }} 
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        handleEditCourseClick(courseId);
                                    }}>
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Remove Course">
                                    <IconButton 
                                        size="small" 
                                        style={{ color: themeData.color5}}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            handleRemoveCourse(courseId);
                                        }}>
                                        <RemoveIcon />
                                    </IconButton>
                                </Tooltip>
                            </MenuItem>
                        ))}
                        <form onSubmit={handleAddCourse}>
                            <TextField
                                label="Type Course Name..."
                                value={newCourseName}
                                onChange={(e) => setNewCourseName(e.target.value)}
                                fullWidth
                            />
                        </form>
                    </Popover>
                {/* <div style={{ width: "100%"}}> {/* New Chat Button }
                    <Button style={{color:themeData.color5, backgroundColor: themeData.color3}}
                    onClick={handleNewChat}
                    fullWidth
                    >
                        New Chat
                    </Button>
                </div> */}
                {/* <Divider /> */}
                <List className="list"> {/* maps out saved chats */}
                    {mostRecentChats.map(conversationID => {
                        const chatTitle = courseConvoIDsToChatTitle[conversationID];
                        return (
                        <ListItem button key={conversationID} onClick={() => handleLoadChat(conversationID)}> {/*TODO: change handle load chat */}
                            {editingChatID === conversationID ? (
                                <TextField
                                    autoFocus
                                    value={editedChatTitle}
                                    onChange={(e) => setEditedChatTitle(e.target.value)}
                                    onKeyDown={(event) => handleChatTitleUpdate(event, conversationID)}
                                />
                            ) : (
                                <ListItemText primary={chatTitle} style={{ color: themeData.color5}}/>
                            )}
                            <Box sx={{ flexGrow: 1}} />
                                {/* <Tooltip title="Edit Chat Title">
                                    <IconButton size="small" style={{ color: themeData.color5}} onClick={(event) => {
                                        event.stopPropagation();
                                        handleEditChatTitleClick(conversationID, chatTitle);
                                    }}> 
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Remove Chat">
                                    <IconButton size="small" style={{ color: themeData.color5}} onClick={(event) => {
                                        event.stopPropagation();
                                        handleRemoveChat(conversationID);
                                    }}>
                                        <RemoveIcon />
                                    </IconButton>
                                </Tooltip> */}
                        </ListItem>
                        
                    );})}
                </List>
                {/* <div className="bottomBar">
                    <Button style={{ color:themeData.color5, backgroundColor:themeData.color3, display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '30px'}} onClick={() => handleSetSettingsOpen()} fullWidth>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <img src={stickman} alt="stickman" style={{ height: '30px', width: '30px', borderRadius: '50%', marginRight: '10px' }} />
                                    <span style={{ textTransform: 'none'}}> {username} </span> 
                            </div>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <SettingsIcon />
                            </div>
                    </Button>
                </div> */}
                
                        {/* <Popover
                                open={Boolean(userPopover)}
                                anchorEl={userPopover}
                                onClose={handleUserClose}
                                anchorOrigin={{//TODO: Edit these so that popover is more aesthetic, may also need to change size
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                PaperProps={{style: {backgroundColor: themeData.color3,
                                //marginBottom: '0px',
                                transform: 'translateX(-11%) translateY(-30%)',
                                color: themeData.color5,}}}
                                >
                            <MenuItem onClick={handleUserClose}>Profile</MenuItem> {/*TODO: update these to pull up some other action }
                            <MenuItem onClick={handleUserClose}>Settings</MenuItem>
                            <MenuItem onClick={handleUserClose}>Logout</MenuItem>
                        </Popover> */}
                        <div style={{ position: 'absolute', bottom: '185px', width: '95%', padding: '5px', display: 'flex', alignItems: 'center' }}>
                            {/* <Button 
                                style={{ 
                                    color: themeData.color5, 
                                    backgroundColor: themeData.color3, 
                                    display: 'flex', 
                                    //justifyContent: 'center', 
                                    //alignItems: 'center', 
                                    width: '100%', 
                                    borderRadius: '30px',
                                    height: '35px'
                                }}
                                //onClick={/* Add your event handler here }
                                fullWidth
                            >
                            <span style={{ textTransform: 'none', marginLeft: '10px'}}> Asshole Mode </span>
                            <Checkbox
                                checked={isSocraticMethodChecked}
                                onChange={handleSocraticMethodChange}
                                color="default"
                                style={{ color: themeData.color5, marginRight: '10px' }}
                            />
                            </Button> */}
                        </div>
                        {/* <div style={{ position: 'absolute', bottom: '185px', width: '95%', padding: '5px', display: 'flex', alignItems: 'center' }}>
                            <Button 
                                style={{ 
                                    color: themeData.color5, 
                                    backgroundColor: themeData.color3, 
                                    display: 'flex', 
                                    //justifyContent: 'center', 
                                    //alignItems: 'center', 
                                    width: '100%', 
                                    borderRadius: '30px',
                                    height: '35px'
                                }}
                                //onClick={}
                                fullWidth
                            >
                            <span style={{ textTransform: 'none', marginLeft: '10px'}}> Hints Only </span>
                            <Checkbox
                                checked={socraticMethod}
                                onChange={handleSocraticMethodChange}
                                color="default"
                                style={{ color: themeData.color5, marginRight: '10px' }}
                            />
                            </Button>
                            </div> */}
                        <div style={{ position: 'absolute', bottom: '140px', width: '95%', padding: '5px', display: 'flex', alignItems: 'center' }}>
                            <Button className="learningToolButton"
                                onClick={handleCreateFlashcards} 
                                fullWidth
                            >
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    {}
                                    <span style={{ textTransform: 'none'}}> Flashcards </span>
                                </div>
                            </Button>
                        </div>
                        <div style={{ position: 'absolute', bottom: '95px', width: '95%', padding: '5px', display: 'flex', alignItems: 'center' }}>
                            <Button className="learningToolButton"
                                onClick={handleQuizMe}
                                fullWidth
                            >
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    {}
                                    <span style={{ textTransform: 'none'}}> Quizzes </span>
                                </div>
                            </Button>
                        </div>
                        {/* <div style={{ position: 'absolute', height: '25px', bottom: '50px', width: '95%', padding: '5px', display: 'flex', alignItems: 'center' }}>
                            <div className="learningToolButton" fullWidth >
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                        {}
                                        <span style={{ textTransform: 'none'}}> Hints Only </span>
                                    </div>
                                    <Checkbox
                                        checked={socraticMethod}
                                        onChange={handleSocraticMethodChange}
                                        // color="default"
                                        style={{ color: themeData.color5 }} //, marginRight: '10px'
                                    />
                            </div>
                        </div> */}
                        <div style={{ position: 'absolute', bottom: '50px', width: '95%', padding: '5px', display: 'flex', alignItems: 'center' }}>
                            <Button 
                                className="learningToolButton"
                                onClick={(e) => e.preventDefault()}
                                fullWidth
                                style={{ height: '35px', pointerEvents: 'none' }} // Added height and adjusted padding
                            >
                                <div style={{
                                    display: 'flex', 
                                    alignItems: 'center', 
                                    justifyContent: 'center', 
                                    width: '100%',
                                    pointerEvents: 'none'
                                }}>
                                    <span style={{ textTransform: 'none'}}> Hints Only </span>
                                    <Checkbox
                                        checked={socraticMethod}
                                        onChange={handleSocraticMethodChange}
                                        style={{ 
                                            color: themeData.color5,
                                            pointerEvents: 'auto' }} // Removed padding from checkbox
                                        onClick={(e) => e.stopPropagation()}
                                        size="small" // Made checkbox smaller
                                    />
                                </div>
                            </Button>
                        </div>

                        <div style={{ position: 'absolute', bottom: '5px', width: '95%', padding: '5px', display: 'flex', alignItems: 'center' }}>
                            <Button 
                                className="learningToolButton"
                                onClick={() => handleSetSettingsOpen()}
                                fullWidth
                                style={{ height: '35px' }}
                            >
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    padding: '0 10px'
                                }}>
                                    <div style={{
                                        display: 'flex', 
                                        alignItems: 'center',
                                        maxWidth: 'calc(100% - 34px)'}}>
                                        <img src={stickman} alt="stickman" style={{ 
                                            height: '24px', 
                                            width: '24px', 
                                            borderRadius: '50%', 
                                            marginRight: '10px' }} />
                                        <span style={{ 
                                            textTransform: 'none',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis' }}>{username}</span>
                                    </div>
                                    <SettingsIcon />
                                </div>
                            </Button>
                        </div>

                        
                        {/*
                        <div style={{ position: 'absolute', bottom: '50px', width: '95%', padding: '5px', display: 'flex', alignItems: 'center' }}>
                            <Button 
                                style={{ 
                                color: themeData.color5, 
                                backgroundColor: themeData.color3, 
                                display: 'flex', 
                                justifyContent: 'center', 
                                alignItems: 'center', 
                                width: '100%', 
                                borderRadius: '30px'
                                }} 
                                onClick={handleUpgradeNowClick} // This is your event handler for the new button
                                fullWidth
                            >
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                <span style={{ textTransform: 'none'}}> Upgrade Now </span>
                                </div>
                            </Button>
                            </div>*/ }
            </Drawer>
    );
};

export default BottomLeftDrawer;