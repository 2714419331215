import React, { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import { AppBar, Divider, TextField, Button, List, ListItem, ListItemText,
        Toolbar, IconButton, Typography, 
        Tooltip, Snackbar } from '@mui/material';
// import platoImage from './images/platoRealLogo.png';
import platoImage from './images/plato.png'
// import platoImage from './images/newPlatoNoBG.png';
import moDaddy from './images/modaddy-circle.png';
import stickman from './images/stickman-nobg.png';

import './chat.css';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector, useDispatch } from 'react-redux';
import { removeResources,  setGeneralSnackbarOpen, setLoginSignupScreenOpen, setFlashcardPrompt,
    setResources, setMostRecentUserPrompt, addMessage, createChatTitle, setQuizPrompt, appendFlashcardToGroup,
    setSnackbarRecordingOpen, setAbortion, setStreamingCards, addPresetPrompt, setCurrentQuizID,
    setSnackbarShareChatOpen, setSettingsOpen, setDrawerOpen, handleNewChat, setCurrentFlashcardGroupID,
    setSelectedResource, setConversationID, setFlashcardQuizObject, setFlashcards, setQuiz, setCurrentFlashcardGroup,
    setChatTitle, setMessages, changeFlashcardQuizIndex, setFlashcardQuizIndex, appendQuizQuestion,
    addStreamedMessage, searchLongtermFilesAPI, addNewChat, addFlashcard, addQuizQuestion,
    getChatTitleAPI, setGeneralSnackbarMessage, setIframeDragged, appendFlashcard, prependFlashcardGroup, 
    setFlashcardsScreenOpen, setQuizMeScreenOpen, uploadContextFileAPI, uploadLongtermFileAPI, removeChatAPI,
    setCurrentFlashcardIndex, setSelectedResourceType, setCurrentQuizGroup, changeCurrentFlashcardIndex,
    getAppendFlashcardToGroup, setCurrentQuizIndex, prependQuizGroup, appendQuizQuestionToGroup } from './store.js';
import CloseIcon from '@mui/icons-material/Close';
import hljs from 'highlight.js';
import 'highlight.js/styles/default.css';
import SendIcon from '@mui/icons-material/Send';
import ShareIcon from '@mui/icons-material/IosShare';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import {Document, Page, pdfjs} from 'react-pdf';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import TopAppBar from './components/AppBar';
import BottomLeftDrawer from './components/LeftDrawer/bottom';
import TopLeftDrawer from './components/LeftDrawer/top';
// import { DjangoHost } from './hosts.js';
import {MuiMarkdown} from 'mui-markdown';
import ReactMarkdown from 'react-markdown';
import isEqual from 'lodash/isEqual';
import {debounce} from 'lodash';
import ResourcePanel from './components/ResourcePanel';
import TopResourcePanel from './components/ResourcePanel/top/topResource.js';
import FlashcardsScreen from './components/FlashcardsScreen';
import ChatInterface from './components/ChatInterface';
import customLog from './customLogger';
import QuizMeScreen from './components/QuizMeScreen/index.js';
import LoginSignupDialogComponent from './components/LoginSignupDialog/LoginSignupDialogComponent.js';
import SettingsPopup from './components/SettingsPopup/SettingsPopup.js';    
import SplitPane from 'react-split-pane';
import {Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import 'katex/dist/katex.min.css';
import { BlockMath, InlineMath } from 'react-katex';
import FlashcardsQuizMeDialog from './components/FlashcardsQuizMeDialog/flashcardsQuizMeDialog.js';
import CreateOrLibrary from './components/FlashcardsQuizMeDialog/createOrLibrary.js';
import NorthIcon from '@mui/icons-material/North';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import StopIcon from '@mui/icons-material/Stop';
import { CommitSharp } from '@mui/icons-material';
// import ChatMessages from './components/ChatMessages/newMessage.js';
import StreamingMessage from './components/ChatMessages/streamingMessage.js';
import PreviousMessages from './components/ChatMessages/prevMessages.js';
import InputForm from './components/InputForm/inputForm.js';

const DjangoHost = process.env.REACT_APP_DJANGO_HOST;


const Chat = () => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;//todo: look more into this

    const dispatch = useDispatch();

    /* DRAWER */
    // State to obtain if drawer open
    const drawerOpen = useSelector((state) => state.chat.drawerOpen);
    const [drawerWidth, setDrawerWidth] = useState(240);
    const [minPanelSize, setMinPanelSize] = useState(1050);

    // UseEffect to update drawer width when drawerOpen state changes
    useEffect(() => {
        if (drawerOpen) {
            setDrawerWidth(240);
            setMinPanelSize(1050);
        } else {
            setDrawerWidth(0);
            setMinPanelSize(800);
        }
        // setMinPanelSize(drawerWidth + 800);
        customLog('minPanelSize is ' + minPanelSize);
    }, [drawerOpen]);


    /* SETTINGS */
    const settingsOpen = useSelector((state) => state.chat.settingsOpen);

    /* TOP APP BAR */
    const snackbarShareChatOpen = useSelector((state) => state.chat.snackbarShareChatOpen);
    const snackbarRecordingOpen = useSelector((state) => state.chat.snackbarRecordingOpen);
    const snackbarRecordingMessage = useSelector((state) => state.chat.snackbarRecordingMessage);
    const generalSnackbarOpen = useSelector((state) => state.chat.generalSnackbarOpen);
    const generalSnackbarMessage = useSelector((state) => state.chat.generalSnackbarMessage);

    // Resource
    const selectedResourceType = useSelector((state) => state.chat.selectedResourceType);

    //Useeffect that executes whenever value of generalSnackbarMessage changes
    useEffect(() => {
        if (generalSnackbarMessage) {
            customLog('generalSnackbarMessage is ' + generalSnackbarMessage);
        }
    }, [generalSnackbarMessage]);

    //TODO: Temp variable for studentCourses. Need to integrate with redux
    const studentCourses = useSelector((state) => state.chat.courses);

    // const [currentArrayIndex, setCurrentIndex] = useState(0);
    

    //Toolbar height variables
    const toolbarRef = useRef(null);
    const [toolbarHeight, setToolbarHeight] = useState(0);

    //AppBar height variables
    const appBarRef = useRef(null);
    const [appBarHeight, setAppBarHeight] = useState(0);

    // height for plato no chat image
    const [platoImageBottom, setPlatoImageBottom] = useState(0);

    // Obtain username
    let username = useSelector((state) => state.chat.username);

    // Obtain isLoggedIn
    let isLoggedIn = useSelector((state) => state.chat.isLoggedIn);


    useEffect(() => {
        if (isLoggedIn) {
            setReactChatMessages([]);
        } else {
            setReactChatMessages([{
                user: 'Assistant',
                text: `**Hey! Plato here, nice to meet you :)**\n\nI'm here to be your personal tutor! Upload any file, or even just type in a prompt, and I can make you flashcards and quizzes through those buttons on the bottom left!\n\nNot only that, but try uploading a file on that bottom tab to the left of "Type your question," and I'll read the file, and you can ask me any questions about it—from summaries to having me teach it to you. It'll also pop up on the right side of the screen so you can keep looking at it!\n\nAnd for everything you upload, I'll store it in my memory. So whenever you ask any question, I'll use it to personalize my response to you and pull up the relevant part of your upload to the screen! Upload all your class notes and textbooks, ask questions, and I'll find all the relevant parts! Isn't that wonderful? No more digging through notes and textbooks trying to find some page.\n\nAnd last, but not least, if you don't want direct answers but instead want me to give you hints and questions, check the "Hints Only" on the bottom left! Be warned, I won't break character, but you can uncheck it at any time.\n\nAsk away! I'm going to make learning easier than it ever was before!`
            }])
        }
    }, [isLoggedIn]);
    
    // Obtain chatMessages
    let chatMessages = useSelector((state) => state.chat.messages);

    let [reactChatMessages, setReactChatMessages] = useState([{
        user: 'Assistant',
        text: `**Hey! Plato here, nice to meet you :)** \n
    
I'm here to be your personal tutor! Upload any file, or even just type in a prompt, and I can make you flashcards and quizzes through those buttons on the bottom left!
    
Not only that, but try uploading a file,  on that bottom tab to the left of Type your question, and I'll read the file, and you can ask me any questions about it - from summaries to having me teach it to you. It'll also pop up on the right side of the screen so you can keep looking at it!

And for everything you upload, I'll store it in my memory. So whenever you ask any question, I'll use it to personalize my response to you, and pull up the relevant part of your upload to the screen! Upload all your class notes and textbooks, ask questions, and I'll find all the relevant parts! Isn't that wonderful? No more digging through notes and textbooks trying to find some page.

And last, but not least, if you don't want direct answers, but instead want me to give you hints and questions, check the Hints Only on the bottom left! Be warned, I won't break character but you can uncheck it at anytime.

Ask away! I'm going to make learning easier than it ever was before!
`
    }]);
    // Obtain resources
    let resources = useSelector((state) => state.chat.resources);

    // Obtain context resource selected
    let contextResourceSelection = useSelector((state) => state.chat.contextResourceSelection);

    // Obtain selectedResource
    let selectedResourceRedux = useSelector((state) => state.chat.selectedResource);

    // const selectedResourceRedux = 10;

    // Obtain user email
    let userEmail = useSelector((state) => state.chat.email);

    // Variable for dragging
    const [isDragging, setIsDragging] = useState(false);

    // State for iframe
    const [iframeLoaded, setIframeLoaded] = useState(false);

    const handleIframeLoad = useCallback(() => {
        customLog('useCallback of handleIFrameLoad');
        setIframeLoaded(true);
      }, []);

    // const selectedResource = useSelector((state) => state.chat.selectedResource);
    const selectedResourceID = useSelector((state) => state.chat.selectedResourceID);
    const selectedResource = useMemo(() => {
        customLog('inside selectedResource useMemo with selectedResourceRedux as ' + selectedResourceRedux);
        customLog('inside selectedResource with resources as ' + JSON.stringify(resources) + ' and selectedResourceID as ' + selectedResourceID);
        // if (contextResourceSelection) {
        //     customLog('inside contextResourceSelection with selectedResourceRedux as ' + selectedResourceRedux);
        //     return selectedResourceRedux;
        // }
        // return resources.find((resource) => resource.id === selectedResourceID);
        // Check if a resource is selected and if the conditions to close the drawer are met
        // if (selectedResourceRedux && (window.innerWidth - 700) / 2 < 180) {
        //     dispatch(setDrawerOpen(false)); // Automatically close the drawer
        // }
        // if (selectedResourceRedux) {
        //     customLog('SELECTEDRESOURCE USEMEMO selectedResourceID is ' + selectedResourceID);
        //     dispatch(setDrawerOpen(false));
        // }

        return selectedResourceRedux;
      }, [resources, selectedResourceID]);

      // useEffect of selectedResourceRedux
        useEffect(() => {
            customLog('useEffect of selectedResourceRedux called with selectedResourceRedux as ' + selectedResourceRedux);
            if (selectedResourceRedux) {
                customLog('useEffect of selectedResourceRedux selectedResourceID is ' + selectedResourceID);
                dispatch(setDrawerOpen(false));
            }
        }, [selectedResourceRedux]);

    // const resource = useMemo(() => 
    //     resources.find((resource) => resource.id === selectedResourceID), 
    //     [resources, selectedResourceID]
    // );

    // // For displaying resource/leftDrawer when all 3 are on screen
    // const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    // const [splitPaneSize, setSplitPaneSize] = useState(0);  // Assuming initially set to 70% of window width

    // // useEffect that customLogs the splitPaneSize everytime it changes
    // useEffect(() => {
    //     customLog('updated splitPaneSize is ' + splitPaneSize);
    // }, [splitPaneSize]);


    // // Update window width on resize
    // useEffect(() => {
    //     const handleResize = () => {
    //         customLog('handleResize window.innerWidth is ' + window.innerWidth);
    //         setWindowWidth(window.innerWidth);
    //     };

    //     window.addEventListener('resize', handleResize);
    //     return () => window.removeEventListener('resize', handleResize);
    // }, []);

    // // Check sizes to toggle drawer and content visibility
    // const drawerThreshold = 1000; // Minimum width px remaining for showing drawer
    // const contentThreshold = 800; // Minimum width px remaining for showing content

    // const drawerOpenSizeLimit = windowWidth - splitPaneSize > drawerThreshold;
    // const contentVisible = windowWidth - splitPaneSize > contentThreshold;

    // useEffect(() => {
    //     customLog('drawerTh is ' + drawerThreshold);
    // }, [drawerOpenSizeLimit]);

    // useEffect(() => {
    //     customLog('contentVisible is ' + contentVisible);
    // }, [contentVisible]);

    // Debounce pane size update
    // const handlePaneResize = debounce((newSize) => {
    //     setSplitPaneSize(windowWidth - newSize);
    // }, 100);


    // selected theme
    const themeData = useSelector((state) => state.chat.themeData);

    const handleSelectResource = (resource) => {
        dispatch(setSelectedResource(resource));
    };

    //Obtain curently selected course ID
    const selectedCourseId = useSelector((state) => state.chat.selectedCourse);
    const realSelectedCourseId = useSelector((state) => state.chat.selectedCourse);
    const selectedCourseTitle = studentCourses[selectedCourseId];

    // Obtain messageToSend
    let messageToSend = useSelector((state) => state.chat.messageToSend);

    // Obtain IDToConversation from state
    const IDToConversation = useSelector((state) => state.chat.IDToConversation);

    // Maximum file size
    const MAX_FILE_SIZE = 50 * 1024 * 1024;

    const fileInputRef = useRef(null);

    const [showUploadFileDialog, setShowUploadFileDialog] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [filePreview, setFilePreview] = useState(null);

    const handleFileChange = async (event) => {
        customLog("HANDLEFILECHANGE called");
        const file = event.target.files[0];
        if (file) {
            customLog("IF (FILE) in handlefilechange");
            // Log file information
            customLog(`File name: ${file.name}`);
            customLog(`File size: ${file.size} bytes`);
            customLog(`File type: ${file.type}`);
            if (file.size > MAX_FILE_SIZE) {
                dispatch(setGeneralSnackbarMessage("File size exceeds 50MB limit"));
                dispatch(setGeneralSnackbarOpen(true));
                return;
            }
            setSelectedFile(file);
            setFilePreview(URL.createObjectURL(file));
            setShowUploadFileDialog(true);
            dispatch(setGeneralSnackbarMessage("Plato's reading your file!"));
            dispatch(setGeneralSnackbarOpen(true));

            if (!currentConversationID) {
                customLog('shortTermMemory, no conversationID - one being created');
                try { //TODO: Replicated code from chat.js, story in central place & use
                    const conversationResponse = await fetch(DjangoHost+"/api/conversation/", {
                        method: "POST",
                        headers: { "Content-Type": "application/json",
                                    Authorization:  `Bearer ${APIToken}`},
                        body: JSON.stringify({course_id: selectedCourseId})                     
                        });
                    const data = await conversationResponse.json();
                    if (conversationResponse.ok) {
                        // console.log(data);
                        customLog("HANDLEFILECHANGE conversation ID successfully made");
                        let conversation_id = data.conversation_id;
                        dispatch(setConversationID(conversation_id));

                        // dispatch(addNewChat(conversation_id));
                        // console.log('conversation_id right before createChatTitle dispatch is ' + conversation_id);
                    } else {
                        // console.log('error');
                        customLog("HANDLEFILECHANGE conversation ID failed");
                    }
                    
                    } catch (error) {
                    console.error("Error:", error);
                    alert("Error", "Failed to register");
                }
            }

            // dispatch(uploadLongtermFileAPI(file));

            // Assuming uploadContextFileAPI expects the file as its argument
            dispatch(uploadContextFileAPI(file))
                .unwrap()
                .then(() => {
                    // console.log("File uploaded successfully");
                    // Handle success, maybe close the dialog or show a success message
                })
                .catch((error) => {
                    console.error("Failed to upload file: ", error);
                    // Handle error, maybe show an error message
                });
        }
    };

    const handleFileUploadClick = () => {
        if (!isLoggedIn) {
            dispatch(setLoginSignupScreenOpen({loginSignupScreenOpen: true, loginSignupTab: 1}));
        } else {
            fileInputRef.current.click();
        }
    };

    // Obtain chatTitle
    let selectedChatTitle = useSelector((state) => state.chat.selectedChatTitle);

    // State variable for is message has been sent (determines if Plato is on screen)
    const [messageSent, setMessageSent] = useState(false);

    // Obtain selectedCourse
    let selectedCourse = useSelector((state) => state.chat.selectedCourse);

    //Obtain current conversation ID
    let currentConversationID = useSelector((state) => state.chat.currentConversationID);
    
    const [showSettingsDialog, setShowSettingsDialog] = useState(false);

    // Obtain streamedMessages from redux state
    let streamedMessages = useSelector((state) => state.chat.streamedMessages);
    
    // State for if course is being created
    const creatingCourse = useSelector((state) => state.chat.creatingCourse);

    // State for if flashcardsScreen is open
    const flashcardsScreenOpen = useSelector((state) => state.chat.flashcardsScreenOpen);

    // State for if in code block
    const [inCodeBlock, setInCodeBlock] = useState(false);

    // State for current langugage for code block
    const [currentLanguage, setCurrentLanguage] = useState('text');

    // State for if quizMeScreen is open
    const quizMeScreenOpen = useSelector((state) => state.chat.quizMeScreenOpen);

    // State for flashcards & QuizMe dialog
    const [showFlashcardsDialog, setShowFlashcardsDialog] = useState(false);
    // const [showQuizMeDialog, setShowQuizMeDialog] = useState(false);


    // Add a state for tracking window width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    //rows
    const [rows, setRows] = useState(1);
    const textFieldRowHeight = 22; // Height of one row in the textfield TODO: Find better values for these
    const textFieldPadding = 30; // Total vertical padding inside the textfield

    const calculateTextFieldHeight = (rows) => {
        return rows * textFieldRowHeight + textFieldPadding;
    };

    const [textFieldHeight, setTextFieldHeight] = useState(calculateTextFieldHeight(rows));

    const handleChange = (event) => {
        
        setInput(event.target.value);
        // inputRef.current = event.target.value;
        if (event.key === 'Enter') {
            //event.preventDefault(); // Prevent the default action to avoid adding a new line
            //document.getElementById('messageForm').dispatchEvent(new Event('submit', {cancelable: true})); // Manually trigger form submission
            // handleSend;
        }
    };

    // const [messages, setMessages] = useState([]);
    const [input, setInput] = useState("");
    const inputRef = useRef('');
    const [storedInput, setStoredInput] = useState("");
    const [loading, setLoading] = useState(false);

    //State for menus
    const [coursePopover, setCoursePopover] = useState(null);
    const [userPopover, setUserPopover] = useState(null);


    //Effect for setting no chat plato image bottom height
    useEffect(() => {
        // Calculate the bottom position of the no chat Plato image to align it with the top of the form-style input textfield
        // This example assumes you want to place the bottom of the image exactly at the top of the textfield,
        // adjust the calculation based on your actual layout, margins, and paddings
        const newPlatoImageBottom = textFieldHeight;/* your calculation here, based on textFieldHeight and any other relevant layout measurements */;
        setPlatoImageBottom(newPlatoImageBottom);
    }, [textFieldHeight]);


    


    //Function to toggle the drawer
    // const toggleDrawer = () => {
    //     customLog('toggleDrawer called with drawerOpen of ' + drawerOpen);
    //     customLog('!drawerOpen is ' + !drawerOpen);
    //     dispatch(setDrawerOpen(!drawerOpen));
    // };

    const handleUserClick = (event) => {
        setUserPopover(event.currentTarget);
    };

    const handleUserClose = () => {
        setUserPopover(null);
    };

    const [resourcePanelWidth, setResourcePanelWidth] = useState('30%');

    const [splitPanePosition, setSplitPanePosition] = useState(window.innerWidth * 0.7);

    // Function to get the initial split pane position
    const getInitialSplitPanePosition = () => window.innerWidth * 0.7;

    // Effect to set the initial split pane position after mount
    // useEffect(() => {
    //     setSplitPanePosition(getInitialSplitPanePosition());
    // }, []);

    // // Add an event listener to update the split pane position on window resize
    // useEffect(() => {
    //     const handleResize = () => {
    //         setSplitPanePosition(getInitialSplitPanePosition());
    //     };

    //     window.addEventListener('resize', handleResize);
    //     return () => {
    //         window.removeEventListener('resize', handleResize);
    //     };
    // }, []);

    const handleDragFinished = useCallback(debounce((newSize) => {
        const newWidth = `${newSize}px`;
        // setResourcePanelWidth(newWidth);
        // setSplitPanePosition(newSize);
        // customLog('newWidth is ' + newWidth);
        // document.documentElement.style.setProperty('--resource-panel-width', newSize);
        
        // if (iframeLoaded) {
        //     customLog('if iframeLoaded in handleDragFinished');
        //     const iframe = document.querySelector('iframe');
        //     if (iframe) {
        //         customLog('posting message from handleDragFinished');
        //       iframe.contentWindow.postMessage({ type: 'resized', width: newSize }, '*');
        //     }
        //   }
    }, 200), []);



    //Helper function to detect language
    const detectLanguage = (code) => {
        const result = hljs.highlightAuto(code);
        return result.language;
    };

    const presetPrompt = useSelector((state) => state.chat.presetPrompt);
    
    // UseEffect where if messageToSend changes, sendOrCreateChatMessageStreaming called
    useEffect(() => {
        if (messageToSend) {
            customLog('useEffect messageToSend called with presetPrompt of ' + presetPrompt);
            // setInput(messageToSend);
            // handleSend(null, messageToSend, presetPrompt);
            responseRef.current = '';
            setStreamedResponse('');
            sendOrCreateChatMessageStreaming({presetPrompt: presetPrompt })
        }
    }, [messageToSend]);

    // state variable for presetPrompt streamed response
    const [presetPromptStreamedResponse, setPresetPromptStreamedResponse] = useState("");
    const newMessageRef = useRef(true);
    const sendPresetPrompt = async ({presetPrompt}) => {
        setIsStreaming(true);
        newMessageRef.current = true;
        // dispatch(handleNewChat());
        //dispatch(removeResources()); //TODO: Think if this is best user design
        abortControllerRef.current = new AbortController();
        let signal = abortControllerRef.current.signal;
        customLog('SENDpresetPrompt beginning');
        try {
            customLog('SYSTEMPROMPT is ' + systemPrompt);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", `Bearer ${APIToken}`);
            var raw;
            customLog('sendpresetPrompt presetPrompt mode true ');
            raw = JSON.stringify({
                "id": currentFlashcardQuiz.id,
                "type": currentFlashcardQuiz.answer ? 1 : 2
            });
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            let accumulatedData = "";
            let endpoint;
            let prefix = '';
            if (presetPrompt === 'hint') {
                endpoint = '/api/message/hint/'
                prefix = '**Hint:** ';
                accumulatedData = '**Hint:** ';
            } else if (presetPrompt === 'explain') {
                endpoint = '/api/message/explain/'
                prefix = '**Explanation:** ';
                accumulatedData = '**Explanation:** ';
            } else if (presetPrompt === 'summary') {
                endpoint = '/api/message/summary/';
                prefix = '**Summary:** ';
                accumulatedData = '**Summary:** ';
            } else if (presetPrompt === 'question') {
                endpoint = '/api/message/question/';
                prefix = '**Question:** ';
                accumulatedData = '**Question:** ';
            } else if (presetPrompt === 'teach') {
                endpoint = 'api/message/teach/';
            }
            fetch(DjangoHost + endpoint, 
                {
                    ...requestOptions,
                    signal
                }
            )
                .then(response => {
                    const reader = response.body.getReader();
                    return new ReadableStream({
                        async start(controller) {
                            let isPrefixAdded = false; // Ensure prefix is only added once
                            while (true) {
                                let { done, value } = await reader.read();
                                if (done) {
                                    customLog('streamedResponse is ' + accumulatedData);
                                    reactChatMessages.forEach((item) =>
                                        customLog('REACTCHATMESSAGES IS ' + JSON.stringify(item))
                                    );
                                    // dispatch(addStreamedMessage({ streamedResponse: accumulatedData }));
                                    dispatch(addPresetPrompt({message: accumulatedData}))
                                    setIsStreaming(false);
                                    const newMessage = { user: "Assistant", text: accumulatedData };
                                    customLog('NEWMESSAGE presetPrompt BEFORE reactChatMessages is ' + JSON.stringify(reactChatMessages));
                                    setStreamedResponse('');
                                    setReactChatMessages((prevReactChatMessages) => {
                                        return [...prevReactChatMessages, newMessage];
                                    });
                                    break;
                                }
                                if (cancelStream) {
                                    customLog("CANCELSTREAM is true");
                                    reader.cancel();
                                    break;
                                    setCancelStream(false);
                                }
                                // Add prefix only once at the beginning
                                if (!isPrefixAdded) {
                                    setStreamedResponse((r) => r + prefix);
                                    isPrefixAdded = true;  // Ensure the prefix isn't added again
                                }
                                value = new TextDecoder().decode(value);
                                customLog('presetPromptvalue is ' + value);
                                accumulatedData += value;
                                setStreamedResponse((r) => r + value);
                                responseRef.current = responseRef.current + value;

                                controller.enqueue(value);
                            }
                            controller.close();
                            reader.releaseLock();
                        }
                    })
                })
                .catch(error => console.log('error', error));
        } catch (error) {
            console.error("Error in sendpresetPrompt:", error);
        }
    };

    // updates chat messages when streamedResponse updates
    useEffect(() => {
        customLog('USEEFFECT presetPromptSTREAMEDRESPONSE');
        if (presetPromptStreamedResponse && presetPromptStreamedResponse.length) {
            setReactChatMessages((prevReactChatMessages) => {
                if (prevReactChatMessages.length === 0) {
                    return [{user: "Assistant", text: presetPromptStreamedResponse}];
                } else {
                    return prevReactChatMessages.map((item, index) => 
                        index === prevReactChatMessages.length - 1
                        ? { user: 'Assistant', text: presetPromptStreamedResponse}
                        : item
                    )
                }
            })
        }

    }, [presetPromptStreamedResponse]);


    
    const handleSettingsClick = () => {
        setShowSettingsDialog(true);
    };
    let ongoingResponse = "";
    // state variable for streamed response
    const [streamedResponse, setStreamedResponse] = useState("");

    const [conversationJustCreated, setConversationJustCreated] = useState(false);

    const conversationJustCreatedRef = useRef(null);

    // useSelector for system prompt
    const systemPrompt = useSelector((state) => state.chat.systemPrompt);

    // ref for bots response
    const responseRef = useRef('');

    const [cancelStream, setCancelStream] = useState(false);
    let abortion = useSelector((state) => state.chat.abortion);

    // Use effect for if abortion changes, check if isStreaming and if so, call handleAbort
    useEffect(() => {
        if (abortion) {
            customLog('useEffect abortion called with abortion of ' + abortion);
            if (isStreaming) {
                customLog('useEffect abortion isStreaming is true');
                handleAbort();
            }
        }
        dispatch(setAbortion(false));
    }, [abortion]);

    const [tooltipOpen, setTooltipOpen] = useState(false);
    let APIToken = useSelector((state) => state.chat.APIToken);

    const abortControllerRef = useRef(null);
    const [isStreaming, setIsStreaming] = useState(false);
    // let abortController = new AbortController();
    // let signal = abortController.signal;
    const handleAbort = () => {
        abortControllerRef.current.abort();
        customLog('HANDLEABORT called');
        // setCancelStream(true);
        setIsStreaming(false);
        setTooltipOpen(false);
        customLog('CONVERSATIONJUSTCREATEDREF.CURRENT is ' + conversationJustCreatedRef.current);
        let conversationID = conversationJustCreatedRef.current;
        if (conversationJustCreatedRef.current) {
            dispatch(removeChatAPI({conversationID}));
            // dispatch(handleNewChat());

        }
      };

    // useSelector for quizPrompt
    const quizPrompt = useSelector((state) => state.chat.quizPrompt);
    // const flashcardArray = useSelector((state) => state.chat.flashcardArray);

    // const [quiz, setQuiz] = useState([]);

    const [uploadQuizFile, setUploadQuizFile] = useState(null);

        // // State to hold the uploaded file
        // const [uploadedFlashcardFile, setUploadedFlashcardFile] = useState(null);

        // // useEffect that if flashcardPrompt changes, it calls createFlashcards
        // useEffect(() => {
        //     customLog('useEffect of flashcardPrompt called with flashcardPrompt of ' + flashcardPrompt);
        //     if (flashcardPrompt) {
        //         customLog('flashcard prompt changed and now is ' + flashcardPrompt);
        //         createFlashcards({message: flashcardPrompt});
        //     } else {
        //         if (uploadedFlashcardFile) {
        //             customLog('uploadedFile is ' + uploadedFlashcardFile);
        //             createFlashcards({file: uploadedFlashcardFile});
        //     }
        // }
        // }, [flashcardPrompt, uploadedFlashcardFile]);
    
        //     // Function to handle file update from child
        //     const flashcardFileUpload = (file) => {
        //         setUploadedFlashcardFile(file);
        //         // You can also perform any additional processing here
        //         console.log('File uploaded:', file);
        //     };

    const quizFileUpload = (file) => {  
        setUploadQuizFile(file);
    };

    // useEffect that if quizPrompt changes, it calls createQuiz
    useEffect(() => {
        customLog('useEffect of quizPrompt called with quizPrompt of ' + quizPrompt);
        if (quizPrompt) {
            customLog('flashcard prompt changed and now is ' + quizPrompt);
            createQuiz({message: quizPrompt});
        } else if (uploadQuizFile) {
            if (uploadQuizFile) {
                customLog('uploadedFile is ' + uploadQuizFile);
                createQuiz({file: uploadQuizFile});
        }
    }
    }, [quizPrompt, uploadQuizFile]);

        const createQuiz = async ({message, file}) => {
            customLog('createQuiz called with message ' + message);
            dispatch(setGeneralSnackbarMessage("Plato's making your quiz!"));
            dispatch(setGeneralSnackbarOpen(true));
            dispatch(setStreamingCards(true));

            dispatch(setCurrentQuizIndex(0));
            dispatch(setCurrentQuizGroup(null));
            dispatch(setSelectedResourceType('quiz'));
    
            try {
                var myHeaders = new Headers();
                // myHeaders.append("Content-Type", "application/json");
                myHeaders.append("Authorization", `Bearer ${APIToken}`);
                var raw;
                if (message) {
                    myHeaders.append("Content-Type", "application/json");
                    raw = JSON.stringify({
                        "message": message,
                    });
                } else {
                    raw = new FormData();
                    raw.append('file', file);
                }
                let quizGroup = {
                    name: '',
                    id: '',   
                    quiz_questions: [],
                };
    
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
    
                let accumulatedData = "";
                fetch(DjangoHost + "/api/quiz/", 
                    {
                        ...requestOptions
                        // signal
                    }
                )
                    .then(response => {
                        const reader = response.body.getReader();
                        return new ReadableStream({
                            async start(controller) {
                                while (true) {
                                    let { done, value } = await reader.read();
                                    if (done) {
                                        customLog('streamedQuiz is ' + accumulatedData);
                                        // setUploadQuizFile(null);
                                        // dispatch(setQuizPrompt(''));
                                        dispatch(prependQuizGroup());
                                        dispatch(setStreamingCards(false));
                                        break;
                                    }
                                    accumulatedData = "";
                                    value = new TextDecoder().decode(value);
                                    customLog('value is ' + value);
                                    accumulatedData += value;
                                    customLog('accumulatedData is ' + accumulatedData);

                                    // Parse and process the accumulated data
                                    while (value.length > 0) {
                                        customLog('WHILE ACCUMULATEDDATA beginning');
                                        try {
                                            customLog('BEFORE PARSING')
                                            const parsedData = JSON.parse(value);
                                            customLog('QUIZSTREAM parsedData is ' + JSON.stringify(parsedData));
                                            
                                            if (parsedData.question && parsedData.choices) {
                                                customLog('QUIZSTREAM make formatted question parsedData is ' + JSON.stringify(parsedData));
                                                dispatch(appendQuizQuestionToGroup({quizQuestion: parsedData, incrementQuestion: false}));
                                                // quizGroup.quiz_questions.push(parsedData);
                                            } else {
                                                dispatch(setCurrentQuizID(parsedData.id));
                                                quizGroup.id = parsedData.id;
                                                quizGroup.name = parsedData.name;
                                                
                                                dispatch(setCurrentQuizGroup(quizGroup));
                                        
                                            }
                                            
                                            // Clear the processed data
                                            value = "";
                                        } catch (error) {
                                            // If parsing fails, it might be an incomplete JSON object
                                            // We'll wait for more data
                                            break;
                                        }
                                    }
                                }
                                controller.close();
                                reader.releaseLock();
                            }
                        })
                    })
                    .catch(error => console.log('error', error));
            } catch (error) {
                console.error("Error in createQuiz:", error);
            }
        };

        // const [extendQuizGroup, setExtendQuizGroup] = useState(false);
        const currentQuizID = useSelector((state) => state.chat.currentQuizID);
        // useEffect(() => {
        //     if (extendQuizGroup) {
        //         customLog('USEEFFECT extendQuizGroup with group id of ' + currentQuizID);
        //         extendQuiz({quizGroupID: currentQuizID});
        //         setExtendQuizGroup(false);
        //     }
        // }, [extendQuizGroup]);

        const extendQuiz = async ({quizGroupID}) => {
            customLog('extendQuiz called with quizGroupID ' + quizGroupID);
            // setQuiz([]);
            // setFlashcards([]);
            // dispatch(setQuiz([]));
            // dispatch(setFlashcards([]));
            // dispatch(setFlashcardQuizIndex(0));
            dispatch(setGeneralSnackbarMessage("Plato's making more!"));
            dispatch(setGeneralSnackbarOpen(true));
            dispatch(setStreamingCards(true));

            let firstQuiz = true;
    
            try {
                var myHeaders = new Headers();
                // myHeaders.append("Content-Type", "application/json");
                myHeaders.append("Authorization", `Bearer ${APIToken}`);
                var raw;
                myHeaders.append("Content-Type", "application/json");
                raw = JSON.stringify({
                    "quiz_id": quizGroupID,
                })
    
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
    
                let accumulatedData = "";
                fetch(DjangoHost + "/api/quiz/extend/", 
                    {
                        ...requestOptions
                    }
                )
                    .then(response => {
                        const reader = response.body.getReader();
                        return new ReadableStream({
                            async start(controller) {
                                while (true) {
                                    let { done, value } = await reader.read();
                                    if (done) {
                                        customLog('streamedQuiz is ' + accumulatedData);
                                        dispatch(setStreamingCards(false));
                                        break;
                                    }
                                    accumulatedData = "";
                                    value = new TextDecoder().decode(value);
                                    customLog('value is ' + value);
                                    accumulatedData += value;
                                    customLog('accumulatedData is ' + accumulatedData);

                                    // Parse and process the accumulated data
                                    while (value.length > 0) {
                                        customLog('WHILE ACCUMULATEDDATA beginning');
                                        try {
                                            customLog('BEFORE PARSING')
                                            const parsedData = JSON.parse(value);
                                            customLog('QUIZSTREAM parsedData is ');
                                            
                                            if (parsedData.question && parsedData.choices) {
                                                customLog('QUIZSTREAM make formatted question');
                                                // Print Q, A, B, C, D
                                                // customLog('QUIZSTREAM parsedData.question is ' + parsedData.question);
                                                // customLog('QUIZSTREAM parsedData.choices[0].body is ' + parsedData.choices[0].body);
                                                // customLog('QUIZSTREAM parsedData.choices[1].body is ' + parsedData.choices[1].body);
                                                // customLog('QUIZSTREAM parsedData.choices[2].body is ' + parsedData.choices[2].body);
                                                // customLog('QUIZSTREAM parsedData.choices[3].body is ' + parsedData.choices[3].body);
                                                // customLog('QUIZSTREAM parsedData.choices.findIndex(choice => choice.is_correct) is ' + parsedData.choices.findIndex(choice => choice.is_correct));
                                                // const formattedQuestion = {
                                                //     Q: parsedData.question,
                                                //     A: parsedData.choices[0].body,
                                                //     B: parsedData.choices[1].body,
                                                //     C: parsedData.choices[2].body,
                                                //     D: parsedData.choices[3].body,
                                                //     Correct: String.fromCharCode(65 + parsedData.choices.findIndex(choice => choice.is_correct)),
                                                //     id: parsedData.id
                                                // };
                                                // setQuiz(prevQuiz => [...prevQuiz, formattedQuestion]);
                                                dispatch(appendQuizQuestionToGroup({parsedData, incrementCard: firstQuiz}));

                                                if (firstQuiz) {
                                                    // dispatch(changeFlashcardQuizIndex(1));
                                                    firstQuiz = false;
                                                }
                                            }
                                            
                                            // Clear the processed data
                                            value = "";
                                        } catch (error) {
                                            // If parsing fails, it might be an incomplete JSON object
                                            // We'll wait for more data
                                            break;
                                        }
                                    }
                                }
                                controller.close();
                                reader.releaseLock();
                            }
                        })
                    })
                    .catch(error => console.log('error', error));
            } catch (error) {
                console.error("Error in createQuiz:", error);
            }
        };

      // --------------------------------------------------------------------
    
      let flashcardText = '';

    // Flashcard UseSelectors
    const flashcardPrompt = useSelector((state) => state.chat.flashcardPrompt);
    const currentFlashcardGroup = useSelector((state) => state.chat.currentFlashcardGroup);
    
    // Quiz UseSelectors
    const currentQuizGroup = useSelector((state) => state.chat.currentQuizGroup);
    // const flashcardArray = useSelector((state) => state.chat.flashcardArray);

    const flashcards = useSelector((state) => state.chat.flashcards);
    useEffect(() => {
        customLog('flashcards now is ' + flashcards);
    }, [flashcards]);
    const quiz = useSelector((state) => state.chat.quiz);

    // State to hold the uploaded file
    const [uploadedFlashcardFile, setUploadedFlashcardFile] = useState(null);

    const currentFlashcardQuizIndex = useSelector((state) => state.chat.currentFlashcardQuizIndex);
    const currentFlashcardQuiz = useSelector((state) => state.chat.currentFlashcardQuiz);

    // useEffect that if flashcardPrompt changes, it calls createFlashcards
    useEffect(() => {
        customLog('useEffect of flashcardPrompt called with flashcardPrompt of ' + flashcardPrompt);
        if (flashcardPrompt) {
            customLog('flashcard prompt changed and now is ' + flashcardPrompt);
            createFlashcards({message: flashcardPrompt});
        } else if (uploadedFlashcardFile){
            if (uploadedFlashcardFile) {
                customLog('uploadedFile is ' + uploadedFlashcardFile);
                createFlashcards({file: uploadedFlashcardFile});
        }
    }
    }, [flashcardPrompt, uploadedFlashcardFile]);

        // Function to handle file update from child
        const flashcardFileUpload = (file) => {
            setUploadedFlashcardFile(file);
            // You can also perform any additional processing here
            console.log('File uploaded:', file);
        };

        const [extendFlashcardGroup, setExtendFlashcardGroup] = useState(false);
        const currentFlashcardGroupID = useSelector((state) => state.chat.currentFlashcardGroupID);
        useEffect(() => {
            if (extendFlashcardGroup) {
                customLog('USEEFFECT EXTENDFLASHCARDGROUP with group id of ' + currentFlashcardGroupID);
                extendFlashcards({flashcardGroupID: currentFlashcardGroupID});
                setExtendFlashcardGroup(false);
            }
        }, [extendFlashcardGroup]);


        useEffect(() => {
            customLog('USEEFFECT CURRENTFLASHCARDGROUP forcing rerender');
            // customLog(JSON.stringify('RESOURCE IS ' + resource));
            // setBullshitUseState(currentFlashcardGroup);
        }, [currentFlashcardGroup]);
        



        const extendFlashcards = async ({flashcardGroupID}) => {
            customLog('extendFlashcards called with group ID ' + flashcardGroupID);
            dispatch(setGeneralSnackbarMessage("Plato's making more!"));
            dispatch(setGeneralSnackbarOpen(true));
            dispatch(setStreamingCards(true));
            let firstCard = true;
    
            try {
                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${APIToken}`);
                var raw;
                myHeaders.append("Content-Type", "application/json");
                raw = JSON.stringify({
                    "flashcard_group_id": flashcardGroupID,
                })
                

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
    
                let accumulatedData = "";
                fetch(DjangoHost + "/api/flashcards/extend/", 
                    {
                        ...requestOptions
                    }
                )
                    .then(response => {
                        const reader = response.body.getReader();
                        return new ReadableStream({
                            async start(controller) {
                                while (true) {
                                    let { done, value } = await reader.read();
                                    if (done) {
                                        customLog('streamedFlashcard is ' + accumulatedData);
                                        dispatch(setStreamingCards(false));
                                        break;
                                    }
                                    value = new TextDecoder().decode(value);
                                    customLog('value is ' + value);
                                    accumulatedData += value;

                                    // Parse and process the accumulated data
                                    let startIndex = 0;
                                    while (true) {
                                        const endIndex = accumulatedData.indexOf('}', startIndex);
                                        if (endIndex === -1) break;

                                        const jsonStr = accumulatedData.substring(startIndex, endIndex + 1);
                                        try {
                                            const flashcard = JSON.parse(jsonStr);
                                            if (!flashcard.owner_id) {
                                                // dispatch(appendFlashcard(flashcard));
                                                customLog('EXTENDFLASHCARDS being appended to group is ' + JSON.stringify(flashcard));
                                                dispatch(getAppendFlashcardToGroup({flashcard, incrementCard: firstCard}));
                                            }
                                            if (firstCard) {
                                                // dispatch(changeCurrentFlashcardIndex(1));
                                                firstCard = false;
                                            }
                                            startIndex = endIndex + 1;
                                        } catch (error) {
                                            // If parsing fails, it might be an incomplete JSON object
                                            break;
                                        }
                                    }


                                    // Remove processed data from accumulatedData
                                    accumulatedData = accumulatedData.slice(startIndex);

                                    controller.enqueue(value);
                                }
                                controller.close();
                                reader.releaseLock();
                            }
                        })
                    })
                    .catch(error => console.log('error', error));
            } catch (error) {
                console.error("Error in extendFlashcards:", error);
            }
        };

        // const retrieveFlashcards = async ({flashcardGroup}) => {
        //     // dispatch(setSelectedResource(flashcardGroup));
        //     dispatch(setSelectedResourceType('flashcards'));
        //     dispatch(setSelectedResource(10));
        //     dispatch(setCurrentFlashcardGroupID(flashcardGroup));
        //     dispatch(setCurrentFlashcardGroup(flashcardGroup));
        // }
        const retrieveFlashcards = async ({flashcardGroup}) => {
            customLog('retrieveFlashcards called with flashcardGroup ' + flashcardGroup);
            // dispatch(setGeneralSnackbarMessage("Plato's retrieving your flashcards!"));
            // dispatch(setGeneralSnackbarOpen(true));
            // dispatch(setStreamingCards(true));

            dispatch(setCurrentFlashcardIndex(0));
            dispatch(setCurrentFlashcardGroup(null));
            dispatch(setSelectedResourceType('flashcards'));
            dispatch(setCurrentQuizGroup(null));

            dispatch(setCurrentFlashcardGroupID(flashcardGroup.id));
            dispatch(setCurrentFlashcardGroup(flashcardGroup));
            dispatch(setSelectedResource({id: 'flashcard', type: 'flashcards', title: 'flashy', url: 'na'}));
            // dispatch(setStreamingCards(false));

            };

        const retrieveQuiz = async ({quizGroup}) => {
            customLog('retrieveQuiz called with quizGroup ' + quizGroup);
            // dispatch(setGeneralSnackbarMessage("Plato's retrieving your quiz!"));
            // dispatch(setGeneralSnackbarOpen(true));
            // dispatch(setStreamingCards(true));

            dispatch(setCurrentQuizIndex(0));
            dispatch(setCurrentQuizGroup(null));
            dispatch(setSelectedResourceType('quiz'));
            dispatch(setCurrentFlashcardGroup(null));

            dispatch(setCurrentQuizID(quizGroup.id));
            dispatch(setCurrentQuizGroup(quizGroup));
            dispatch(setSelectedResource({id: 'quiz', type: 'quizzes', title: 'flashy', url: 'na'}));
            // dispatch(setStreamingCards(false));

            };


        const createFlashcards = async ({message, file}) => {
            customLog('createFlashcards called with message ' + message);
            // setFlashcards([]);
            // setQuiz([]);
            // dispatch(setFlashcards([]));
            // dispatch(setQuiz([]));
            dispatch(setGeneralSnackbarMessage("Plato's making your flashcards!"));
            dispatch(setGeneralSnackbarOpen(true));
            dispatch(setStreamingCards(true));

            dispatch(setCurrentFlashcardIndex(0));
            dispatch(setCurrentFlashcardGroup(null));
            dispatch(setSelectedResourceType('flashcards'));
            dispatch(setCurrentQuizGroup(null));
    
            try {
                var myHeaders = new Headers();
                // myHeaders.append("Content-Type", "application/json");
                myHeaders.append("Authorization", `Bearer ${APIToken}`);
                var raw;
                if (message) {
                    myHeaders.append("Content-Type", "application/json");
                    raw = JSON.stringify({
                        "message": message,
                    });
                } else {
                    raw = new FormData();
                    raw.append('file', file);
                }

                let firstCard = true;

    
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
    
                let accumulatedData = "";
                fetch(DjangoHost + "/api/flashcards/", 
                    {
                        ...requestOptions
                        // signal
                    }
                )
                    .then(response => {
                        const reader = response.body.getReader();
                        return new ReadableStream({
                            async start(controller) {
                                while (true) {
                                    let { done, value } = await reader.read();
                                    if (done) {
                                        customLog('streamedFlashcard is ' + accumulatedData);
                                        // dispatch(setFlashcardPrompt(''));
                                        // setUploadedFlashcardFile(null);
                                        // dispatch(setCurrentFlashcardGroupID());
                                        //TODO: ADD FLASHCARDS FROM NEW GROUP TO ALLFLASHCARDGROUP
                                        dispatch(prependFlashcardGroup());
                                        dispatch(setStreamingCards(false));
                                        break;
                                    }
                                    value = new TextDecoder().decode(value);
                                    customLog('value is ' + value);
                                    accumulatedData += value;

                                    // Parse and process the accumulated data
                                    let startIndex = 0;
                                    while (true) {
                                        const endIndex = accumulatedData.indexOf('}', startIndex);
                                        if (endIndex === -1) break;

                                        const jsonStr = accumulatedData.substring(startIndex, endIndex + 1);
                                        try {
                                            const flashcard = JSON.parse(jsonStr);
                                            if (!flashcard.owner_id) {
                                                customLog('!FLASHCARD.OWNER_ID within createFlashcards');
                                                // dispatch(addFlashcard(flashcard));
                                                dispatch(getAppendFlashcardToGroup({flashcard, incrementCard: false}));
                                            } else {
                                                customLog('CREATEFLASHCARDS ELSE with group id of ' + flashcard.id);
                                                dispatch(setCurrentFlashcardGroupID(flashcard.id));
                                                const newGroup = {
                                                    name: flashcard.name || 'Placeholder',
                                                    id: flashcard.id,
                                                    flashcards: [],
                                                };
                                                // dispatch(prependFlashcardGroup(newGroup));
                                                dispatch(setCurrentFlashcardGroup(newGroup));
                                                dispatch(setSelectedResource({id: 'flashcard', type: 'flashcards', title: 'flashy', url: 'na'}));
                                            }

                                            startIndex = endIndex + 1;
                                        } catch (error) {
                                            // If parsing fails, it might be an incomplete JSON object
                                            break;
                                        }
                                    }
                                    // Remove processed data from accumulatedData
                                    accumulatedData = accumulatedData.slice(startIndex);

                                    controller.enqueue(value);
                                }
                                controller.close();
                                reader.releaseLock();
                            }
                        })
                    })
                    .catch(error => console.log('error', error));
            } catch (error) {
                console.error("Error in createFlashacrds:", error);
            }
        };

    //let APIToken = useSelector((state) => state.chat.APIToken)
    const sendOrCreateChatMessageStreaming = async ({selectedCourse, message, presetPrompt, currentID}) => {
        setIsStreaming(true);
        dispatch(removeResources()); //TODO: Think if this is best user design
        // if (!presetPrompt) {
        if (!presetPrompt) {
            dispatch(setMostRecentUserPrompt(message)); //PREV was input
            dispatch(searchLongtermFilesAPI(message));
        }

        abortControllerRef.current = new AbortController();
        let signal = abortControllerRef.current.signal;

        let conversation_id = currentConversationID;

        if (currentConversationID === null) {
            customLog('SENDORCREATECHATMESSAGESTREAMING creating conversation');
            try {
                const conversationResponse = await fetch(DjangoHost + "/api/conversation/", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${APIToken}`
                    },
                    body: JSON.stringify({ course_id: selectedCourseId })
                });
                const data = await conversationResponse.json();
                if (conversationResponse.ok) {
                    conversation_id = data.conversation_id;
                    dispatch(setConversationID(conversation_id));
                    conversationJustCreatedRef.current = conversation_id;
                } else {
                    customLog('else of sendOrCreateChatMessageStreaming');
                    dispatch(setGeneralSnackbarMessage('Failed to create conversation'));
                    dispatch(setGeneralSnackbarOpen(true));
                    return;
                }
            } catch (error) {
                customLog("catch (error) in sendOrCreateChatMessageStreaming creating convo", error);
                dispatch(setGeneralSnackbarMessage('Failed to create conversation'));
                dispatch(setGeneralSnackbarOpen(true));
                return;
            }
        } else {
            conversationJustCreatedRef.current = false;
        }

        try {
            customLog('SYSTEMPROMPT is ' + systemPrompt);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", `Bearer ${APIToken}`);
            var raw;
            if (presetPrompt === 'explain' || presetPrompt === 'hint') { //TODO: CHANGE THIS TO INSTEAD HAVE MORE CASES FOR WHAT PRESETPROMPT IS
                customLog('SENDORCREATE presetPrompt mode true ');
                raw = JSON.stringify({
                    "id": currentID,
                    "type": selectedResourceType === 'flashcards' ? 1 : 2,
                    "conversation_id": conversation_id,
                });
            } else if (presetPrompt === 'question' || presetPrompt === 'summary') {
                raw = JSON.stringify({
                    "file_id": currentID,
                    "conversation_id": conversation_id,
                })
            } else {
                customLog('SENDORCREATE presetPrompt mode false ');
                    raw = JSON.stringify({
                        "conversation_id": conversation_id,
                        "message": message,
                        "system_prompt": systemPrompt
                });
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            // abortController = new AbortController();

            // abortControllerRef.current = new AbortController();
            // requestOptions.signal = abortControllerRef.current.signal;

            let accumulatedData = "";
        //     const response = await fetch(DjangoHost + "/api/message/", requestOptions);
        //     const reader = response.body.getReader();

        //     while (true) {
        //         const { done, value } = await reader.read();
        //         if (done) break;

        //         const chunk = new TextDecoder().decode(value);
        //         accumulatedData += chunk;
        //         setStreamedResponse((r) => r + chunk);
        //         responseRef.current = responseRef.current + chunk;
        //     }

        //     dispatch(addStreamedMessage({ streamedResponse: accumulatedData }));
        //     if (currentConversationID === null) {
        //         dispatch(getChatTitleAPI({ conversation_id }));
        //     }
        // } catch (error) {
        //     if (error.name === 'AbortError') {
        //         console.log('Fetch aborted');
        //     } else {
        //         console.error("Error in sendOrCreateChatMessageStreaming:", error);
        //     }
        // } finally {
        //     setIsStreaming(false);
        // }
            let endpoint;
            let prefix = '';
            if (presetPrompt === 'hint') {
                endpoint = '/api/message/hint/';
                // prefix = '**Hint:** ';
                // accumulatedData = '**Hint:** ';
            } else if (presetPrompt === 'explain') {
                endpoint = '/api/message/explain/';
                // prefix = '**Explanation:** ';
                // accumulatedData = '**Explanation:** ';
            } else if (presetPrompt === 'question') {
                endpoint = '/api/file/question/';
            } else if (presetPrompt === 'summary') {
                endpoint = '/api/file/summarize/';
            } else {
                customLog('SENDORCREATE /api/message/');
                endpoint = '/api/message/';
            }
            fetch(DjangoHost + endpoint, 
                {
                    ...requestOptions,
                    signal
                }
            )
                .then(response => {
                    const reader = response.body.getReader();
                    return new ReadableStream({
                        async start(controller) {
                            let isPrefixAdded = false;
                            while (true) {
                                let { done, value } = await reader.read();
                                if (done) {
                                    customLog('streamedResponse is ' + accumulatedData);
                                    dispatch(addStreamedMessage({ streamedResponse: accumulatedData }));
                                    // dispatch(addPresetPrompt({message: accumulatedData}))
                                    setIsStreaming(false);
                                    if (reactChatMessages.length === 0) {
                                        customLog('CONVERSATIONJUSTCREATEDREF.CURRENT calling getChatTitleAPI');
                                        dispatch(getChatTitleAPI({ conversation_id }));
                                    }
                                    const newMessage = { user: "Assistant", text: accumulatedData };
                                    setStreamedResponse('');
                                    setReactChatMessages((prevReactChatMessages) => {
                                        return [...prevReactChatMessages, newMessage];
                                    });

                                    break;
                                }
                                if (cancelStream) {
                                    customLog("CANCELSTREAM is true");
                                    reader.cancel();
                                    break;
                                    setCancelStream(false);
                                }
                                // Add prefix only once at the beginning
                                // if (!isPrefixAdded) {
                                //     setStreamedResponse((r) => r + prefix);
                                //     // accumulatedData += prefix;
                                //     isPrefixAdded = true;  // Ensure the prefix isn't added again
                                // }

                                value = new TextDecoder().decode(value);
                                customLog('sendOrCreateChatMessageStreaming value is ' + value);
                                accumulatedData += value;
                                setStreamedResponse((r) => r + value);
                                responseRef.current = responseRef.current + value;

                                controller.enqueue(value);
                            }
                            controller.close();
                            reader.releaseLock();
                        }
                    })
                })
                .catch(error => console.log('error', error));
        } catch (error) {
            console.error("Error in sendOrCreateChatMessageStreaming:", error);
        }
    };

    const handleStopStreaming = () => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
            setIsStreaming(false);
        }
    };

    let streamedResponseObject = {'user': 'Assistant', 'text': streamedResponse};

    // updates chat messages when streamedResponse updates
    useEffect(() => {
        // if (streamedResponse && streamedResponse.length) {
        // setReactChatMessages((prevReactChatMessages) => {
        //     const lastMessage = prevReactChatMessages[prevReactChatMessages.length - 1];
        //     // customLog('lastMessage is ' + lastMessage);
        //     // customLog('newMessageRef.current is ' + newMessageRef.current);
        //         if (newMessageRef.current || !lastMessage || lastMessage.user !== "Assistant") {
        //             // If ref is true or the last message is not from the assistant, create a new message
        //             // customLog('NEWMESSAGE');
        //             newMessageRef.current = false; // Reset ref after new message creation
        //             return [...prevReactChatMessages, { user: "Assistant", text: streamedResponse }];
        //         } else {
        //             // customLog('CONTINUEDMESSAGE');
        //             // Otherwise, append to the last assistant message
        //             return prevReactChatMessages.map((item, index) =>
        //                 index === prevReactChatMessages.length - 1
        //                     ? { ...item, text: streamedResponse }
        //                     : item
        //             );
        //         }
        //     });
        // }

    }, [streamedResponse]);

    useEffect(() => {
        if (isLoggedIn) {
            customLog('useEffect currentConversationID called with currentConversationID of ' + currentConversationID);
            let newMessages = currentConversationID == null ? [] : IDToConversation[selectedCourse]?.[currentConversationID];
            if (newMessages) {
                customLog('useEffect currentConversationID new messages being set is ' + newMessages);
                setReactChatMessages(newMessages);
            }
        }

    }, [currentConversationID]);

    //Chat panel width
    // const drawerWidth = 240;

    //Function for handling new chat button
    // const handleNewChat = () => {
    //     if (!selectedCourse) {
    //         return; //TODO: Figure out what to do if new chat made but no course selected
    //     }
    //     dispatch(setChatTitle("New Chat"));
    //     dispatch(setMessages([]));
    //     dispatch(setConversationID(null));
    //     setMessageSent(false);
    // };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSend(event);
        }
    };

    //Called when form submitted. Adds current value of input to messages and then clears input
    const handleSend = async (event, inputMessage) => {
        if (event) event.preventDefault();
        if (!isLoggedIn) {
            dispatch(setLoginSignupScreenOpen({loginSignupScreenOpen:true, loginSignupTab: 1}));
            return;
        }
        customLog('HANDLESEND inputMessage is ' + inputMessage);
        const message = inputMessage || input;
        setMessageSent(true);
        const newMessage = { user: "User", text: message };
        setStoredInput(message);
        dispatch(addMessage(newMessage));
        customLog('NEWMESSAGE HANDLESEND IS ' + newMessage.text);
        customLog('NEWMESSAGE HANDLESEND NEWMESSAGE IS ' + JSON.stringify(newMessage));
        customLog('NEWMESSAGE HANDLESEND BEFORE reactChatMessages is ' + JSON.stringify(reactChatMessages));
        // Use functional update to ensure the latest state is captured
        setReactChatMessages((prevReactChatMessages) => {
            const updatedMessages = [...prevReactChatMessages, newMessage];
            customLog('NEWMESSAGE HANDLESEND AFTER reactChatMessages is ' + JSON.stringify(updatedMessages));
            return updatedMessages;
        });
        customLog('NEWMESSAGE HANDLESEND AFTER reactChatMessages is ' + JSON.stringify(reactChatMessages));
        setRows(1);
        responseRef.current = '';
        setStreamedResponse('');

        setLoading(true);

        try {
            sendOrCreateChatMessageStreaming({ selectedCourse: selectedCourseId, message: message });
        } catch (e) {
            setMessages(oldMessages => [...oldMessages, { user: "Plato", text: "Sorry, something went wrong." }]);
            console.error("Error sending message: ", e);
        }

        setInput("");
        inputRef.current = '';
        setLoading(false);
    };


    //Effect for dynamically updating GUI on toolbar height
    useEffect(() => {
        // Update the toolbar height when the component mounts or the window resizes
        const handleResize = () => {
            if (toolbarRef.current) { // Checks if toolbar is mounted
                setToolbarHeight(toolbarRef.current.clientHeight); //Obtains height of toolbar
            }
        };

        // Call handleResize initially and whenever the window resizes
        window.addEventListener('resize', handleResize);
        handleResize();

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    //Effect for dynamically updating GUI on appBar height
    useEffect(() => {
        // Update the appBar height when the component mounts or the window resizes
        const handleResize = () => {
            if (appBarRef.current) { // Checks if toolbar is mounted
                setAppBarHeight(appBarRef.current.clientHeight); //Obtains height of toolbar
            }
        };

        // Call handleResize initially and whenever the window resizes
        window.addEventListener('resize', handleResize);
        handleResize();

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Resource panel will take up 30% of screen width
    // const resourcePanelWidth = selectedResource ? '30%' : '0px';

    
    
    
    // const SettingsPopup = ({ open, onClose, ...props }) => {
    //     const [activeTab, setActiveTab] = useState('email');
      
    //     return (
    //       <Dialog open={open} onClose={onClose} PaperProps={{ style: { backgroundColor: themeData.color3, borderRadius: '10px' } }}>
    //         <DialogTitle style={{color: themeData.color5}}>Settings</DialogTitle>
    //         <DialogContent style={{ display: 'flex', justifyContent: 'space-between', padding: '20px', alignItems: 'center' }}>
    //             <div style={{ marginRight: '100px', color: themeData.color5}}>Username</div>
    //             <div style={{color: themeData.color5}}>{userEmail}</div>
    //         </DialogContent>
    //         <DialogActions>
    //           <Button style={{color: themeData.color5}} onClick={onClose}>Close</Button>
    //         </DialogActions>
    //       </Dialog>
    //     );
    //   };


    const resourcePanelMarginRight = selectedResourceRedux ? '30%' : '0px'; //TODO: changed to redux
    

    // Generic Dialog for dialogs on chat interface
    const GenericDialog = ({ open, handleClose, title, children }) => {
        return (
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
              {children}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Dialog>
        );
      };

    // UI component for displaying resources
    const ResourceItem = ({ resource, onSelect, className }) => (
        <Tooltip title={resource.title}>
            <IconButton onClick={() => onSelect(resource)} className={className} style={{ padding: 0, marginRight: 'none' }}>
                <img src={resource.previewImageUrl} alt={resource.title} style={{ width: '150px', height: '150px' }} />
            </IconButton>
        </Tooltip>
      );

    return (
        <div >
            <TopAppBar />
            <Tooltip title="Share Chat">
                <IconButton style={{ position: 'fixed', top: 10, right: 10, zIndex: 1000 }}>
                    <ShareIcon />
                </IconButton>
            </Tooltip>
            <TopLeftDrawer/>
            {drawerOpen && <BottomLeftDrawer/>}
            {/* {drawerOpen && <BottomLeftDrawer/>} */}

            
            {/* <Button 
                    style={{ 
                        position: 'fixed', 
                        top: '50%', 
                        left: drawerOpen ? `${drawerWidth}px` : '0px',
                        color: themeData.color1
                    }} 
                    onClick={toggleDrawer}
                >
                    {drawerOpen ? <ArrowLeftIcon />: <ArrowRightIcon />}
            </Button> */}
            {/* {flashcardsScreenOpen ? (
                <FlashcardsScreen />
            ) : quizMeScreenOpen ? ( 
                <QuizMeScreen />
            ) : ( */}
            <LoginSignupDialogComponent/>
            {flashcardsScreenOpen && (
                <CreateOrLibrary
                    open={flashcardsScreenOpen}
                    onClose={() => dispatch(setFlashcardsScreenOpen(false))}
                    type="flashcards"
                    onFlashcardFileUpload={flashcardFileUpload}
                    createFlashcards={createFlashcards}
                    retrieveFlashcards={retrieveFlashcards}
                />
                // <LoginSignupDialogComponent/>
            )}
            {quizMeScreenOpen && (
                <CreateOrLibrary
                    open={quizMeScreenOpen}
                    onClose={() => dispatch(setQuizMeScreenOpen(false))}
                    type="quizme"
                    onQuizFileUpload={quizFileUpload}
                    createQuiz={createQuiz}
                    retrieveQuiz={retrieveQuiz}
                />
            )}
            <SplitPane 
                split="vertical"
                // minSize={0}
                maxSize={-400} // Prevent left pane from overlapping the left edge
                defaultSize={600}
                onChange={(size) => {
                    customLog('size is ' + size);
                    customLog('windowWidth is ' + windowWidth);
                    customLog('drawerWidth is ' + drawerWidth);
                    if ((size - 700)/2 < 180) {
                        dispatch(setDrawerOpen(false)); // Automatically close the drawer if the left pane overlaps
                    }
                }}
                // minSize={minPanelSize} 
                size={!selectedResourceType ? '100%' : '70%'} //TODO: changed to redux
                // onChange={handlePaneResize}
                onDragStarted={() => dispatch(setIframeDragged(true))}
                onDragFinished={(newSize) => {
                    dispatch(setIframeDragged(false));
                    //handleDragFinished(newSize);
                }}
                pane1Style={{overflow: 'scroll'}}
                className={!selectedResourceType ? 'hide-resizer' : ''} //TODO: changed to redux
                //TODO: Fuck with above overflow to get right
                >
                <main 
                    className="main" 
                    style={{ backgroundColor: themeData.color4}}
                    > 
                    {reactChatMessages.length === 0 && (
                         <div className="no-chat-plato-container" 
                            //style={{right: `${window.innerWidth - splitPanePosition}px`}}
                            >
                            <img src={platoImage} alt="Plato" className="no-chat-plato"/> 
                         </div>
                    )}
                    <div style={{marginBottom: `${textFieldHeight}px`}}> {/*`${textFieldHeight}px` */}
                        <List className="chat-area" style={{ backgroundColor: themeData.color4, maxHeight: 'calc(100% - 50px)' }}>
                            <PreviousMessages 
                                messages={reactChatMessages}
                                username={username}
                                platoImage={platoImage}
                                stickman={stickman}
                            />
                            {streamedResponseObject && (
                                <StreamingMessage 
                                    message={streamedResponseObject}
                                    username={username}
                                    platoImage={platoImage}
                                    stickman={stickman}
                                />
                            )}
                        </List>
                        <div className="resource-preview" style={{marginBottom: `${textFieldHeight + 100}px`}}>
                            {resources.map((resource) => (
                                <ResourceItem key={resource.id} resource={resource} onSelect={handleSelectResource} className="resource-item"/>
                            ))}
                        </div>
                    </div>
                    <div className="form-style" style={{
                        //right: `${window.innerWidth - splitPanePosition}px`,
                    }}>
                        {loading ? (
                            <CircularProgress />
                        ) : (
                            <InputForm
                                handleSend={handleSend}
                                themeData={themeData}
                                // input={input}
                                // setInput={setInput}
                                loading={loading}
                                isStreaming={isStreaming}
                                handleFileUploadClick={handleFileUploadClick}
                                handleFileChange={handleFileChange}
                                // handleChange={handleChange}
                                // handleKeyDown={handleKeyDown}
                                handleAbort={handleAbort}
                            />
                        )}
                    </div>
                </main>
                {selectedResourceType &&  (//contentVisible &&
                    <div className="resource-panel">
                        <TopResourcePanel
                            sendOrCreateChatMessageStreaming={sendOrCreateChatMessageStreaming}
                            // getSimilarFlashcard={getSimilarFlashcard}
                            // getSimilarQuiz={getSimilarQuiz}
                            />
                        <ResourcePanel 
                            resource={selectedResourceRedux}
                            //flashcards={flashcards}
                            quiz={quiz}
                            currentFlashcardQuizIndex={currentFlashcardQuizIndex}
                            currentFlashcardQuiz={currentFlashcardQuiz}
                            selectedResource={selectedResourceRedux}
                            extendFlashcards={extendFlashcards}
                            extendQuiz={extendQuiz}
                            currentFlashcardGroup={currentFlashcardGroup}
                            // isDragging={dispatch(setIframeDragged(true))}
                            //onIframeLoad={handleIframeLoad}
                        />
                    </div>
                )}
            </SplitPane>
            {/* )} */}
            <SettingsPopup 
                open={settingsOpen} 
                onClose={() => dispatch(setSettingsOpen(false))}
                themeData={themeData}
                userEmail={userEmail} 
            />
            <Toolbar className="bottom-toolbar" style={{ backgroundColor: themeData.color4}}>
                {/* Add any content you want inside the toolbar here */}
            </Toolbar>
            <Snackbar
                open={snackbarShareChatOpen}
                autoHideDuration={3000}
                message={<span style={{ color: themeData.color5 }}>Link copied to clipboard!</span>}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={() => dispatch(setSnackbarShareChatOpen(false))}
                ContentProps={{
                    style: { backgroundColor: themeData.color3, justifyContent: 'center', padding: '2px' }
                }}
            />
            <Snackbar
                open={snackbarRecordingOpen}
                autoHideDuration={3000}
                message={<span style={{ color: themeData.color5 }}>{snackbarRecordingMessage}</span>}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={() => {
                    dispatch(setSnackbarRecordingOpen(false));
                }}
                ContentProps={{
                    style: { backgroundColor: themeData.color3, justifyContent: 'center', padding: '2px' }
                }}
            />
            <Snackbar
                open={generalSnackbarOpen}
                autoHideDuration={4000}
                message={
                    <div 
                        style={{ 
                            color: themeData.color5,
                            whiteSpace: 'normal',  // Allow text to wrap
                            wordBreak: 'break-word',  // Ensure long words break correctly
                            textAlign: 'center',  // Center the text horizontally
                            padding: '2px',  // Equal padding around the text
                            display: 'flex',  // Use flexbox for vertical centering
                            justifyContent: 'center',  // Center horizontally
                            alignItems: 'center',  // Center vertically
                            height: '100%',  // Ensure it uses full height of the Snackbar content area
                            boxSizing: 'border-box',  // Include padding in the width and height calculation
                            maxWidth: '300px'  // Set a maximum width for the Snackbar content
                        }}
                    >
                        {generalSnackbarMessage}
                    </div>
                }
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={() => {
                    dispatch(setGeneralSnackbarOpen(false));
                }}
                ContentProps={{
                    style: { 
                        backgroundColor: themeData.color3, 
                        justifyContent: 'center', 
                        padding: '0px',  // Remove padding here, handled in the message div
                        border: '1px solid #000',
                        display: 'flex',  // Use flexbox to ensure content centers properly
                        alignItems: 'center'  // Vertically center the content within the Snackbar
                    }
                }}
            />
        </div>
    );
}

export default Chat;